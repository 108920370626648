import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import theme from "../../../../../themes/default";
import {
  AvatarContent,
  InfoContainer,
  InfoImg,
  InfoText,
  PersonCard,
  Position,
  TeamContainer,
  Title,
  Username,
  WrapAvatar,
  WrapImg,
  WrapInfo} from "./our-team-styles";
import teamAll from "../../../../../img/teamAll.jpg";
import ArthurShingirei from "../../../../../img/team/ArthurShingirei.png";
import AndreiIvashkevich from "../../../../../img/team/AndreiIvashkevich.png";
import ArseniyBoiko from "../../../../../img/team/ArseniyBoiko.png";
import ArtemKotov from "../../../../../img/team/ArtemKotov.png";
import DmitryChesnokov from "../../../../../img/team/DmitryChesnokov.png";
import EvgeniyBachilo from "../../../../../img/team/EvgeniyBachilo.png";
import EvgeniyZaharko from "../../../../../img/team/EvgeniyZaharko.png";
import KarymAidary from "../../../../../img/team/KarymAidary.png";
import KhinaPopok from "../../../../../img/team/KhinaPopok.png";
import KirillPetrov from "../../../../../img/team/KirillPetrov.png";
import ViacheslavBogdanov from "../../../../../img/team/ViacheslavBogdanov.png";
import ViktorBurkouski from "../../../../../img/team/ViktorBurkouski.png";
import YegorKravtsevich from "../../../../../img/team/YegorKravtsevich.png";
import EkaterinaLeshkova from "../../../../../img/team/EkaterinaLeshkova.png";

const OurTeam:React.FC = () => {
  return (
    <>
      <Controller>
        <Scene classToggle={["#our-team-title", "animate"]} triggerElement="#our-team-title" triggerHook={0.80} indicators={false} reverse={false}>
          <Title id="our-team-title">Our team</Title>
        </Scene>
      </Controller>
      <InfoContainer>
        <Controller>
          <Scene classToggle={["#our-team-text", "animate"]} triggerElement="#our-team-text" triggerHook={0.80} indicators={false} reverse={false}>
            <InfoText id="our-team-text">{data.text}</InfoText>
          </Scene>
        </Controller>
        <Controller>
          <Scene classToggle={["#our-team-photo", "animate"]} triggerElement="#our-team-photo" triggerHook={0.80} indicators={false} reverse={false}>
            <WrapImg id="our-team-photo">
              <InfoImg src={teamAll}/>
            </WrapImg>
          </Scene>
        </Controller>
      </InfoContainer>
      {/*<TeamContainer>*/}
      {/*  {data.team.map((person: any, index: number) =>*/}
      {/*    <Controller key={index}>*/}
      {/*      <Scene classToggle={[`#card-person-${index}`, "animate"]} triggerElement={`#card-person-${index}`} triggerHook={0.80} indicators={false} reverse={false}>*/}
      {/*        <PersonCard key={index} id={`card-person-${index}`}>*/}
      {/*          <Controller>*/}
      {/*            <Scene classToggle={[`.our-team-photo-${index}`, "animate"]} triggerElement={`#card-person-${index}`} triggerHook={0.80} indicators={false} reverse={false}>*/}
      {/*              <WrapAvatar className={`our-team-photo-${index}`}>*/}
      {/*                <AvatarContent>*/}
      {/*                  <img src={person.image_url} alt=""/>*/}
      {/*                </AvatarContent>*/}
      {/*              </WrapAvatar>*/}
      {/*            </Scene>*/}
      {/*          </Controller>*/}
      {/*          <Controller>*/}
      {/*            <Scene classToggle={[`.our-team-info-${index}`, "animate"]} triggerElement={`#card-person-${index}`} triggerHook={0.80} indicators={false} reverse={false}>*/}
      {/*              <WrapInfo className={`our-team-info-${index}`}>*/}
      {/*                <Position>{person.position}</Position>*/}
      {/*                <Username>{person.first_name}</Username>*/}
      {/*                <Username>{person.last_name}</Username>*/}
      {/*              </WrapInfo>*/}
      {/*            </Scene>*/}
      {/*          </Controller>*/}
      {/*        </PersonCard>*/}
      {/*      </Scene>*/}
      {/*    </Controller>*/}
      {/*  )}*/}
      {/*</TeamContainer>*/}
    </>
  )
}

export default OurTeam;

const data = {
  text: "Our goal is long-term relationships based          on trust, flexibility, comprehesive approach          to each task and rich expertise of our team.",
  team: [
    {
      image_url: ArseniyBoiko,
      position: "Backend Developer",
      first_name: "Arseniy",
      last_name: "Boiko"
    },
    {
      image_url: AndreiIvashkevich,
      position: "Senior backend Developer",
      first_name: "Andrei",
      last_name: "Ivashkevich"
    },
    {
      image_url: DmitryChesnokov,
      position: "Software Engineer",
      first_name: "Dmitry",
      last_name: "Chesnokov"
    },
    {
      image_url: EkaterinaLeshkova,
      position: "Frontend Developer",
      first_name: "Ekaterina",
      last_name: "Leshkova"
    },
    {
      image_url: ArthurShingirei,
      position: "CEO of Nord-Soft",
      first_name: "Arthur",
      last_name: "Shingirei"
    },
    {
      image_url: ArtemKotov,
      position: "Frontend Developer",
      first_name: "Artem",
      last_name: "Kotov"
    },
    {
      image_url: KhinaPopok,
      position: "Full-Stack Developer",
      first_name: "Khina",
      last_name: "Popok"
    },
    {
      image_url: EvgeniyBachilo,
      position: "Frontend Developer",
      first_name: "Evgeniy",
      last_name: "Bachilo"
    },
    {
      image_url: EvgeniyZaharko,
      position: "Backend Developer",
      first_name: "Evgeniy",
      last_name: "Zaharko"
    },
    {
      image_url: KarymAidary,
      position: "DevOps Engineer & Backend Developer",
      first_name: "Karym",
      last_name: "Aidary"
    },
    {
      image_url: KirillPetrov,
      position: "Full-Stack Developer",
      first_name: "Kirill",
      last_name: "Petrov"
    },
    {
      image_url: ViacheslavBogdanov,
      position: "Frontend Developer",
      first_name: "Viacheslav",
      last_name: "Bogdanov"
    },
    {
      image_url: ViktorBurkouski,
      position: "Senior frontend Developer",
      first_name: "Viktor",
      last_name: "Burkouski"
    },
    {
      image_url: YegorKravtsevich,
      position: "Backend Developer",
      first_name: "Yegor",
      last_name: "Kravtsevich"
    },
  ]
}