import { getRoot, types } from 'mobx-state-tree';
import api from '../services/api';

const { model, number, optional, boolean, string, array, maybeNull } = types;

const ModelReview = model({
  id: number,
  text: string,
  username: string,
  position: string,
  is_clutch: boolean
})

const ModelMessageError = model({
  description: optional(array(string), []),
  email: optional(array(string), []),
  name: optional(array(string), [])
})

const DefaultMessageErrors = {
  description: [],
  email: [],
  name: []
}

const AuthStore = model('AuthStore', {
  isLoading: false,
  messageErrors: optional(ModelMessageError, {}),
  selectedReview: maybeNull(ModelReview),
  isModal: false,
})
  .actions((self: any) => ({
    setSelectedReview(data: any) {
      self.selectedReview = data;
    },
    sendMessage: async (body: any) => {
      try {
        self.toggleLoading();
        let data = "";
        for (let key in body) {
          data = data + (data ? "&" : "") + key+ "=" + body[key]
        }
        const response = await api.connect.sendMessage(data);
        if (response.ok) {
          self.toggleModal();
          self.toggleMessageError();
        } else {
          self.toggleMessageError(await response.json());
        }
      } catch (err) {
        console.error('sendMessage error', err);
      } finally {
        self.toggleLoading();
      }
    },
    toggleLoading() {
      self.isLoading = !self.isLoading;
    },
    toggleModal() {
      self.isModal = !self.isModal;
    },
    toggleMessageError(value: any = {}) {
      self.messageErrors = {...DefaultMessageErrors, ...value};
    },
  }))
  .views(self => ({}));

export default AuthStore;
