import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp } from "react-animations";

type PropsStyle = {
}

const FadeInUp = keyframes`${fadeInUp}`;

export const Title = styled.div`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  color: ${theme.palette.white[0]};
  text-align: center;
  padding-bottom: ${theme.spaces[1]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 40px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 30px;
    line-height: 39px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    font-size: 28px;
    padding-bottom: 35px;
  }
`

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
`