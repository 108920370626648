import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, fadeInDown, fadeInLeft, fadeInRight } from "react-animations";

type PropsStyle = {
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;
const FadeIn = keyframes`${fadeIn}`;
const FadeInLeft = keyframes`${fadeInLeft}`;
const FadeInRight = keyframes`${fadeInRight}`;

export const CollaborationContainer = styled.div`
  margin-top: 65px;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-column-gap: 20px;
  grid-row-gap: 120px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top: 25px;
    grid-row-gap: 95px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 25px;
    grid-row-gap: 70px;
  }
  
  @media (max-width: 768px) {
    margin-top: 20px;
    grid-template-columns: repeat(2,1fr);
    grid-row-gap: 60px;
  }
`

export const WrapArrow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  
  &.animate-right{
    opacity: 1;
    animation: 0.4s ${FadeInLeft};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
  
  &.animate-left{
    animation: 0.4s ${FadeInRight};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
  
  &.animate-bottom{
    animation: 0.4s ${FadeInDown};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
`

export const CollaborateItemCard = styled.div`
  position: relative;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
`

export const ItemText = styled.div`
  font-family: ${theme.fonts.mulish};
  font-style: normal;
  font-weight: bold;
  font-size: ${theme.fontSizes.headers[4]}px;  
  text-align: center;
  letter-spacing: 0.01em;
  color: ${theme.palette.darkBlue[1]};
  
  &.animate{
    animation: 0.4s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.texts[1]}px;
  }
  
  @media (max-width: 992px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
`


export const WrapImg = styled.div`
  text-align: center;
  
  &.animate{
    animation: 0.4s ${FadeInDown};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }  
`

export const IconImg = styled.img`
  width: 80px;
  height: 80px;
  pointer-events: none;
  
  @media (max-width: 768px) {
    width: 75px;
    height: 75px;
  }
`

export const WaitTitle = styled.div`
  margin-top: ${theme.spaces[0]}px;
  padding-bottom: ${theme.spaces[2]}px;
  font-weight: bold;
  font-size: ${theme.fontSizes.headers[2]}px;
  color: ${theme.palette.white[0]};
  text-align: center;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0.0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top: ${theme.spaces[1]}px;
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: ${theme.spaces[1]}px;
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    text-align: left;
    font-size: 28px;
    margin-top: 40px;
    padding-bottom: 20px;
  }
`

export const WaitText = styled.div`
  text-align: center;
  font-family: ${theme.fonts.mulish};
  font-style: normal;
  font-weight: normal;
  color: ${theme.palette.white[0]};
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 768px) {
    text-align: left;
    font-size: ${theme.fontSizes.texts[1]}px;
  }
`

export const WrapperOptions = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top: 20px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    display: block;
    margin-top: 20px;
  }
`

export const OptionCard = styled.div`
  padding: 40px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  background-color: ${theme.palette.grayscale[0]};
  color: ${theme.palette.black[1]};
  transition-duration: 0.8s;
   opacity: 0;
  
  :hover{
    transition-duration: 0.8s;
    background-color: ${theme.palette.darkBlue[1]};
    color: ${theme.palette.grayscale[0]};
    cursor: pointer;
  }
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
  
  .card-option-title-1, .card-option-title-2{
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 25px;
    
    &.animate{
      animation: 0.4s ${FadeInDown};
      animation-delay: 0.5s;
      animation-fill-mode: both;
    }
  }
  
  .card-option-text-1, .card-option-text-2{
    font-family: ${theme.fonts.mulish};
    font-weight: normal;
    letter-spacing: 0.01em;
    
    &.animate{
      animation: 0.4s ${FadeInUp};
      animation-delay: 0.5s;
      animation-fill-mode: both;
    }
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: 35px 30px;
    
    .card-option-title-1, .card-option-title-2{
      font-weight: bold;
      font-size: 28px;
      line-height: 110%;
      margin-bottom: 15px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 35px 20px;
    
    .card-option-title-1, .card-option-title-2{
      font-weight: 500;
      font-size: 22px;
      line-height: 110%;
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 768px) {
    padding: 30px;
    margin-bottom: 20px;
    
    :last-child{
      margin-bottom: 0;
    }
    
    .card-option-title-1, .card-option-title-2{
      font-weight: 500;
      font-size: 26px;
      margin-bottom: 10px;
    }
    
    .card-option-text-1, .card-option-text-2{
      font-size: 16px;
    }
  }
`