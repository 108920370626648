import React from "react";
import { ContainerWrap } from "../app-styles";
import ContentTechnologies from "./content/ContentTechnologies";
import HeaderTechnologies from "./header/HeaderTechnologies";

const Technologies:React.FC = (props) => {
  return (
    <ContainerWrap>
      <HeaderTechnologies {...props}  />
      <ContentTechnologies {...props} />
    </ContainerWrap>
  )
}

export default Technologies;