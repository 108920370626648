import styled, { keyframes } from 'styled-components';
import theme from "../../../themes/default";
// @ts-ignore
import { fadeInUp } from "react-animations";

type PropsStyle = {
  isWhite?: boolean
}

const FadeInUp = keyframes`${fadeInUp}`;

export const TextInfo = styled.div<PropsStyle>`
  font-weight: normal;
  font-family: ${theme.fonts.mulish};
  color: ${({isWhite}) => isWhite ? theme.palette.white[0] : theme.palette.black[1]};
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`

export const Title = styled.div<PropsStyle>`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  color: ${({isWhite}) => isWhite ? theme.palette.white[0] : theme.palette.black[1]};
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 20px;
  }
`