import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import {
  Container,
  ContainerStack,
  InfoText,
  InfoWrap,
  ItemList,
  ListThematic,
  StacksThematic,
  StackWrap,
  ThematicWrap,
  Title } from "./title-info-styles";

type PropsType = {
  data: any
}

const TitleInfo:React.FC<PropsType> = ({data}) => {
  return (
    <Container id="title-info">
      <Controller>
        <Scene classToggle={[".project-title", "animate"]} triggerElement="#title-info" triggerHook={0.80} indicators={false} reverse={false}>
          <Title className="project-title">{data.name}</Title>
        </Scene>
      </Controller>
      <InfoWrap>
        <Controller>
          <Scene classToggle={[".project-text", "animate"]} triggerElement="#title-info" triggerHook={0.80} indicators={false} reverse={false}>
            <InfoText className="project-text">{data.description}</InfoText>
          </Scene>
        </Controller>
        <StacksThematic>
          <StackWrap>
            {data.stacks.map((stack: string, index: number) =>
              <Controller key={index}>
                <Scene classToggle={[`.stack-title-${index}`, "animate"]} triggerElement="#title-info" triggerHook={0.80} indicators={false} reverse={false}>
                  <ContainerStack key={index} className={`stack-title-${index}`}>{stack}</ContainerStack>
                </Scene>
              </Controller>
            )}
          </StackWrap>
          <ThematicWrap>
            {data.thematic.length > 0 &&
            <ListThematic>
              {data.thematic.map((item: string, index: number) =>
                <Controller key={index}>
                  <Scene classToggle={[`.thematic-title-${index}`, "animate"]} triggerElement="#title-info" triggerHook={0.80} indicators={false} reverse={false}>
                    <ItemList key={index} className={`thematic-title-${index}`}>{item}</ItemList>
                  </Scene>
                </Controller>
              )}
            </ListThematic>
            }
          </ThematicWrap>
        </StacksThematic>
      </InfoWrap>
    </Container>
  )
}

export default TitleInfo;