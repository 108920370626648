import React from 'react';
import {Redirect, Route, Switch } from 'react-router-dom';
import MainLandingPage from '../pages/main/MainLandingPage';
import Projects from "../pages/projects/Projects";
import Company from "../pages/company/Company";
import Services from "../pages/services/Services";
import Technologies from '../pages/technologies/Technologies';
import ProjectCard from '../pages/projectCard/ProjectCard';

const useRoute = () => {
    return (
        <Switch>
            <Route exact component={MainLandingPage} path='/'/>
            <Route exact component={Projects} path='/projects'/>
            <Route exact component={ProjectCard} path='/project/:id'/>
            <Route exact component={Company} path='/company'/>
            <Route exact component={Services} path='/services'/>
            <Route exact component={Technologies} path='/technologies'/>
            <Redirect to='/'/>
        </Switch>
    )
}

export default useRoute