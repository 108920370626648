import React from "react";
import Header from "../../components/header/Header";
import { ContainerWrap } from "../app-styles";
import ContentCompany from "./content/ContentCompany";

const Company:React.FC = (props) => {
  return (
    <ContainerWrap>
      <Header {...props}/>
      <ContentCompany/>
    </ContainerWrap>
  )
}

export default Company