import React from "react";
import mouse from "../../img/mouse.svg";
import grey from "../../img/grey_arrow.svg";
import white from "../../img/white_arrow.svg";

//css
import './mouse_style.css'

const Mouse = () => {
  return (
    <div className='container' >
      <img src={mouse} />
      <div className='wrapper'>
        <div className='wrapper-image'><img src={grey} /></div>
        <div className='wrapper-image'><img src={white} /></div>
      </div>
    </div>
  )
}

export default Mouse;