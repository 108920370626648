import React from "react";
import { inject, observer } from "mobx-react";
import { Controller, Scene } from "react-scrollmagic";
import {
  TextWrap,
  WrapHeader,
  ButtonWrap,
  TitleWrap, ImageWrap,
} from "./landing-headerstyles";
import Header from "../../../components/header/Header";
import Button from "../../../components/button/Button";
import Particles from "react-particles-js";
import '../style.css'
import {LandingContainer} from "../landing-styles";
import Mouse from "../../../components/mouse/Mouse";

type PropsType = {
  authStore?: any;
};

const LandingHeader: React.FC<PropsType> = (props) => {
  const {
    authStore: { toggleModal },
  } = props;
  return (
    <>
      <Header main="true" {...props} />
      <div style={{position: 'relative'}}>
        <Particles
          className="canvas"
          params={{
            particles: {
              number: {
                value: 150,
              },
              size: {
                value: 3,
              },
              move: {
                random: true,
                speed: 1,
              },
              color: {
                value: '#1B8FF8'
              },
              line_linked: {
                enable: true,
                color: "#5698D6",
                opacity: 0.4,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
        />
        <Controller>
          <Scene
            classToggle={[".landing-header", "animate"]}
            triggerElement="#header-container"
            triggerHook={0.9}
            indicators={false}
            reverse={false}
          >
            {/*<WrapColorPicture*/}
            {/*  padding="WrapHeader"*/}
            {/*  animateClass={`landing-header`}*/}
            {/*  height="100vh"*/}
            {/*>*/}
            <WrapHeader id="header-container">
              <Controller>
                <Scene
                  classToggle={[".landing-header-text", "animate"]}
                  triggerElement="#header-container"
                  triggerHook={0.9}
                  indicators={false}
                  reverse={false}
                >
                  <>
                    <TextWrap className="landing-header-text">
                      <span>
                        We build web <br />& mobile applications
                      </span>
                    </TextWrap>
                  </>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[".landing-header-title", "animate"]}
                  triggerElement="#header-container"
                  triggerHook={0.9}
                  indicators={false}
                  reverse={false}
                >
                  <>
                    <TitleWrap className="landing-header-title">
                      <span>
                        We are a full cycle software development studio <br />{" "}
                        with custom attitude.
                      </span>
                    </TitleWrap>
                  </>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[".landing-header-image", "animate"]}
                  triggerElement="#header-container"
                  triggerHook={0.9}
                  indicators={false}
                  reverse={false}
                >
                  <ImageWrap className="landing-header-image">
                    <Mouse />
                  </ImageWrap>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[".landing-header-button", "animate"]}
                  triggerElement="#header-container"
                  triggerHook={0.9}
                  indicators={false}
                  reverse={false}
                >
                  <ButtonWrap className="landing-header-button">
                    <Button
                      isArrow={true}
                      color="red"
                      onClick={() => toggleModal()}
                    >
                      Get in touch
                    </Button>
                  </ButtonWrap>
                </Scene>
              </Controller>
            </WrapHeader>
            {/*</WrapColorPicture>*/}
          </Scene>
        </Controller>
      </div>
    </>
  );
};

export default inject((stores: any) => {
  return {
    authStore: stores.authStore,
  };
})(observer(LandingHeader));
