import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeInDown } from "react-animations";

type PropsStyle = {
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;

export const Title = styled.div`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  text-align: center;
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInDown};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    font-size: 28px;
    padding-bottom: 25px;
  }
`

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
`