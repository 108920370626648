// https://github.com/diegohaz/arc/wiki/Styling

const theme: any = {};

theme.palette = {
  blue: ['#18AAD7', '#2A96B7'],
  darkBlue: ['#137DDE', '#0C61AE'],
  black: ["#1E253F", "#1B2340"],
  white: ["#ffffff"],
  grayscale: ["#F3F9FB", "#A6A6A6"],
  red: ['#E3296C', '#BE255C'],
  lightBlue: ['#9ABDDD', '#2378C6'],
};

theme.fonts = {
  primary: "DM Sans, sans-serif",
  mulish: "'Mulish', sans-serif",
};

theme.fontSizes = {
  headers: [93, 63, 58, 30, 20, 18, 80],
  texts: [17, 16, 14, 18]
}

theme.spaces = [80, 50, 30, 200]

theme.sizes = {
  minWidth: "320px"
};

theme.breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export default theme;
