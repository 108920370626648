import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import {CareCard, CareCardText, CareText, CareWrap, Title } from "./we-care-styles";
import confidentiality from "../../../../../img/confidentiality.svg";
import reputation from "../../../../../img/reputation.svg";
import respect from "../../../../../img/respect.svg";
import contract from "../../../../../img/contract.svg";

const WeCare:React.FC = () => {
  return (
    <>
      <Controller>
        <Scene classToggle={["#we-care-title", "animate"]} triggerElement="#we-care-title" triggerHook={0.80} indicators={false} reverse={false}>
          <Title id="we-care-title">We care</Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene classToggle={[".we-care-text-animate", "animate"]} triggerElement="#we-care-title" triggerHook={0.80} indicators={false} reverse={false}>
          <CareText className="we-care-text-animate">{data.text}</CareText>
        </Scene>
      </Controller>
      <CareWrap>
        {data.cares.map((care: any, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[`#card-care-${index}`, "animate"]} triggerElement={`#card-care-${index}`} triggerHook={0.95} indicators={false} reverse={false}>
              <CareCard key={index} id={`card-care-${index}`}>
                <Controller>
                  <Scene classToggle={[`.card-care-img-${index}`, "animate"]} triggerElement={`#card-care-${index}`} triggerHook={0.95} indicators={false} reverse={false}>
                    <img src={care.icon} alt="" className={`card-care-img-${index}`}/>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene classToggle={[`.card-care-title-${index}`, "animate"]} triggerElement={`#card-care-${index}`} triggerHook={0.95} indicators={false} reverse={false}>
                    <CareCardText className={`card-care-title-${index}`}>{care.text}</CareCardText>
                  </Scene>
                </Controller>
              </CareCard>
            </Scene>
          </Controller>
        )}
      </CareWrap>
    </>
  )
}

export default WeCare;

const data = {
  text: "Business ethics and commitment are at the heart of our company's culture. We value trust and protect your intellectual property.",
  cares: [
    {
      text: "Confidentiality policy",
      icon: confidentiality
    },
    {
      text: "Value of reputation",
      icon: reputation
    },
    {
      text: "Culture of respect",
      icon: respect
    },
    {
      text: "Contract transparency",
      icon: contract
    },
  ]
}