import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import Button from "../../../../../components/button/Button";
import { CardBenefit, CardTitle, ContainerOfBenefits, ContainerSkills, CardSkill, WrapButton, CardText, ImgSkill, ContainerSkill, TextSkill, IconWrap } from "./what-matters-styles";
import { Title } from "../../landing-content.styles";
import reactIcon from "../../../../../img/technologies/react.svg";
import angular from "../../../../../img/technologies/angular.svg";
import css from "../../../../../img/technologies/css.svg";
import dj from "../../../../../img/technologies/dj.svg";
import html5 from "../../../../../img/technologies/html-5.svg";
import js from "../../../../../img/technologies/js.svg";
import python from "../../../../../img/technologies/python.svg";
import typescript from "../../../../../img/technologies/typescript.svg";
import progress from "../../../../../img/progress.svg";
import communication from "../../../../../img/communication.svg";
import reliability from "../../../../../img/reliability.svg";

type PropsType = {
  history?: any
};

const WhatMatters:React.FC<PropsType> = (props) => {
  const { history } = props;
  return (
    <div id="what-matters">
      <Controller>
        <Scene classToggle={[".matters-title-animate", "animate"]} triggerElement="#what-matters" triggerHook={0.80} indicators={false} reverse={false}>
          <Title className="matters-title-animate" animate={true}>What matters is the Result</Title>
        </Scene>
      </Controller>
      <ContainerOfBenefits>
        {dataBenefits.map((benefit: any, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[`#card-benefit-${index}`, "animate"]} triggerElement={`#card-benefit-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
              <CardBenefit color={benefit.color} id={`card-benefit-${index}`}>
                <Controller>
                  <Scene classToggle={[`.card-benefit-title-${index}`, "animate"]} triggerElement={`#card-benefit-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <CardTitle className={`card-benefit-title-${index}`} color={benefit.color}>
                      <IconWrap src={benefit.img}/>
                      {benefit.title}
                    </CardTitle>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene classToggle={[`.card-benefit-text-${index}`, "animate"]} triggerElement={`#card-benefit-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <CardText className={`card-benefit-text-${index}`}>{benefit.text}</CardText>
                  </Scene>
                </Controller>
              </CardBenefit>
            </Scene>
          </Controller>
        )}
      </ContainerOfBenefits>
      <Controller>
        <Scene classToggle={["#matters-button-get-animate", "animate"]} triggerElement="#matters-button-get-animate" triggerHook={0.80} indicators={false} reverse={false}>
          <WrapButton id="matters-button-get-animate">
            <Button color="red" onClick={() => {
              window.open(
                'https://nord-soft.com/static/pdf/presentation.pdf',
                '_blank'
              );
            }}>Get brochure</Button>
          </WrapButton>
        </Scene>
      </Controller>
      <Controller>
        <Scene classToggle={["#technologies", "animate"]} triggerElement="#technologies" triggerHook={0.80} indicators={false} reverse={false}>
          <Title id="technologies">Technologies</Title>
        </Scene>
      </Controller>
      <ContainerSkills>
        {dataSkills.map((skill:string, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[`#card-skill-${index}`, "animate"]} triggerElement={`#card-skill-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
              <CardSkill id={`card-skill-${index}`}>
                <ContainerSkill>
                  <Controller>
                    <Scene classToggle={[`.card-skill-icon-${index}`, "animate"]} triggerElement={`#card-skill-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                      <ImgSkill className={`card-skill-icon-${index}`} src={skills[skill].icon} alt=""/>
                    </Scene>
                  </Controller>
                  <Controller>
                    <Scene classToggle={[`.card-skill-text-${index}`, "animate"]} triggerElement={`#card-skill-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                      <TextSkill className={`card-skill-text-${index}`}>{skills[skill].title}</TextSkill>
                    </Scene>
                  </Controller>
                </ContainerSkill>
              </CardSkill>
            </Scene>
          </Controller>
        )}
      </ContainerSkills>
      <Controller>
        <Scene classToggle={["#matters-button-learn-animate", "animate"]} triggerElement="#matters-button-learn-animate" triggerHook={0.80} indicators={false} reverse={false}>
          <WrapButton id="matters-button-learn-animate">
            <Button
              color="blue"
              isArrow={true}
              onClick={() => {
                history.push(`/technologies`)
              }}
            >Learn more</Button>
          </WrapButton>
        </Scene>
      </Controller>
    </div>
  )
}

export default WhatMatters;

const dataBenefits: any = [
  {
    title: "Reliability",
    img: reliability,
    text: "Here at Nord-Soft, we implement best practices and do things right from beginning to end. Such an approach allows us  to maintain                  a consistent level  of quality and deliver on our promises.",
    color: "blue2"
  },
  {
    title: "Communication",
    img: communication,
    text: "We are open and available throughout    the entire development process so that no detail  of your project          is missed. We believe that transparency and integrity are what makes the best customer experience possible.",
    color: "red"
  },
  {
    title: "Progress",
    img: progress,
    text: "Our goal is to unlock the true value of your business using cutting-edge technology together with careful business analysis, and bring in creativity and innovation where relevant.",
    color: "blue3"
  },
]

const dataSkills = ["PY", "JS", "HT", "TS", "RE", "AN", "CS", "DJ"];

const skills: any = {
  PY: {title: "Python", icon: python},
  JS: {title: "Java Script", icon: js},
  HT: {title: "HTML5", icon: html5},
  TS: {title: "Type Script", icon: typescript},
  RE: {title: "React", icon: reactIcon},
  AN: {title: "React Native", icon: reactIcon},
  CS: {title: "CSS3", icon: css},
  DJ: {title: "Django", icon: dj},
}
