import React from "react";
import Header from "../../../components/header/Header";
import { Controller, Scene } from "react-scrollmagic";
import {  Title, TextInfo } from "./header-services-styles";
import {ImageWrap, WrapHeader} from "../../main/header/landing-headerstyles";
import Particles from "react-particles-js";
import Mouse from "../../../components/mouse/Mouse";

const HeaderServices: React.FC = (props) => {
  return (
    <>
      <Header main="true" {...props} />
      <div style={{position: 'relative'}}>
      <Particles
        className="canvas"
        params={{
          particles: {
            number: {
              value: 150,
            },
            size: {
              value: 3,
            },
            move: {
              random: true,
              speed: 1,
            },
            color: {
              value: '#1B8FF8'
            },
            line_linked: {
              enable: true,
              color: "#5698D6",
              opacity: 0.4,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <Controller>
        <Scene
          classToggle={[".services-header", "animate"]}
          triggerElement="#services-container"
          triggerHook={0.9}
          indicators={false}
          reverse={false}
        >
          {/*<WrapColorPicture*/}
          {/*  padding="WrapHeader"*/}
          {/*  image={background_img}*/}
          {/*  animateClass={`services-header`}*/}
          {/*  height="100vh"*/}
          {/*  color={theme.palette.grayscale[0]}*/}
          {/*>*/}
            <WrapHeader id="services-container">
              <Controller>
                <Scene
                  classToggle={[".title-animate", "animate"]}
                  triggerElement="#services-container"
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <Title className="title-animate">Services</Title>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[".text-title-animate", "animate"]}
                  triggerElement="#services-container"
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <TextInfo className="text-title-animate" isWhite={true}>
                    We specialize in web and mobile app development and deliver
                    high-end solutions to <br/>the companies all around the world.
                    Our team's expertise and keen insight into your <br/> business
                    needs lay a solid foundation for project success.
                  </TextInfo>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[".services-header-image", "animate"]}
                  triggerElement="#services-container"
                  triggerHook={0.9}
                  indicators={false}
                  reverse={false}
                >
                  <ImageWrap className="services-header-image" >
                    <Mouse />
                  </ImageWrap>
                </Scene>
              </Controller>
            </WrapHeader>
          {/*</WrapColorPicture>*/}
        </Scene>
      </Controller>
      </div>
    </>
  );
};

export default HeaderServices;
