import React from "react";
import Header from "../../components/header/Header";
import { ContainerWrap } from "../app-styles";
import Content from "./content/Content";

const Projects:React.FC = (props) => {
  return (
    <ContainerWrap>
      <Header  main="true"  {...props}/>
      <Content {...props}/>
    </ContainerWrap>
  )
}

export default Projects