import React from "react";
import theme from "../../../themes/default";
import WrapColorPicture from "../../../components/wrapColorPicture/WrapColorPicture";
import TitleInfo from "./components/TitleInfo/TitleInfo";
import TechnicalExpertise from "./components/TechnicalExpertise/TechnicalExpertise";
import Questions from "../../projects/content/components/Questions/Questions";

const ContentTechnologies:React.FC = () => {
  return (
    <>
      <WrapColorPicture padding="TechnicalExpertise" color={theme.palette.grayscale[0]}>
        <TitleInfo/>
      </WrapColorPicture>
      <WrapColorPicture padding="TechnicalExpertise" color={theme.palette.black[1]}>
        <TechnicalExpertise/>
      </WrapColorPicture>
      <WrapColorPicture color={theme.palette.grayscale[0]}>
        <Questions/>
      </WrapColorPicture>
    </>
  )
}

export default ContentTechnologies;