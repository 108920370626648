import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { BigCount, ContentStyle, Line, StatisticInfo, TextInfo, VerticalLine, WrapInfo } from "./our-company-styles";
import { Title } from "../../landing-content.styles";

const OurCompany:React.FC = () => {
  return (
    <div>
      <Controller>
        <Scene classToggle={[".title-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
          <Title className="title-animate" animate={true}>Our Company</Title>
        </Scene>
      </Controller>
      <WrapInfo>
        <Controller>
          <Scene classToggle={[".text-company-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
            <TextInfo className="text-company-animate">
              <span>
                {data.text}
              </span>
              <span>
                {data.text2}
              </span>
            </TextInfo>
          </Scene>
        </Controller>
        <StatisticInfo>
          <ContentStyle>
            <div className="table-info">
              <Controller>
                <Scene classToggle={[".table-info-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="table-info-animate">
                    <BigCount>
                      {data.count_clients}
                    </BigCount>
                    <br/>clients from US and Europe
                  </div>
                </Scene>
              </Controller>
            </div>
            <Controller>
              <Scene classToggle={[".line-horizontal-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
                <Line className="line-horizontal-animate"/>
              </Scene>
            </Controller>
            <div className="table-info">
              <Controller>
                <Scene classToggle={[".table-info-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="table-info-animate">
                    <BigCount>
                      {data.count_projects}
                    </BigCount>
                    <br/>projects
                  </div>
                </Scene>
              </Controller>
            </div>
          </ContentStyle>
          <Controller>
            <Scene classToggle={[".line-vertical-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
              <VerticalLine className="line-vertical-animate"/>
            </Scene>
          </Controller>
          <ContentStyle>
            <div className="table-info">
              <Controller>
                <Scene classToggle={[".table-info-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="table-info-animate">
                    <BigCount>
                      {data.years_exp_it}
                    </BigCount>
                    <br/>years of IT experience per developer
                  </div>
                </Scene>
              </Controller>
            </div>
            <div className="table-info">
              <Controller>
                <Scene classToggle={[".table-info-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="table-info-animate">
                    <BigCount>
                      {data.established}
                    </BigCount>
                    <br/>established in
                  </div>
                </Scene>
              </Controller>
            </div>
          </ContentStyle>
        </StatisticInfo>
      </WrapInfo>
    </div>
  )
}

export default OurCompany

const data={
  text: "Nord-Soft is an EU based company from Vilnius, Lithuania. For more than 11 years now, we've been growing, enhancing our performance and extending the list of customers. We've successfully completed more than 150 projects, which means more than 50 customers have trusted us and were happy about it.",
  text2: "Our goal is long-term relationships based on trust, flexibility, comprehesive approach to each task and rich expertise of our team. ",
  count_clients: "50+",
  count_projects: "150+",
  years_exp_it: "4,8",
  established: "2009"
}