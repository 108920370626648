import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeIn } from "react-animations";

type PropsStyle = {
}

const FadeIn = keyframes`${fadeIn}`;

export const TextInfo = styled.div`
  display: flex;
  color: ${theme.palette.white[0]};
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const StartText = styled.div`
  &.animate{
    animation: 0.5s ${FadeIn};
    animation-delay: 1.3s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[1]}px;
  }
`