import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, fadeInDown } from "react-animations";

type PropsStyle = {
  index: number
  isWhite?: boolean
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;
const FadeIn = keyframes`${fadeIn}`;

type ReturnColor= (index: number) => any

let getColor: ReturnColor = function (index) {
  switch (index) {
    case 0: {
      return theme.palette.black[1]
    }
    case 1: {
      return theme.palette.red[1]
    }
    case 2: {
      return theme.palette.blue[1]
    }
    case 3: {
      return theme.palette.darkBlue[1]
    }
  }
}

export const CategoriesContainer = styled.div`
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const CategoryCard = styled.div<PropsStyle>`
  padding: 50px 40px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  background-color: ${({index}) => getColor(index)};
  color: ${theme.palette.white[0]};
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: 35px 30px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 30px 20px;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 30px;
    
    :last-child{
      margin-bottom: 0;
    }
  }
`

export const CategoryTitle = styled.div`
  font-weight: bold;
  font-size: ${theme.fontSizes.headers[3]}px;
  position: relative;
  margin-left: 60px;
  
  &.animate{
    animation: 0.4s ${FadeInDown};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 28px;
    margin-left: 55px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-weight: 500;
    font-size: 22px;
    line-height: 110%;
    margin-left: 45px;
  }
  
  @media (max-width: 768px) {
    font-size: 24px;
    margin-left: 45px;
  }
`

export const IconWrap = styled.img`
  height: 54px;
  width: 55px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -60px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    height: 49px;
    width: 50px;
    left: -55px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    height: 40px;
    width: 41px;
    left: -45px;
  }
  
  @media (max-width: 768px) {
    height: 40px;
    width: 41px;
    left: -45px;
  }
`

export const CategoryText = styled.div`
  margin-top 25px;
  font-weight: normal;
  letter-spacing: 0.01em;
  font-family: ${theme.fonts.mulish};
  
  &.animate{
    animation: 0.4s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top 15px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top 15px;
  }
  
  @media (max-width: 768px) {
    margin-top 10px;
  }
  
`


export const Title = styled.div<PropsStyle>`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  color: ${({isWhite}) => isWhite ? theme.palette.white[0] : theme.palette.black[1]};
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 20px;
  }
`