import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, zoomIn } from "react-animations";

type PropsStyle = {
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeIn = keyframes`${fadeIn}`;
const ZoomIn = keyframes`${zoomIn}`;

export const Title = styled.div`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 20px;
  }
`

export const InfoContainer = styled.div`
  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const InfoText = styled.div`
  font-family: ${theme.fonts.mulish};
  grid-column-start: 1;
  grid-column-end: 6;
  opacity: 0;
  
  //change
  margin-bottom: 25px;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    grid-column-end: 5;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    grid-column-end: 5;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`

export const WrapImg = styled.div`
  grid-column-start: 6;
  grid-column-end: 13;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    grid-column-start: 5;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    grid-column-start: 5;
  }
  
  @media (max-width: 768px) {
    &.animate{
      animation: 0.8s ${FadeInUp};
    }
  }
`

export const InfoImg = styled.img`
  object-fit: contain;
  width: 100%;
`

export const TeamContainer = styled.div`
  margin-top: 65px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (max-width: 768px) {
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
`

export const PersonCard = styled.div`
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
`

export const WrapAvatar = styled.div`
  position: relative;
  padding-top: 100%;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
`

export const AvatarContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  img{
    width: 100%;
    object-fit: cover;
  }
`

const Align = styled.div`
  text-align: right;
  color: ${theme.palette.black[0]};
  font-weight: bold;
`

export const WrapInfo = styled.div`
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${ZoomIn};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
`

export const Position = styled(Align)`
  font-size: ${theme.fontSizes.texts[1]}px;
  margin-top: 15px;
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
  
  @media (max-width: 768px) {
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
  }
`

export const Username = styled(Align)`
  font-size: ${theme.fontSizes.headers[4]}px;
  line-height: 32px;
  margin-bottom: -8px;
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: ${theme.fontSizes.texts[1]}px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`

