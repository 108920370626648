import React, {useEffect, useState} from "react";
import { getProject } from "../../services/api";
import { ContainerWrap } from "../app-styles";
import ContentProjectCard from "./content/ContentProjectCard";
import HeaderProjectCard from "./header/HeaderProjectCard";

const ProjectCard:React.FC = (props) => {
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    setData(getProject(window.location.pathname.slice(9)))
  }, [])

  return (
    <ContainerWrap>
      {data &&
        <>
          <HeaderProjectCard data={data} {...props}/>
          <ContentProjectCard data={data} {...props}/>
        </>
      }
    </ContainerWrap>
  )
}

export default ProjectCard;