import React from "react";
import { ContainerWrap } from "../app-styles";
import ContentServices from "./content/ContentServices";
import HeaderServices from "./header/HeaderServices";

const Services: React.FC = (props) => {
  return (
    <ContainerWrap>
      <HeaderServices {...props} />
      <ContentServices {...props} />
    </ContainerWrap>
  );
};

export default Services;
