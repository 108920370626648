import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import {
  TextInfo,
  Title,
  BigCount,
  BottomContent,
  StatisticInfo,
  TopContent,
  Line,
  VerticalLine,
  Container} from "./title-info-styles";
import theme from "../../../../../themes/default";

const TitleInfo:React.FC = () => {
  return (
    <Container>
      <div id="title-info">
        <Controller>
          <Scene classToggle={[".title-animate", "animate"]} triggerElement="#title-info" triggerHook={0.80} indicators={false} reverse={false}>
            <Title className="title-animate">Our Company</Title>
          </Scene>
        </Controller>
        <Controller>
          <Scene classToggle={[".text-title-animate", "animate"]} triggerElement="#title-info" triggerHook={0.80} indicators={false} reverse={false}>
            <TextInfo className="text-title-animate">
                <span>
                    {dataInfo.text}
                </span>
            </TextInfo>
          </Scene>
        </Controller>
      </div>
      <StatisticInfo id="static-info">
        <TopContent colorLeft={theme.palette.black[1]} colorRight={theme.palette.red[0]}>
          <div className="table-info">
            <Controller>
              <Scene classToggle={[".table-info-animate", "animate"]} triggerElement="#static-info" triggerHook={0.80} indicators={false} reverse={false}>
                <div className="table-info-animate">
                  <BigCount>
                    {dataInfo.established}
                  </BigCount>
                  <br/>Established in
                </div>
              </Scene>
            </Controller>
          </div>
          <Controller>
            <Scene classToggle={[".line-horizontal-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
              <Line className="line-horizontal-animate"/>
            </Scene>
          </Controller>
          <div className="table-info">
            <div className="table-info-animate">
              <BigCount>
                {dataInfo.count_clients}
              </BigCount>
              <br/>Clients from US and Europe
            </div>
          </div>
        </TopContent>
        <Controller>
          <Scene classToggle={[".line-vertical-animate", "animate"]} triggerElement="#our-company" triggerHook={0.80} indicators={false} reverse={false}>
            <VerticalLine className="line-vertical-animate"/>
          </Scene>
        </Controller>
        <BottomContent colorLeft={theme.palette.blue[2]} colorRight={theme.palette.blue[0]}>
          <div className="table-info">
            <div className="table-info-animate">
              <BigCount>
                {dataInfo.count_projects}
              </BigCount>
              <br/>Projects Completed
            </div>
          </div>
          <div className="table-info">
            <div className="table-info-animate">
              <BigCount>
                {dataInfo.years_exp_it}
              </BigCount>
              <br/>Years of IT experience per developer
            </div>
          </div>
        </BottomContent>
      </StatisticInfo>
    </Container>
  )
}

export default TitleInfo;

const dataInfo = {
  text: "Nord-Soft is an EU based company from Vilnius, Lithuania, but, first of all, a team of professionals creating excellent digital products that make sense. We keep up to date and constantly learn and apply new technologies in our solutions.We provide full development cycle: product strategy & consulting, design, development, testing, release and support. For more than 11 years now, we've been growing, enhancing our performance and extending the list of customers. We’ve successfully completed more than 100 projects, which means more than 100 customers have trusted us and were happy about it.Business ethics and dedication are at the core of our company culture. We value trust and protect your intellectual property.",
  count_clients: "50+",
  count_projects: "150+",
  years_exp_it: "4,8",
  established: "2009"
}