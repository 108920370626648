import React from "react";
import theme from "../../../themes/default";
import TitleInfo from "./components/TitleInfo/TitleInfo";
import WrapColorPicture from "../../../components/wrapColorPicture/WrapColorPicture";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import OurTeam from "./components/OurTeam/OurTeam";
import WeCare from "./components/WeСare/WeСare";

const ContentCompany:React.FC = () => {
  return (
    <>
      <WrapColorPicture padding="TitleInfo" color={theme.palette.grayscale[0]}>
        <TitleInfo/>
      </WrapColorPicture>
      <WrapColorPicture padding="WhatWeDo" color={theme.palette.black[1]}>
        <WhatWeDo/>
      </WrapColorPicture>
      <WrapColorPicture padding="OurTeam" color={theme.palette.grayscale[0]}>
        <OurTeam/>
      </WrapColorPicture>
      <WrapColorPicture padding="WeCare" color={theme.palette.grayscale[0]}>
        <WeCare/>
      </WrapColorPicture>
    </>
  )
}

export default ContentCompany