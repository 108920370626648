import React, { MouseEventHandler } from "react";
import { ButtonStyle } from "./button-styles";

type PropsType = {
  color?: string
  disabled?: boolean
  isArrow?: boolean
  onClick?: MouseEventHandler
};

const Button:React.FC<PropsType> = ({ disabled, color, children, isArrow, onClick}) => {
  return (
    <ButtonStyle color={color ? color : "white"} disabled={disabled} onClick={onClick}>
      <div>
        {children}
        {isArrow && <svg width="13" height="17" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1.75L10.75 11.5L1 21.25" strokeWidth="2.4375"/>
        </svg>}
      </div>
    </ButtonStyle>
  )
}

export default Button