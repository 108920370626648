import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, fadeInDown } from "react-animations";

type PropsStyle = {
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;
const FadeIn = keyframes`${fadeIn}`;

export const Title = styled.div`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  color: ${theme.palette.white[0]};
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 20px;
  }
`

export const WrapperOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const OptionCard = styled.div`
  padding: 50px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  background-color: #232d50;
  color: ${theme.palette.grayscale[0]};
  transition-duration: 0.8s;
  cursor: pointer;
  opacity: 0;
  
  :hover{
    background-color: ${theme.palette.grayscale[0]};
    color: ${theme.palette.black[1]};
  }
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
  
  .card-option-title-1, .card-option-title-2{
    font-weight: bold;
    font-size: 36px;
    padding-bottom: ${theme.spaces[2]}px;
    
    &.animate{
      animation: 0.4s ${FadeInDown};
      animation-delay: 0.5s;
      animation-fill-mode: both;
    }
  }
  
  .card-option-text-1, .card-option-text-2{
    font-family: ${theme.fonts.mulish};
    font-weight: normal;
    letter-spacing: 0.01em;
    
    &.animate{
      animation: 0.4s ${FadeInUp};
      animation-delay: 0.5s;
      animation-fill-mode: both;
    }
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: 35px 30px;
    
    .card-option-title-1, .card-option-title-2{
      font-size: 28px;
      line-height: 110%;
      padding-bottom: 15px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 30px 20px;
    
    .card-option-title-1, .card-option-title-2{
      font-size: 22px;
      line-height: 110%;
      padding-bottom: 15px;
    }
  }
  
  @media (max-width: 768px) {
    padding: 30px;
    margin-bottom: 20px;
    
    :last-child{
      margin-bottom: 0;
    }
    
    .card-option-title-1, .card-option-title-2{
      font-weight: 500;
      font-size: 24px;
      line-height: 110%;
      padding-bottom: 10px;
    }
    
    .card-option-text-1, .card-option-text-2{
      font-size: 14px;
    }
  }
`