import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, fadeInDown } from "react-animations";

type PropsStyle = {
  color: string | "red"
}

type PropsStyleAnimate = {
  delay?: number
}

const FadeIn = keyframes`${fadeIn}`;
const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;

type ReturnServiceCard = (color: string) => any

let styleServiceCard: ReturnServiceCard = function (color) {
  switch (color) {
    case "darkBlue":{
      return {
        color: theme.palette.black[1],
      }
    }
    case "red":{
      return {
        color: theme.palette.red[1],
      }
    }
    case "lightBlue":{
      return {
        color: theme.palette.lightBlue[1],
      }
    }
    case "blue":{
      return {
        color: theme.palette.blue[1],
      }
    }

  }
}

export const TextInfo = styled.div`
  display: flex;
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const ButtonWrap = styled.div<PropsStyleAnimate>`
  flex: none;
  width: 400px;
  opacity: 0;
  text-align: right;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: ${({delay}) => delay ? delay : 0.4}s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    width: 195px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    width: 180px;
  }
  
  @media (max-width: 768px) {
    margin-top: 20px;
    width: auto;
    text-align: center;
    button{
      margin-left: 0;
    }
  }
`

export const OurText = styled.div`
  opacity: 0;

  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeIn};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[1]}px;
  }
`

export const ServicesContainer = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    margin-top: 25px;
    display: block;
  }
`

export const ServiceCard = styled.div<PropsStyle>`
  max-width: 610px;
  padding: 45px 40px;
  background-color: ${({color}) => styleServiceCard(color).color};
  color: ${theme.palette.white[0]};
  box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.07);
  border-radius: 7px;
  transition-duration: 0.4s;
  opacity: 0;
    
  :hover{
    box-shadow: 0px 0px 15px ${({color}) => styleServiceCard(color).color}8c;
  }
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeIn};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: 35px 30px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 30px 20px;
  }
  
  @media (max-width: 768px) {
    padding: 30px;
    margin-bottom: 20px;
    
    :last-child {
      margin-bottom: 0;
    }
  }
`

export const ServiceTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${theme.fontSizes.headers[3]}px;
  margin-bottom: 25px;
  position: relative;
  margin-left: 60px;
  
  &.animate{
    animation: 0.5s ${FadeInDown};
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 22px;
    margin-bottom: 15px;
    margin-left: 44px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    margin-bottom: 15px;
    margin-left: 38px;
  }
  
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: 44px;
  }
`

export const IconWrap = styled.img`
  height: 54px;
  width: 55px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -60px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    height: 49px;
    width: 50px;
    left: -50px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    height: 40px;
    width: 41px;
    left: -42px;
  }
  
  @media (max-width: 768px) {
    height: 40px;
    width: 41px;
    left: -45px;
  }
`

export const ServiceText = styled.div`
  line-height: 136%;
   font-size: ${theme.fontSizes.texts[1]}px;
  &.animate{
    animation: 0.5s ${FadeInUp};
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
`