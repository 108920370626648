import React, { useEffect, useState } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { TextInfo, Title } from "../../content-services-styles";

//icons
import deliver from "../../../../../img/collaboration/deliver.svg";
import analysis from "../../../../../img/collaboration/analysis.svg";
import develop from "../../../../../img/collaboration/develop.svg";
import discuss from "../../../../../img/collaboration/discuss.svg";
import interfaceIcon from "../../../../../img/collaboration/interface.svg";
import nda from "../../../../../img/collaboration/nda.svg";
import maintain from "../../../../../img/collaboration/maintain.svg";
import release from "../../../../../img/collaboration/release.svg";
import sign from "../../../../../img/collaboration/sign.svg";
import {
  CollaborateItemCard,
  CollaborationContainer,
  IconImg,
  ItemText,
  WaitText,
  WaitTitle,
  WrapImg,
  OptionCard,
  WrapperOptions,
  WrapArrow,
} from "./why-wait";
import Arrow from "../../../../../components/arrow/Arrow";

const WhyWait: React.FC = () => {
  return (
    <>
      <Controller>
        <Scene
          classToggle={["#wait-title", "animate"]}
          triggerElement="#wait-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <WaitTitle id="wait-title">Why Wait</WaitTitle>
        </Scene>
      </Controller>
      <Controller>
        <Scene
          classToggle={[".wait-text", "animate"]}
          triggerElement="#wait-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <WaitText className="wait-text">
            Share your idea with us and make it come true.
          </WaitText>
        </Scene>
      </Controller>
      <WrapperOptions>
        <Controller>
          <Scene
            classToggle={[`#card-option-1`, "animate"]}
            triggerElement={`#card-option-1`}
            triggerHook={0.8}
            indicators={false}
            reverse={false}
          >
            <OptionCard id="card-option-1">
              <Controller>
                <Scene
                  classToggle={[`.card-option-title-1`, "animate"]}
                  triggerElement={`#card-option-1`}
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <div className="card-option-title-1">Start a new project</div>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[`.card-option-text-1`, "animate"]}
                  triggerElement={`#card-option-1`}
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <div className="card-option-text-1">
                    Describe your vision, and we'll be in touch soon.
                  </div>
                </Scene>
              </Controller>
            </OptionCard>
          </Scene>
        </Controller>
        <Controller>
          <Scene
            classToggle={[`#card-option-2`, "animate"]}
            triggerElement={`#card-option-2`}
            triggerHook={0.8}
            indicators={false}
            reverse={false}
          >
            <OptionCard id="card-option-2">
              <Controller>
                <Scene
                  classToggle={[`.card-option-title-2`, "animate"]}
                  triggerElement={`#card-option-2`}
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <div className="card-option-title-2">
                    Recover an existing project
                  </div>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[`.card-option-text-2`, "animate"]}
                  triggerElement={`#card-option-2`}
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <div className="card-option-text-2">
                    Your app or web site leaves something to be desired? Let us
                    know, we'll bring it to a new technical level.
                  </div>
                </Scene>
              </Controller>
            </OptionCard>
          </Scene>
        </Controller>
      </WrapperOptions>
    </>
  );
};

export default WhyWait;
