import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import theme from "../../../themes/default";
import WrapColorPicture from "../../../components/wrapColorPicture/WrapColorPicture";
import OurCompany from "./components/OurCompany/OurCompany";
import WhatMatters from "./components/WhatMatters/WhatMatters";
import ClientsHappy from "./components/ClientsHappy/ClientsHappy";
import OurService from "./components/OurService/OurService";
import StartProject from "./components/StartProject/StartProject";
import OurTeam from "./components/OurTeam/OurTeam";
import Question from "./components/Question/Question";
import PortfolioCard from "../../../components/potfolioCard/PortfolioCard";
import BrandCollector from "../../../img/projects/brandCollector/BrandCollector.png";
import BrandCollectorMobile from "../../../img/projects/brandCollector/BrandCollectorMobile.png";
import FoodTrack from "../../../img/projects/foodTrack/FoodTrack.png";
import FoodTrackMobile from "../../../img/projects/foodTrack/FoodTrackMobile.png";
import JettPro from "../../../img/projects/jettpro/JettPro.png";
import JettProMobile from "../../../img/projects/jettpro/JettProMobile.png";
import Particles from "react-particles-js";

const LandingContent:React.FC = (props) => {
  return (
    <>
      <WrapColorPicture id="our-company" color={theme.palette.lightBlue[0]}>
        <OurCompany/>
      </WrapColorPicture>
      <WrapColorPicture color={theme.palette.grayscale[0]}>
        <WhatMatters {...props}/>
      </WrapColorPicture>
      <Controller>
        <Scene classToggle={[".client-happy", "animate"]} triggerElement="#clients-happy"triggerHook={0.90} indicators={false} reverse={false}>
          <WrapColorPicture color={theme.palette.black[0]} animateClass="client-happy">
            <ClientsHappy/>
          </WrapColorPicture>
        </Scene>
      </Controller>
      <WrapColorPicture color={theme.palette.grayscale[0]}>
        <OurService {...props}/>
      </WrapColorPicture>
      <Controller>
        <Scene classToggle={[".start-project", "animate"]} triggerElement="#start-project"triggerHook={0.90} indicators={false} reverse={false}>
          <div style={{height: '300px'}}>
          <Particles
            className="canvas_content"
            params={{
              particles: {
                number: {
                  value: 100,
                },
                size: {
                  value: 3,
                },
                move: {
                  random: true,
                  speed: 1,
                },
                color: {
                  value: '#1B8FF8'
                },
                line_linked: {
                  enable: true,
                  color: "#5698D6",
                  opacity: 0.4,
                },
              },
              interactivity: {
                events: {
                  onhover: {
                    enable: true,
                    mode: "repulse",
                  },
                },
              },

            }
            }
          />
            <StartProject/>
          </div>
        </Scene>
      </Controller>
      {/*<Controller>*/}
      {/*  <Scene classToggle={["#portfolio-title", "animate"]} triggerElement="#portfolio-title"triggerHook={0.90} indicators={false} reverse={false}>*/}
      {/*    <WrapColorPicture padding="PortfolioSolutions" color={theme.palette.grayscale[0]}>*/}
      {/*      <Title id="portfolio-title">Portfolio solutions</Title>*/}
      {/*    </WrapColorPicture>*/}
      {/*  </Scene>*/}
      {/*</Controller>*/}
      {dataPortfolio?.map((portfolio: any, index: number) =>
        <PortfolioCard data={portfolio} key={index} {...props}/>
      )}
      {/*<WrapColorPicture color={theme.palette.grayscale[0]}>*/}
      {/*  <OurTeam {...props}/>*/}
      {/*</WrapColorPicture>*/}
      <WrapColorPicture color={theme.palette.black[0]}>
        <Question/>
      </WrapColorPicture>
    </>
  )
}

export default LandingContent;

const dataPortfolio = [
  {
    id: "food-track",
    title: "AB-Way",
    text: "Closing the gap between food producers, restaurant owners and delivery vendors.\n",
    stacks: ["PYTHON", "DJANGO", "REACT-NATIVE", "POSTGRESQL", "TWILIO"],
    color: "#2A96B7",
    picture_url: FoodTrack,
    mobile_picture_url: FoodTrackMobile,
    type: "mobile"
  },
  {
    id: "the-brand-collector",
    title: "The Brand Collector",
    text: "The platform in real-time automatically monitors the availability     of goods from suppliers as well as the public mentions of the goods and tracks related sales transactions on more than 15 platforms.",
    stacks: ["PYTHON", "DJANGO", "ANGULAR", "POSTGRESQL", "EBAY API", "KAFKA", "CELERY", "RABBITMQ", "REDIS", "EBAY MPI", "DOCKER"],
    color: "#2B344B",
    picture_url: BrandCollector,
    mobile_picture_url: BrandCollectorMobile,
    type: "desktop"
  },
  // {
  //   id: null,
  //   title: "YouHiredMe",
  //   text: "The platform in real-time automatically monitors the availability of goods from suppliers as well as the public mentions of the goods and tracks related sales transactions on more than 15 platforms.",
  //   stacks: ["PYTHON", "DJANGO", "ANGULAR", "POSTGRESQL", "RXJS"],
  //   color: "#1A8C0D",
  //   picture_url: Pic
  // },
  {
    id: "jett-pro",
    title: "JettPro",
    text: "JettPro is a provider of high-quality services on repair and technical maintenance of aviation equipment for various airlines throughout the continental U.S. The company was awarded the prestigious Diamond Award and FAA for four years in a row. Our collaboration resulted in the creation of a platform for document management automation and, therefore, increase in speed of the maintenance services.",
    stacks: ["PYTHON", "DJANGO", "CELERY", "REACT", "REDIS", "POSTGRESQL"],
    color: "#186F3B",
    picture_url: JettPro,
    mobile_picture_url: JettProMobile,
    type: "desktop"
  },
]