import bodies1 from "../img/projects/bodies/bodies1.png";
import bodies2 from "../img/projects/bodies/bodies2.png";
import bodies3 from "../img/projects/bodies/bodies3.png";
import bodiesMobile1 from "../img/projects/bodies/bodies-mobile1.png";
import bodiesMobile2 from "../img/projects/bodies/bodies-mobile2.png";
import bodiesMobile3 from "../img/projects/bodies/bodies-mobile3.png";
import vybe1 from "../img/projects/vybe/vybe1.png";
import vybe2 from "../img/projects/vybe/vybe2.png";
import vybeMobile1 from "../img/projects/vybe/vybe-mobile1.png";
import vybeMobile2 from "../img/projects/vybe/vybe-mobile2.png";
import foodTrack1 from "../img/projects/foodTrack/food-track1.png";
import foodTrack2 from "../img/projects/foodTrack/food-track2.png";
import foodTrack3 from "../img/projects/foodTrack/food-track3.png";
import foodTrackMobile1 from "../img/projects/foodTrack/food-track-mobile1.png";
import foodTrackMobile2 from "../img/projects/foodTrack/food-track-mobile2.png";
import foodTrackMobile3 from "../img/projects/foodTrack/food-track-mobile3.png";
import amazingBnb1 from "../img/projects/amazingbnb/amazingbnb1.png";
import amazingBnb2 from "../img/projects/amazingbnb/amazingbnb2.png";
import amazingBnb3 from "../img/projects/amazingbnb/amazingbnb3.png";
import amazingBnbMobile1 from "../img/projects/amazingbnb/amazingbnb-mobile1.png";
import amazingBnbMobile2 from "../img/projects/amazingbnb/amazingbnb-mobile2.png";
import amazingBnbMobile3 from "../img/projects/amazingbnb/amazingbnb-mobile3.png";
import jettPro1 from "../img/projects/jettpro/jettpro1.png";
import jettPro2 from "../img/projects/jettpro/jettpro2.png";
import jettPro3 from "../img/projects/jettpro/jettpro3.png";
import jettPro4 from "../img/projects/jettpro/jettpro4.png";
import jettProMobile1 from "../img/projects/jettpro/jettpro-mobile1.png";
import jettProMobile2 from "../img/projects/jettpro/jettpro-mobile2.png";
import jettProMobile3 from "../img/projects/jettpro/jettpro-mobile3.png";
import jettProMobile4 from "../img/projects/jettpro/jettpro-mobile4.png";
import boardDirector1 from "../img/projects/boardDirector/board-director1.png";
import boardDirector2 from "../img/projects/boardDirector/board-director2.png";
import boardDirector3 from "../img/projects/boardDirector/board-director3.png";
import boardDirector4 from "../img/projects/boardDirector/board-director4.png";
import boardDirectorMobile1 from "../img/projects/boardDirector/board-director-mobile1.png";
import boardDirectorMobile2 from "../img/projects/boardDirector/board-director-mobile2.png";
import boardDirectorMobile3 from "../img/projects/boardDirector/board-director-mobile3.png";
import boardDirectorMobile4 from "../img/projects/boardDirector/board-director-mobile4.png";
import brandCollector1 from "../img/projects/brandCollector/brand-collector1.png";
import brandCollector2 from "../img/projects/brandCollector/brand-collector2.png";
import brandCollectorMobile1 from "../img/projects/brandCollector/brand-collector-mobile1.png";
import brandCollectorMobile2 from "../img/projects/brandCollector/brand-collector-mobile2.png";
import refunite1 from "../img/projects/refunite/refunite1.png";
import refunite2 from "../img/projects/refunite/refunite2.png";
import refunite3 from "../img/projects/refunite/refunite3.png";
import refuniteMobile1 from "../img/projects/refunite/refunite-mobile1.png";
import refuniteMobile2 from "../img/projects/refunite/refunite-mobile2.png";
import refuniteMobile3 from "../img/projects/refunite/refunite-mobile3.png";
import BrandCollector from "../img/projects/brandCollector/BrandCollector.png";
import BrandCollectorMobile from "../img/projects/brandCollector/BrandCollectorMobile.png";
import BoardDirector from "../img/projects/boardDirector/BoardDirector.png";
import BoardDirectorMobile from "../img/projects/boardDirector/BoardDirectorMobile.png";
import JettPro from "../img/projects/jettpro/JettPro.png";
import JettProMobile from "../img/projects/jettpro/JettProMobile.png";
import Bodies from "../img/projects/bodies/Bodies.png";
import BodiesMobile from "../img/projects/bodies/BodiesMobile.png";
import FoodTrack from "../img/projects/foodTrack/FoodTrack.png";
import FoodTrackMobile from "../img/projects/foodTrack/FoodTrackMobile.png";
import AmazingBnb from "../img/projects/amazingbnb/AmazingBnb.png";
import AmazingBnbMobile from "../img/projects/amazingbnb/AmazingBnbMobile.png";
import Refunite from "../img/projects/refunite/Refunite.png";
import RefuniteMobile from "../img/projects/refunite/RefuniteMobile.png";
import VybeMobile from "../img/projects/vybe/VybeMobile.png";
import Vybe from "../img/projects/vybe/Vybe.png";

const baseUrl = process.env.REACT_APP_API;

const apiCall = async (method: any, url: string, body: any) => {
  // const token = await getToken().then( (value: any) => value.value);
  const token = null;

  if (token) {
    const fetchOpts: any = {
      method,
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    };

    if (body) {
      fetchOpts.body = JSON.stringify(body);
    }
    return fetch(baseUrl + url, fetchOpts);
  } else return apiCallWithoutToken(method, url, body);
};

const apiCallWithoutToken = async (method: any, url: string, body: any) => {
  const fetchOpts: any = {
    method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  };

  if (body) {
    fetchOpts.body = body;
  }
  return fetch(baseUrl + url, fetchOpts);
};

const api = {
  connect: {
    sendMessage(data: any) {
      return apiCallWithoutToken('POST', 'proposals/', data);
    },
  },
};

export default api;

export const getProject = (id:string) => {
  const project = projects.find((project: any) => project.id === id);
  if (project) return project;
  window.location.href = "/";
  return null
}

export const getDataProjects = (data:any) => data.reduce((a: any, value: number) => {
    let result = dataProjects().find((project: any) => project.id === value);
    if (result) return [...a, result]
  }, []);

const projects = [
  {
    id: "bodies",
    name: "Bodies",
    description: "A Bodies is a platform connecting people who seek to make sports a significant part of their lives. It provides users with an opportunity to find the best coach and most comfortable place for their workout.",
    stacks: ["IONIC", "PYTHON", "DJANGO", "POSTGRESQL", "AWS", "TWILIO", "SENDGRID", "HYPERWALLET"],
    thematic: ["Fitness", "Health"],
    color: "#006379",
    type: "mobile",
    slides: [
      {
        count: 1,
        text: ["We're working on the user app, allowing users to register a session with a coach, and the web-worms enabling coaches and hosts to register on the platform and make full use of it."],
        list: [],
        image: bodies1,
        image_mobile: bodiesMobile1
      },
      {
        count: 2,
        text: ["The core features of the mobile app are:"],
        list: ["Registering in the system", "Session booking", "Rescheduling and cancelling", "Notifications", "Rating system"],
        image: bodies2,
        image_mobile: bodiesMobile2
      },
      {
        count: 3,
        text: ["Our work on the platform includes:"],
        list: ["Architecture design", "Back-end integration", "Geolocation integration", "Salesforce integration", "Google maps integration", "Django views development etc."],
        image: bodies3,
        image_mobile: bodiesMobile3
      },
    ]
  },
  {
    id: "food-track",
    name: "AB-Way",
    description: "A digital ecosystem that consists of three separate mobile apps built so that they do not cross with each other.",
    stacks: ["PYTHON", "DJANGO", "REACT-NATIVE", "POSTGRESQL", "TWILIO"],
    thematic: ["Food industry", "Restaurant management"],
    color: "#2378C6",
    type: "mobile",
    slides: [
      {
        count: 1,
        text: ["Restaurant owners can add several of its restaurants, bind several addresses to them and select products from an extensive catalog, comparing prices, delivery terms and also the rating and reviews of both suppliers and carriers."],
        list: [],
        image: foodTrack1,
        image_mobile: foodTrackMobile1
      },
      {
        count: 2,
        text: ["Suppliers, in turn, also gain access to this shared directory from their app. They have the opportunity to work both with products of other manufacturers (alcohol, preservation, semi-processed products) and their own. Implemented a flexible system for adding goods, as well as any related filters."],
        list: [],
        image: foodTrack2,
        image_mobile: foodTrackMobile2
      },
      {
        count: 3,
        text: ["Private carriers and delivery companies also get access to the system from their end where they can choose from set orders with detailed shipping information like weight, overall dimensions, loading/unloading addresses and so they can bid with their offers. Restaurants receive offers and choose a carrier by price and reviews."],
        list: [],
        image: foodTrack3,
        image_mobile: foodTrackMobile3
      },
    ]
  },
  {
    id: "amazing-bnb",
    name: "AmazingBnb",
    description: "The question of finding accommodation during the trip has always been relevant to travelers. We have designed and developed an online, mobile platform AmazingBnb.com that provides online booking for Hotels and B&B.",
    stacks: ["PYTHON", "DJANGO", "ANGULAR", "POSTGRESQL", "RXJS"],
    thematic: ["Hotel industry"],
    color: "#AA1A4E",
    type: "desktop",
    slides: [
      {
        count: 1,
        text: ["The platform is designed specifically for Manhattan Hotel Group."],
        list: [],
        image: amazingBnb1,
        image_mobile: amazingBnbMobile1
      },
      {
        count: 2,
        text: ["The application supports the following features:"],
        list: ["Search for hotels", "Hotel booking", "Integration into external hotel search services", "Saving personal booking history"],
        image: amazingBnb2,
        image_mobile: amazingBnbMobile2
      },
      {
        count: 3,
        text: ["AmazingBnb was created from scratch, including"],
        list: ["Database structure", "Flexible expandable API", "User-friendly interface"],
        image: amazingBnb3,
        image_mobile: amazingBnbMobile3
      },
    ]
  },
  {
    id: "jett-pro",
    name: "JettPro",
    description: "Maintenance of aircraft is not a simple task, especially if you have to support a great number of aircraft in different locations.We designed and developed a platform automating a considerable part of paper work and increasing the speed of services during the maintenance of aviation equipment and aircraft.",
    stacks: ["PYTHON", "DJANGO", "CELERY", "REACT", "REDIS", "POSTGRESQL"],
    thematic: ["Company management"],
    color: "#186F3B",
    type: "desktop",
    slides: [
      {
        count: 1,
        text: ["The platform is intended for internal use in the JettPro company. Below are the core features:"],
        list: [
          "Document turnover automation", "Time accounting/calculation for maintenance and repair of different aircrafts", "Modules for tools calibration",
          "Inventory accounting modules", "Users notification modules within the system, (Important messages, material for study, reminders of certification, etc.)",
          "Reporting Modules (on payments, balances on the warehouse, accounting and maintenance, etc.", "Modules for training and certification of personnel"
        ],
        image: jettPro1,
        image_mobile: jettProMobile1
      },
      {
        count: 2,
        text: ["The most complex part of the project we're especially proud of is the back-end solution our team came up with. As a result, we developed a high-performance product capable of synchronizing and structuring a huge load of various data smoothly and without loss.",
          "Just imagine how many details there are to to note, update or replace during the aircraft maintenance."],
        list: [],
        image: jettPro2,
        image_mobile: jettProMobile2
      },
      {
        count: 3,
        text: ["JettPro already had some of the legacy code before we got down to the development of the new platform. Before developing new features we updated the existing modules and fixed the bugs, which included:"],
        list: ["UI updates", "Implementing complex filtering by different types and options", "Introducing dynamic report generation and export to the different file formats (such as PDF and other)"],
        image: jettPro3,
        image_mobile: jettProMobile3
      },
      {
        count: 4,
        text: ["As a result of our work, JettPro received a complete application, which brought their services to a new level and optimized the workflow significantly."],
        list: [],
        image: jettPro4,
        image_mobile: jettProMobile4
      },
    ]
  },
  {
    id: "board-director",
    name: "BoardDirector",
    description: "BoardDirector is a convenient scheduler facilitating the collaboration between Board Members and Management.",
    stacks: ["ANGULAR", "RXJS", "POSTGRESQL"],
    thematic: ["Hotel industry"],
    color: "#7A3588",
    type: "desktop",
    slides: [
      {
        count: 1,
        text: ["It allows to manage the upcoming Board of Director’s Meetings, Committee Meetings, or special events such as a Board Retreat or fundraiser."],
        list: [],
        image: boardDirector1,
        image_mobile: boardDirectorMobile1
      },
      {
        count: 2,
        text: ["BoardDirector makes it much easier to prepare and schedule board meetings, attach the Agenda, Minutes, and Reports, notify members with calendar invites, and track RSVPs."],
        list: [],
        image: boardDirector2,
        image_mobile: boardDirectorMobile2
      },
      {
        count: 3,
        text: ["It also includes such functionalities as"],
        list: [
          "Repository for Documents", "Inventory accounting modules", "Modules for training and certification of personnel",
          "Guest Permissions", "News & Announcements", "Committee Workspaces", "Voting, Polling", "Special Events"
        ],
        image: boardDirector3,
        image_mobile: boardDirectorMobile3
      },
      {
        count: 4,
        text: ["The tool enables a truly digital process for creating and managing board meetings and allows all the members work seamlessly together as a fully functioning boardroom. This will help everyone across the team be more productive, less stressed, and better prepared."],
        list: [],
        image: boardDirector4,
        image_mobile: boardDirectorMobile4
      }
    ]
  },
  {
    id: "the-brand-collector",
    name: "The Brand Collector",
    description: "The platform in real-time automatically monitors the availability of goods from suppliers as well as the public mentions of the goods and tracks related sales transactions on more than 15 platforms.",
    stacks: ["PYTHON", "DJANGO", "ANGULAR", "POSTGRESQL", "EBAY API", "KAFKA", "CELERY", "RABBITMQ", "REDIS", "EBAY MPI", "DOCKER"],
    thematic: ["Fashion", "Global sales"],
    color: "#2B344B",
    type: "desktop",
    slides: [
      {
        count: 1,
        text: ["We developed and implemented Integrations with eBay API, eBay MIP, Shopify, with more than 15 international online trade platforms for the sale of used luxury goods, CSV and XLS feed by suppliers were implemented. Public API for suppliers."],
        list: [],
        image: brandCollector1,
        image_mobile: brandCollectorMobile1
      },
      {
        count: 2,
        text: ["Flexible system to automatically determine trade margins. Notification system for suppliers and company employees. Backoffice system for managing all events and products."],
        list: [],
        image: brandCollector2,
        image_mobile: brandCollectorMobile2
      }
    ]
  },
  {
    id: "refunite",
    name: "REFUNITE",
    description: "REFUNITE is the world’s largest missing persons platform for refugees and displaced populations.",
    stacks: ["DJANGO", "GO", "AWS (ECS, RDS, SNS, SQS, ETC)", "DOCKER"],
    thematic: ["Company management"],
    color: "#2378C6",
    type: "desktop",
    slides: [
      {
        count: 1,
        text: ["Refugees United is a non-profit organisation helping refugee families that have lost contact with each other during escape from conflict with an anonymous service to reconnect."],
        list: [],
        image: refunite1,
        image_mobile: refuniteMobile1
      },
      {
        count: 2,
        text: [],
        list: ["Over 40,000 reconnected families", "More than 1 million registrations", "Available free of charge in 17 countries via FreeBasics", "2000+ searches performed daily"],
        image: refunite2,
        image_mobile: refuniteMobile2
      },
      {
        count: 3,
        text: ["We use proven technologies to make the app high-performance and easy to use."],
        list: [],
        image: refunite3,
        image_mobile: refuniteMobile3
      },
    ]
  },
  {
    id: "vybe",
    name: "Vybe",
    description: "The neobank for young people (from the age of 13) available in the UK and in France, with all the services free of charge, whether it's the app, the card or its perks.",
    stacks: ["ANGULAR", "RXJS", "POSTGRESQL"],
    thematic: ["Hotel industry"],
    color: "#006379",
    type: "mobile",
    slides: [
      {
        count: 1,
        text: ["Vybe Card app allows to transfer and receive money, to pay with the phone online or in stores around the world, to see the list of all transactions, and to personalize the card."],
        list: [],
        image: vybe1,
        image_mobile: vybeMobile1
      },
      {
        count: 2,
        text: ["The main features include:"],
        list: ["Instant money transfer from account to account", "Notifications for every purchase to track your account balance", "Blocking your card or changing your PIN at any time"],
        image: vybe2,
        image_mobile: vybeMobile2
      },
    ]
  },
]

export const dataProjects = () => [
  {
    id: "the-brand-collector",
    title: "The Brand Collector",
    text: "The platform in real-time automatically monitors the availability of goods from suppliers as well as the public mentions of the goods and tracks related sales transactions on more than 15 platforms.",
    stacks: ["PYTHON", "DJANGO", "ANGULAR", "POSTGRESQL", "EBAY API", "KAFKA", "CELERY", "RABBITMQ", "REDIS", "EBAY MPI", "DOCKER"],
    color: "#2B344B",
    picture_url: BrandCollector,
    mobile_picture_url: BrandCollectorMobile,
    type: "desktop"
  },
  // {
  //   id: null,
  //   title: "YouHiredMe",
  //   text: "The platform in real-time automatically monitors the availability of goods from suppliers as well as the public mentions of the goods and tracks related sales transactions on more than 15 platforms.",
  //   stacks: ["PYTHON", "DJANGO", "ANGULAR", "POSTGRESQL", "RXJS"],
  //   color: "#276C1F",
  //   picture_url: Pic
  // },
  {
    id: "jett-pro",
    title: "JettPro",
    text: "JettPro is a provider of high-quality services on repair and technical maintenance of aviation equipment for various airlines throughout the continental U.S. The company was awarded the prestigious Diamond Award and FAA for four years in a row. Our collaboration resulted in the creation of a platform for document management automation and, therefore, increase in speed of the maintenance services.",
    stacks: ["PYTHON", "DJANGO", "CELERY", "REACT", "REDIS", "POSTGRESQL"],
    color: "#186F3B",
    picture_url: JettPro,
    mobile_picture_url: JettProMobile,
    type: "desktop"
  },
  {
    id: "food-track",
    title: "AB-Way",
    text: "Closing the gap between food producers, restaurant owners and delivery vendors.",
    stacks: ["PYTHON", "DJANGO", "REACT-NATIVE", "POSTGRESQL", "TWILIO"],
    color: "#2378C6",
    picture_url: FoodTrack,
    mobile_picture_url: FoodTrackMobile,
    type: "mobile"
  },
  {
    id: "amazing-bnb",
    title: "AmazingBnb",
    text: "A platform similar to famous systems for booking hotels, apartments, villas, etc., focused on the real estate in Asia.",
    stacks: ["PYTHON", "DJANGO", "ANGULAR", "POSTGRESQL", "RXJS"],
    color: "#AA1A4E",
    picture_url: AmazingBnb,
    mobile_picture_url: AmazingBnbMobile,
    type: "desktop"
  },
  {
    id: "bodies",
    title: "Bodies",
    text: "A Bodies is a platform connecting people who seek to make sports a significant part of their lives. It provides users with an opportunity to find the best coach and most comfortable place for their workout.",
    stacks: ["IONIC", "PYTHON", "DJANGO", "POSTGRESQL", "AWS", "TWILIO", "SENDGRID", "HYPERWALLET"],
    color: "#006379",
    picture_url: Bodies,
    mobile_picture_url: BodiesMobile,
    type: "desktop"
  },
  {
    id: "board-director",
    title: "BoardDirector",
    text: "A collaboration tool allowing Board Members to communicate with Management and exchange the necessary information. The application provides instant access to data helping the board members prepare for the meetings.",
    stacks: ["ANGULAR", "RXJS", "POSTGRESQL"],
    color: "#7A3588",
    picture_url: BoardDirector,
    mobile_picture_url: BoardDirectorMobile,
    type: "desktop"
  },
  {
    id: "refunite",
    title: "REFUNITE",
    text: "REFUNITE is the world’s largest missing persons platform for refugees and displaced populations.",
    stacks: ["DJANGO", "GO", "AWS (ECS, RDS, SNS, SQS, ETC)", "DOCKER"],
    color: "#2378C6",
    picture_url: Refunite,
    mobile_picture_url: RefuniteMobile,
    type: "desktop"
  },
  {
    id: "vybe",
    title: "Vybe",
    text: "The neobank for young people (from the age of 13) available in the UK and in France, with all the services free of charge, whether it's the app, the card or its perks.",
    stacks: ["ANGULAR", "RXJS", "POSTGRESQL"],
    color: "#1B2340",
    picture_url: Vybe,
    mobile_picture_url: VybeMobile,
    type: "mobile"
  },
]