import styled from 'styled-components';
import theme from "../../themes/default";

type PropsStyle = {
  rotate?: string
  color?: string
  top?: string
  bottom?: string
  left?: string
  right?: string
  arrows?: any
}

export const ArrowStyle = styled.div<PropsStyle>`  
  width: 58px;
  height: 22px;
  background-image: url(${({arrows}) => arrows});
  background-repeat: no-repeat;
  top: ${({top}) => top};
  bottom: ${({bottom}) => bottom};
  left: ${({left}) => left};
  right: ${({right}) => right};
  display: inline-block;
  position: absolute;
  transform: rotate(${({rotate}) => rotate});
  margin: auto;
    
  @media (min-width: 993px) and (max-width: 1440px) {
    width: 47px;
    height: 19px;
    background-image: url(${({arrows}) => arrows["2"]});
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    width: 37px;
    height: 15px;
    background-image: url(${({arrows}) => arrows["3"]});
  }
  
  @media (max-width: 768px) {
    width: 37px;
    height: 15px;
    background-image: url(${({arrows}) => arrows["3"]});
  }
`