import React from "react";
import { Container, PaddingContent, WithShadow, WrapContent } from "./wrap-color-picture-styles";

type PropsStyle = {
  color?: string
  image?: string
  padding?: string
  shadow?: string
  id?: string
  animateClass?: string
  height?: string
  display?: string
}

const WrapColorPicture:React.FC<PropsStyle> = ({
                                                 children,
                                                 color,
                                                 image,
                                                 padding,
                                                 shadow,
                                                 id,
                                                 animateClass,
                                                 height,
                                                 display
}) => {
  return (
    <Container color={color} image={image} display={display} id={id} className={animateClass ? animateClass : "" } height={height}>
      {shadow ?
        <WithShadow shadow={shadow}>
          <WrapContent>
            <PaddingContent padding={padding}>{children}</PaddingContent>
          </WrapContent>
        </WithShadow>:
        <WrapContent>
          <PaddingContent padding={padding}>{children}</PaddingContent>
        </WrapContent>
      }
    </Container>
  )
}

export default WrapColorPicture