import React, { useEffect, useState } from "react";
import { Controller, Scene } from "react-scrollmagic";
import {
  CountSlide,
  DesktopImg,
  DesktopText,
  ItemList,
  ListSlide,
  MobileImg,
  MobileText,
  TextSlide,
  Wrap,
  WrapButton
} from "./slide-card-styles";
import Button from "../../../../components/button/Button";
import theme from "../../../../themes/default";

type PropsType = {
  data: any
  slide: any
  index: number
  isLast: boolean
  history?: any
  id?: string
}

const SlideCard:React.FC<PropsType> = (props) => {
  const { data, slide, index, isLast, history, id } = props;
  const [isMinimize, toggleMinimize] = useState(false);

  const listenResize = () => {
    let width = window.innerWidth;
    if (width > 768) {
      toggleMinimize(false);
    } else { toggleMinimize(true)}
  }

  useEffect(() => {
    window.addEventListener("resize", listenResize);
    listenResize();
    return () => window.removeEventListener("resize", listenResize)
  }, []);

  return (
    <>
      <Wrap isWhite={!(index % 2)} id={id}>
        {data.type === "mobile" &&
          <>
            {(!(index % 2) || isMinimize) &&
            <Controller>
              <Scene classToggle={[`.slide-img-${index}`, "animate"]} triggerElement={`#${id}`} triggerHook={0.80} indicators={false} reverse={false}>
                <MobileImg isWhite={!(index % 2)} className={`slide-img-${index}`}>
                  {isMinimize ?
                    <img src={slide.image_mobile} alt=""/> :
                    <img src={slide.image} alt=""/>
                  }
                </MobileImg>
              </Scene>
            </Controller>
            }
            <MobileText isWhite={!(index % 2)}>
              <Controller>
                <Scene classToggle={[`#slide-count-${index}`, "animate"]} triggerElement={`#slide-count-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                  <CountSlide id={`slide-count-${index}`}>{slide.count}.</CountSlide>
                </Scene>
              </Controller>
              {slide.text.length > 0 && slide.text.map((item: string, i: number) =>
                <Controller key={i}>
                  <Scene classToggle={[`.slide-text-${index}-${i}`, "animate"]} triggerElement={`#slide-count-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <TextSlide
                      key={i}
                      className={`slide-text-${index}-${i}`}
                    >{item}</TextSlide>
                  </Scene>
                </Controller>
              )}

              {slide.list.length > 0 &&
                <ListSlide id={`slide-list-${index}`}>
                  {slide.list.map((item: string, i: number) =>
                    <Controller key={i}>
                      <Scene classToggle={[`.slide-item-${index}-${i}`, "animate"]} triggerElement={`#slide-list-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                        <ItemList
                          key={i}
                          isLeft={!!(index%2)}
                          count={i+1}
                          color={!(index % 2) ?
                            data.color :
                            theme.palette.white[0]}
                          className={`slide-item-${index}-${i}`}
                        >{item}</ItemList>
                      </Scene>
                    </Controller>
                  )}
                </ListSlide>
              }
            </MobileText>
            {(!!(index % 2) && !isMinimize) &&
            <Controller>
              <Scene classToggle={[`.slide-img-${index}`, "animate"]} triggerElement={`#${id}`} triggerHook={0.80} indicators={false} reverse={false}>
                <MobileImg isWhite={!(index % 2)} className={`slide-img-${index}`}>
                  <img src={slide.image} alt=""/>
                </MobileImg>
              </Scene>
            </Controller>
            }
          </>
        }
        {data.type === "desktop" &&
          <>
            {(!!(index % 2) || isMinimize) &&
            <Controller>
              <Scene classToggle={[`.slide-img-${index}`, "animate"]} triggerElement={`#${id}`} triggerHook={0.80} indicators={false} reverse={false}>
                <DesktopImg isWhite={!(index % 2)} className={`slide-img-${index}`}>
                  {isMinimize ?
                    <img src={slide.image_mobile} alt=""/> :
                    <img src={slide.image} alt=""/>
                  }
                </DesktopImg>
              </Scene>
            </Controller>
            }
            <DesktopText isWhite={!(index % 2)}>
              <Controller>
                <Scene classToggle={[`#slide-count-${index}`, "animate"]} triggerElement={`#slide-count-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                  <CountSlide id={`slide-count-${index}`}>{slide.count}.</CountSlide>
                </Scene>
              </Controller>
              {slide.text.length > 0 && slide.text.map((item: string, i: number) =>
                <Controller key={i}>
                  <Scene classToggle={[`.slide-text-${index}-${i}`, "animate"]} triggerElement={`#slide-count-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <TextSlide
                      key={i}
                      className={`slide-text-${index}-${i}`}
                    >{item}</TextSlide>
                  </Scene>
                </Controller>
              )}
              {slide.list.length > 0 &&
              <ListSlide id={`slide-list-${index}`}>
                {slide.list.map((item: string, i: number) =>
                  <Controller key={i}>
                    <Scene classToggle={[`.slide-item-${index}-${i}`, "animate"]} triggerElement={`#slide-list-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                      <ItemList
                        key={i}
                        count={i+1}
                        isLeft={!(index%2)}
                        color={!(index % 2) ?
                          data.color :
                          theme.palette.white[0]}
                        className={`slide-item-${index}-${i}`}
                      >{item}</ItemList>
                    </Scene>
                  </Controller>
                )}
              </ListSlide>
              }
            </DesktopText>
            {(!(index % 2) && !isMinimize) &&
            <Controller>
              <Scene classToggle={[`.slide-img-${index}`, "animate"]} triggerElement={`#${id}`} triggerHook={0.80} indicators={false} reverse={false}>
                <DesktopImg isWhite={!(index % 2)} className={`slide-img-${index}`}>
                  <img src={slide.image} alt=""/>
                </DesktopImg>
              </Scene>
            </Controller>
            }
          </>
        }
        {isLast &&
          <Controller>
            <Scene classToggle={[`#project-button-back`, "animate"]} triggerElement={`#project-button-back`} triggerHook={1} indicators={false} reverse={false}>
              <WrapButton id="project-button-back">
                <Button color="red" onClick={() => history.goBack()}>Back</Button>
              </WrapButton>
            </Scene>
          </Controller>
        }
      </Wrap>
    </>
  )
}

export default SlideCard;