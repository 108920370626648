import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import Button from "../../../../../components/button/Button";
import {
  ButtonWrap,
  IconWrap,
  OurText,
  ServiceCard,
  ServicesContainer,
  ServiceText,
  ServiceTitle,
  TextInfo,
} from "./our-service-styles";
import { Title } from "../../landing-content.styles";
import webDevelop from "../../../../../img/web_develop.svg";
import devops from "../../../../../img/devops.svg";
import design from "../../../../../img/web_design.svg";
import mobile from "../../../../../img/mobile_develop.svg";

type PropsType = {
  history?: any;
};

const OurService: React.FC<PropsType> = (props) => {
  const { history } = props;
  return (
    <div id="our-service">
      <Controller>
        <Scene
          classToggle={[".our-service-title", "animate"]}
          triggerElement="#our-service"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <Title className="our-service-title" delay={0.4}>
            Our Services
          </Title>
        </Scene>
      </Controller>
      <TextInfo>
        <Controller>
          <Scene
            classToggle={["#our-service-text", "animate"]}
            triggerElement="#our-service-text"
            triggerHook={0.8}
            indicators={false}
            reverse={false}
          >
            <OurText id="our-service-text">{text}</OurText>
          </Scene>
        </Controller>
        <Controller>
          <Scene
            classToggle={[".our-service-button", "animate"]}
            triggerElement="#our-service-text"
            triggerHook={0.8}
            indicators={false}
            reverse={false}
          >
            <ButtonWrap className="our-service-button">
              <Button
                isArrow={true}
                color="darkBlue"
                onClick={() => {
                  history.push(`/services`);
                }}
              >
                Show all services
              </Button>
            </ButtonWrap>
          </Scene>
        </Controller>
      </TextInfo>
      <ServicesContainer id="our-services-blocks">
        {dataServices.map((service: any, index: number) => (
          <Controller key={index}>
            <Scene
              classToggle={[`#our-service-card-${index}`, "animate"]}
              triggerElement={`#our-service-card-${index}`}
              triggerHook={0.8}
              indicators={false}
              reverse={false}
            >
              <ServiceCard
                color={service.color}
                key={index}
                id={`our-service-card-${index}`}
              >
                <Controller>
                  <Scene
                    classToggle={[
                      `.our-service-card-title-${index}`,
                      "animate",
                    ]}
                    triggerElement={`#our-service-card-${index}`}
                    triggerHook={0.8}
                    indicators={false}
                    reverse={false}
                  >
                    <ServiceTitle className={`our-service-card-title-${index}`}>
                      <IconWrap src={service.img} />
                      {service.title}
                    </ServiceTitle>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene
                    classToggle={[`.our-service-card-text-${index}`, "animate"]}
                    triggerElement={`#our-service-card-${index}`}
                    triggerHook={0.8}
                    indicators={false}
                    reverse={false}
                  >
                    <ServiceText className={`our-service-card-text-${index}`}>
                      {service.text}
                    </ServiceText>
                  </Scene>
                </Controller>
              </ServiceCard>
            </Scene>
          </Controller>
        ))}
      </ServicesContainer>
    </div>
  );
};

export default OurService;

const text =
  "We always control and evaluate our work to make sure that our processes run smoothly, our products are well-developed, and the results meet your expectations.\n";

const dataServices = [
  {
    color: "darkBlue",
    title: "Web development",
    img: webDevelop,
    text: "We provide development of web applications for a variety of business sectors. Nord-Soft leverages an extended set of programming languages, frameworks, libraries and tools to build projects of any scale and complexity and transform your ideas into innovative solutions.",
  },
  {
    color: "red",
    title: "DevOps",
    img: devops,
    text: "Our team has extensive experience working with various hosting providers. We offer server setup/configuration and automation to support delivery across all of your applications, and use   the most up-to-date tools to ensure stable and predictable processes of continuous delivery.",
  },
  {
    color: "blue",
    title: "UX/UI Design",
    img: design,
    text: "Custom web designs strategically built to engage your audience, drive goal conversions & produce measurable results. We offer the following services: prototyping, UI and UX design for web and mobile products.",
  },
  {
    color: "lightBlue",
    title: "Mobile apps development",
    img: mobile,
    text: "We focus on React Native technology for iOS and Android applications. We offer full cycle of frontend and backend development, from mobile app UX/UI design to App Store and Google Play rollout and system maintenance.",
  },
];
