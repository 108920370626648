import styled from 'styled-components';
import theme from "../themes/default";

export const AppContainer = styled.div<any>`
  min-height: 100%;
  min-width: ${theme.sizes.minWidth};
  width: 100%;
  background-color: ${theme.palette.grayscale[0]};
  color: ${theme.palette.black[0]};
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fontSizes.texts[0]}px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 15px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
`

export const ContainerWrap =styled.div`
  position: relative;
  background-color: ${theme.palette.grayscale[0]};
`