import React from "react";
import { inject, observer } from "mobx-react";
import { Controller, Scene } from "react-scrollmagic";
import {
  FullReviewContainer,
  InfoGrid,
  QuotationMarkContainer,
  ReviewGrid,
  TextGrid,
  UserNameFull,
  UserPosition
} from "./full-review-styles";
import { QuotationMark } from "../../clients-happy-styles";

type PropsType = {
  // selectedReview: any
}

const FullReview:React.FC<PropsType> = (props: any) => {
  const {authStore: { selectedReview,  }} = props;
  return (
    <FullReviewContainer selected={!!selectedReview} id="full-review">
      <Controller>
        <Scene classToggle={[".client-happy-mark", "animate"]} triggerElement="#full-review" triggerHook={0.90} indicators={false} reverse={false}>
          <QuotationMarkContainer className="client-happy-mark">
            <QuotationMark color="red">"</QuotationMark>
          </QuotationMarkContainer>
        </Scene>
      </Controller>
      <ReviewGrid>
        <Controller>
          <Scene classToggle={[".client-happy-text", "animate"]} triggerElement="#full-review" triggerHook={0.90} indicators={false} reverse={false}>
            <TextGrid className="client-happy-text">{selectedReview?.text}</TextGrid>
          </Scene>
        </Controller>
        <InfoGrid>
          <Controller>
            <Scene classToggle={[".client-happy-username", "animate"]} triggerElement="#full-review" triggerHook={0.90} indicators={false} reverse={false}>
              <UserNameFull className="client-happy-username">{selectedReview?.username}</UserNameFull>
            </Scene>
          </Controller>
          <Controller>
            <Scene classToggle={[".client-happy-position", "animate"]} triggerElement="#full-review" triggerHook={0.80} indicators={false} reverse={false}>
              <UserPosition className="client-happy-position">{selectedReview?.position}</UserPosition>
            </Scene>
          </Controller>
        </InfoGrid>
      </ReviewGrid>
    </FullReviewContainer>
  )
}

// export default FullReview;
export default inject((stores: any) => {
  return {
    authStore: stores.authStore,
  };
})(observer(FullReview));