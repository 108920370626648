import React from "react";
import Header from "../../../components/header/Header";
import { Controller, Scene } from "react-scrollmagic";
import { Title, TextInfo } from "./header-technologies-styles";
import { ImageWrap, WrapHeader } from "../../main/header/landing-headerstyles";
import Particles from "react-particles-js";
import Mouse from "../../../components/mouse/Mouse";

const HeaderTechnologies: React.FC = (props) => {
  return (
    <>
      <Header main="true" {...props} />
      <div style={{position: 'relative'}}>
      <Particles
        className="canvas"
        params={{
          particles: {
            number: {
              value: 150,
            },
            size: {
              value: 3,
            },
            move: {
              random: true,
              speed: 1,
            },
            color: {
              value: '#1B8FF8'
            },
            line_linked: {
              enable: true,
              color: "#5698D6",
              opacity: 0.4,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <Controller>
        <Scene
          classToggle={[".technologies-header", "animate"]}
          triggerElement="#technologies-container"
          triggerHook={0.9}
          indicators={false}
          reverse={false}
        >
          {/*<WrapColorPicture*/}
          {/*  padding="WrapHeader"*/}
          {/*  image={background_img}*/}
          {/*  animateClass={`technologies-header`}*/}
          {/*  height="100vh"*/}
          {/*  color={theme.palette.grayscale[0]}*/}
          {/*>*/}
            <WrapHeader id="technologies-container">
              <Controller>
                <Scene
                  classToggle={[".technologies-title-animate", "animate"]}
                  triggerElement="#technologies-container"
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <Title className="technologies-title-animate">Technologies</Title>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[".text-technologies-animate", "animate"]}
                  triggerElement="#technologies-container"
                  triggerHook={0.8}
                  indicators={false}
                  reverse={false}
                >
                  <TextInfo className="text-technologies-animate" isWhite={true}>
                    We develop solutions across a wide range of business
                    domains. All-out technology <br />
                    expertise, countless years of overall experience in <br />
                    numerous projects at your service in a blink of an eye.
                  </TextInfo>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle={[".technologies-header-image", "animate"]}
                  triggerElement="#technologies-container"
                  triggerHook={0.9}
                  indicators={false}
                  reverse={false}
                >
                  <ImageWrap className="technologies-header-image" >
                   <Mouse />
                  </ImageWrap>
                </Scene>
              </Controller>
            </WrapHeader>
          {/*</WrapColorPicture>*/}
        </Scene>
      </Controller>
      </div>
    </>
  );
};

export default HeaderTechnologies;
