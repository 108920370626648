import styled from 'styled-components';
import theme from "../../themes/default";

type PropsStyle = {
  isShowError?: boolean
}

export const MainContainer = styled.div`
  width: 100%;
  font-family: ${theme.fonts.primary};
  min-width: 320px;
`

export const ContentWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  position: relative;
  
  @media (max-width: 768px) {
    grid-template-columns: unset;
    margin-bottom: 30px;
  }
`

export const FormLetter = styled.div`
  
  @media (max-width: 768px) {
    grid-column-start: unset;
    grid-column-end: unset;
  }
`

export const InfoBlock = styled.div`

  >div:last-child{
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    grid-column-start: unset;
    grid-column-end: unset;
  }
`

export const FormTitle = styled.div`
  font-weight: bold;
  font-size: 48px;
  line-height: 130%;
  height: 70px;
  display: flex;
  align-items: flex-end;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 40px;
    height: 50px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 30px;
    height: 40px;
  }
  
  @media (max-width: 768px) {
    font-size: 28px;
    height: 40px;
  }
`

export const FormWrap = styled.div`
  
`

export const InputForm = styled.input`
  width: calc(100% - 20px);
  margin-top: 30px;
  font-size: 16px;
  line-height: 130%;
  border: unset;
  border-bottom: 1px solid ${theme.palette.black[0]};
  padding: 3px 10px;
  
  :focus{
    outline: unset;
    border-width: 2px;
  }
`

export const TextAreaForm = styled.textarea`
  width: calc(100% - 20px);
  margin-top: 30px;
  margin-bottom: -5px;
  font-size: 16px;
  line-height: 130%;
  padding: 3px 10px;
  height: 400px;
  resize: vertical;
`

export const ErrorMessage = styled.div<PropsStyle>`
  display: ${({isShowError}) => isShowError ? "block" : "none"};
  color: red;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
`

export const WrapButton = styled.div`
  display: flex;
  margin-top: 60px;
  position: relative;
  
  @media (max-width: 1440px) {
    margin: 25px 0;
    
    :last-child{
      margin: 25px 0 0;
    }
  }
  
  @media (max-width: 768px) {
    margin: ${theme.spaces[2]}px 0;
  
    :last-child{
      margin: ${theme.spaces[2]}px 0 0;
    }
  }
  
`

export const InfoTitle = styled.div`
  font-weight: 500;
  font-size: 30px;
  line-height: 133%;
  height: 70px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
  position: relative;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 26px;
    height: 50px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 22px;
    height: 40px;
  }
  
  @media (max-width: 768px) {
    font-size: 20px;
    height: 40px;
  }
`

export const InfoText = styled.div`
  font-size: 16px;
  line-height: 133%;
  margin-bottom: 30px;
`

export const WrapBoltInfo = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 133%;
  margin-bottom: 30px;
  
  a:first-child{
    margin-right: 10px;
  }
`

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10;
  
  span, span:before, span:after{
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: black;
    transition-duration: .25s;
  }
  
  span {
    transform: rotate(45deg);
  }
  
  span::before {
    content: '';
    top: 0;
    transform: rotate(0);
  }
  
  span::after {
    content: '';
    top: 0;
    transform: rotate(90deg);
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    width: 24px;
    height: 24px;
    top: 15px;
  }
  
  @media (max-width: 992px) {
    width: 20px;
    height: 20px;
    top: 10px;
  }
`