import React from "react";
import {SpinnerContainer, SpinnerContent} from "./spinner-styles";
import spinner from '../../img/spinner.gif';

type PropsType = {
  component?: string
  isGlobal?: boolean
};

const Spinner:React.FC<PropsType> = ({component, isGlobal = true}) => {
    return (
        <SpinnerContainer isGlobal={isGlobal}>
            <SpinnerContent component={component}>
                <img src={spinner} alt=""/>
            </SpinnerContent>
        </SpinnerContainer>
    )
}

export default Spinner