import React from "react";
import { inject, observer } from "mobx-react";
import { Controller, Scene } from "react-scrollmagic";
import Button from "../../../../../components/button/Button";
import { Title, WrapButton } from "./question-styles";

type PropsType = {
  authStore?: any
};

const Question:React.FC<PropsType> = ({authStore}) => {
  return (
    <div id="question">
      <Controller>
        <Scene classToggle={[".question-title", "animate"]} triggerElement="#question" triggerHook={0.90} indicators={false} reverse={false}>
          <Title className="question-title">Let’s talk</Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene classToggle={[".question-button", "animate"]} triggerElement="#question" triggerHook={0.90} indicators={false} reverse={false}>
          <WrapButton className="question-button">
            <Button color="red" onClick={() => authStore.toggleModal()}>Get in touch</Button>
          </WrapButton>
        </Scene>
      </Controller>
    </div>
  )
}

export default inject((stores: any) => {
  return {
    authStore: stores.authStore,
  };
})(observer(Question));