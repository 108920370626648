import styled, { keyframes } from 'styled-components';
import theme from "../../../themes/default";
// @ts-ignore
import { fadeInUp } from "react-animations";

const FadeInUp = keyframes`${fadeInUp}`;

export const TextWrap = styled.div`
  font-size: ${theme.fontSizes.headers[0]}px;
  color: ${theme.palette.white[0]};
  font-weight: 500;
  line-height: 106%;
  margin-bottom: 10px;
  
  &.animate{
    opacity: 1;
    animation: 1s ${FadeInUp};
    animation-delay: 0.0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-weight: 500;
    font-size: 72px;
    line-height: 110%;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-weight: 500;
    font-size: 50px;
    line-height: 110%;
  }
  
  @media (max-width: 768px) {
    font-size: 36px;
  }
`

export const TitleWrap = styled.div`
  font-family: ${theme.fonts.mulish};
  font-size: ${theme.fontSizes.texts[3]}px;
  color: ${theme.palette.white[0]};
  font-weight: 500;
  line-height: 125%;
  
  &.animate{
    opacity: 1;
    animation: 1s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-weight: 500;
    font-size: 17px;
    line-height: 110%;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 110%;
  }
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const ButtonWrap = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    flex: none;
    opacity: 0;
    text-align: center;
    margin-top: ${theme.spaces[2]}px;
  
    button{
    }
    
    &.animate{
      opacity: 1;
      animation: 0.5s ${FadeInUp};
      animation-delay: 0.8s;
      animation-fill-mode: both;
    }
  }
`
export const ImageWrap = styled.div`
  position: absolute;
  bottom: 3%;
  left: 48%;
    &.animate{
      opacity: 1;
      animation: 0.5s ${FadeInUp};
      animation-delay: 1.2s;
      animation-fill-mode: both;
    }

`

export const WrapHeader = styled.div`
  height: 100vh;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 170px;
  box-sizing: border-box;

    
  @media (max-width: 768px) {
   height: 100vh;
    padding: 150px 50px 100px;
  }
`