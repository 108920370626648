import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn } from "react-animations";

type PropsStyle = {
}
const FadeInUp = keyframes`${fadeInUp}`;
const FadeIn = keyframes`${fadeIn}`;


export const Title = styled.div<PropsStyle>`
  font-size: ${theme.fontSizes.headers[1]}px;
  font-weight: bold;
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 45px;
    line-height: 59px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 40px;
    line-height: 109%;
    padding-bottom: 20px;
  }
`

export const SpheresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 40px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (max-width: 460px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const SphereCard = styled.div`
  min-height: 141px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.6s ${FadeIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    min-height: 137px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    min-height: unset;
  }
  
  @media (max-width: 768px) {
    min-height: 98px;
  }
`

export const WrapImg = styled.div`
  text-align: center;
`

export const SphereImg = styled.img`
  pointer-events: none;
  height: 82px;
  width: 81px;
  
  @media (min-width: 769px) and (max-width: 1440px) {
    height: 62px;
    width: 61px;
  }
  
  @media (max-width: 768px) {
    height: 52px;
    width: 51px;
  }
`

export const SphereText = styled.div`
  text-align: center;
  font-weight: 400;
  color: ${theme.palette.black[1]};
  width: 70%;
  margin: 10px auto 0;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 14px;
    line-height: 130%;
    width: 86%;
  }
  
  @media (max-width: 768px) {
    width: 114px;
    margin: auto;
    
    font-size: 14px;
    line-height: 133%;
  }
`