import styled, { keyframes } from "styled-components";
import theme from "../../../../../../../themes/default";
// @ts-ignore
import { fadeInDown } from "react-animations";

type PropsStyle = {
  isClutch: boolean
}

const FadeInDown = keyframes`${fadeInDown}`;

export const ContainerFlickity = styled.div`
  margin-top: 25px;
  padding-bottom: 35px;
  :hover {
    .carousel .flickity-page-dots .dot{
      opacity:1;
      transition: 1s;
    }
    
    .flickity-prev-next-button{
      opacity:1;
      transition: 1s;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 0 57px;
  }
  
  @media (max-width: 768px) {
    padding: 0 32px;
    
    .flickity-prev-next-button{
      opacity:1;
      transition: 1s;
    }
  }
`

export const ClutchMobile = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    width: 126px;
    margin: auto;
    margin-top: 30px;
    cursor: pointer;
  }
`

export const SlideContainer = styled.div<PropsStyle>`
  width: calc(calc(100% - 196px) / 3);
  height: 260px;
  background-color: ${({isClutch}) => isClutch ? theme.palette.black[0] : theme.palette.white[0]};
  margin: 30px 10px 1px;
  border: 1px solid ${({isClutch}) => !isClutch ? theme.palette.black[0] : theme.palette.white[0]};
  padding: 35px 25px;
  cursor: pointer;
  opacity: 0;
  border-radius: 5px;
  
  :after, :before {
    top: -35px;
    right: 25%; 
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  :after {
    border-color: transparent;
    border-bottom-color: ${({isClutch}) => isClutch ? theme.palette.black[0] : theme.palette.white[0]};
    border-width: 18px;
    border-bottom-width: 18px;
    margin-left: -5px;
  }
  :before {
    top: -36px;
    border-color: transparent;
    border-bottom-color: ${theme.palette.white[0]};
    
    border-width: 18px;
    border-bottom-width: 18px;
    margin-left: -5px;
    margin-bottom: 1px;
  }
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInDown};
    animation-delay: 0.8s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    width: calc(calc(100% - 144px) / 2);
    height: 210px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    width: calc(calc(100% - 104px) / 2);
    height: 190px;
    padding: 35px 15px;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    margin: 30px 0 0;
    height: 200px;
  }
`

export const SlideWrap = styled.div`
  position: relative;
  height: 100%;
  user-select: none;
  
  @media (max-width: 768px) {
    margin: 0 15px;
    position: relative;
  }
`

const Absolute = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
`

export const AbsoluteMark = styled(Absolute)`
  
`

export const SlideText = styled.div`
  font-family: ${theme.fonts.mulish};
  font-style: normal;
  font-weight: normal;
  font-size: ${theme.fontSizes.texts[1]}px;
  padding-top: 45px;
  line-height: 136%;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
`

export const AbsoluteInfoUser = styled(Absolute)`
  top: 200px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    top: 160px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    top: 150px;
  }
  
  @media (max-width: 768px) {
    top: 155px;
  }
`

export const UserName = styled.div`
  font-family: ${theme.fonts.mulish};
  font-style: normal;
  font-weight: 600;
  font-size: ${theme.fontSizes.headers[4]}px;
  color: ${theme.palette.black[0]};
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.headers[5]}px;
    font-weight: bold;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-weight: bold;
    font-size: 16px;
    line-height: 110%;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
    font-weight: bold;
  }
`

export const PositionUser = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size:  ${theme.fontSizes.texts[1]}px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 5px;
    font-size: ${theme.fontSizes.texts[2]}px;
  }
  
  @media (min-width: 993px) and (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
`

export const ClutchContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 190px;
  height: 100px;
  margin: auto;
`

export const Clutch = styled.div`
  @media (max-width: 768px) {
    img {
      height: 35px;
    }
  }
`

export const Stars = styled.div`
  margin-top: 25px;
  
  @media (max-width: 768px) {
    margin-top: 0px;
    img {
      height: 8px;
    }
  }
`