import styled, {keyframes} from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import {fadeInUp, zoomIn, fadeIn} from "react-animations";

type PropsStyle = {
  isWhite?: boolean
}

const FadeIn = keyframes`${fadeIn}`;
const FadeInUp = keyframes`${fadeInUp}`;
const ZoomIn = keyframes`${zoomIn}`;

export const WrapInfo = styled.div`
  display: flex;
  justify-content: between;
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const TextInfo = styled.div`
  font-style: normal;
  font-weight: normal;
  span{
    display: inline-block;
    margin-bottom: 30px;
  }
  span:last-child{
    margin-bottom: 0;
  }
  &.animate{
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 1440px) {
    span {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
`

export const StatisticInfo = styled.div`
  position: relative;
  min-width: 506px;
  margin-left: 120px;
  margin-top: -${theme.spaces[1] + 75}px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    min-width: 338px;
    margin-left: 20px;
    margin-top: -30px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    min-width: 320px;
    height: 201px;
    margin-left: 20px;
    margin-top: 0px;
  }
  
  @media (max-width: 768px) {
    min-width: unset;
    margin-left: 0;
    margin-top: 20px;
  }
`

export const ContentStyle = styled.div`
  display: flex;
  height: 175px;
  position: relative;
  .table-info{
    flex: 1;
    font-style: normal;
    font-weight: 500;
    font-size: ${theme.fontSizes.headers[5]}px;
    text-align: center;
    padding-top: 25px;
    color: ${theme.palette.black[1]};
    
    .table-info-animate {
      max-width: 80%;
      display: inline-block;
      opacity: 0;
      
      &.animate{
      opacity: 1;
        animation: 0.4s ${FadeIn};
        animation-delay: 1.3s;
        animation-fill-mode: both;
      }
    }
    
    :hover{
      color: ${theme.palette.grayscale[0]};
      cursor: default;
      transition-duration: 0.4s;
    }
  }  
  
  @media (min-width: 993px) and (max-width: 1440px) {
    height: 119px;
    
    .table-info{
      padding-top: 15px;
      font-size: 13px;
      line-height: 120%;
      
      .table-info-animate {
        max-width: 153px;
      }
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    height: 100.5px;
    
    .table-info{
      padding-top: 5px;
      font-size: 13px;
      line-height: 120%;
      
      .table-info-animate {
        max-width: 153px;
      }
    }
  }
  
  @media (max-width: 768px) {
    height: 99px;
    
    .table-info{
      padding-top: 5px;
      font-size: ${theme.fontSizes.texts[2]}px;
    }
  }
`

export const Line = styled.div`
  position: absolute;
  width: 0px;
  left: 0;
  right: 0;
  margin: auto;
  height: 350px;
  border-right: 2px solid #1B2340;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${ZoomIn};
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    height: 238px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    height: 201px;
  }
  
  @media (max-width: 768px) {
    height: 198px;
  }
`

export const VerticalLine = styled.div`
//   position: absolute;
  height: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  border-bottom: 2px solid #1B2340;
    
  &.animate{
    animation: 0.4s ${ZoomIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`

export const BigCount = styled.span`
  font-size: ${theme.fontSizes.headers[2]}px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 38px;
    line-height: 52px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 38px;
    line-height: 52px;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.headers[3]}px;
  }
`