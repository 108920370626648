import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, zoomIn, fadeIn } from "react-animations";

type PropsStyle = {
  colorLeft?: string
  colorRight?: string
}

const FadeIn = keyframes`${fadeIn}`;
const FadeInUp = keyframes`${fadeInUp}`;
const ZoomIn = keyframes`${zoomIn}`;

export const Container = styled.div`
  @media (min-width: 993px) and (max-width: 1440px) {
    display: flex;
    justify-content: space-between;
    
    #title-info{
      width: 422px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    display: flex;
    justify-content: space-between;
    
    #title-info{
      width: 320px;
    }
  }
`

export const Title = styled.div`
  font-size: ${theme.fontSizes.headers[1]}px;
  font-weight: bold;
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 45px;
    line-height: 59px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 40px;
    padding: 40px 0 20px;
  }
`

export const TextInfo = styled.div`
  font-family: ${theme.fonts.mulish};
  font-style: normal;
  font-weight: normal;
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`

export const StatisticInfo = styled.div`
  width: 731px;
  margin: 0 auto;
  position: relative;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    width: 338px;
    height: 238px;
    margin: 0;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    width: 320px;
    height: 201px;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`

const ContentStyle = styled.div<PropsStyle>`
  display: flex;
  height: 170px;
  .table-info{
    flex: 1;
    font-style: normal;
    font-weight: 500;
    font-size: ${theme.fontSizes.headers[5]}px;
    // line-height: 24px;
    text-align: center;
    padding: 20px 0 0 20px;
    color: ${({colorRight}) => colorRight ? colorRight : theme.palette.black[1]};
    .table-info-animate {
      max-width: 80%;
      display: inline-block;
      
      &.animate{
        animation: 0.4s ${FadeIn};
        animation-delay: 1.3s;
        animation-fill-mode: both;
      }
    }
  }
  > div:first-child{
    // border-right: 3px solid ${theme.palette.black[0]}80;
    padding: 20px 20px 0 0;
    color: ${({colorLeft}) => colorLeft ? colorLeft : theme.palette.black[1]};
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    height: 119px;
    
    > div:first-child{
      padding: 15px 0 0;
    }
     
    .table-info{
      padding: 15px 0 0;
      font-size: 13px;
      line-height: 120%;
      
      .table-info-animate {
        max-width: 153px;
      }
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    height: 100.5px;
    
    > div:first-child{
      padding: 10px 0 0;
    }
    
    .table-info{
      padding: 10px 0 0;
      font-size: 13px;
      line-height: 120%;
      
      .table-info-animate {
        max-width: 153px;
      }
    }
  }
  
  @media (max-width: 768px) {
    height: 99px;
    
    .table-info{
      padding-top: 5px;
      font-size: ${theme.fontSizes.texts[2]}px;
      line-height: 120%;
    }
  }
`

export const TopContent = styled(ContentStyle)`
  // border-bottom: 3px solid ${theme.palette.black[0]}80;
 
`

export const BottomContent = styled(ContentStyle)`
  
`

export const BigCount = styled.span<PropsStyle>`
  font-size: ${theme.fontSizes.headers[2]}px;
  line-height: 106%;
  font-weight: 500;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 38px;
    line-height: 52px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 38px;
    line-height: 52px;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.headers[3]}px;
    line-height: 39px;
  }
`

export const Line = styled.div`
  position: absolute;
  width: 0px;
  left: 0;
  right: 0;
  margin: auto;
  height: 340px;
  border-right: 2px solid #1B2340;
  
  &.animate{
    animation: 0.4s ${ZoomIn};
    animation-delay: 0.9s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    height: 238px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    height: 201px;
  }
  
  @media (max-width: 768px) {
    height: 198px;
  }
`

export const VerticalLine = styled.div`
  position: absolute;
  height: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  border-bottom: 2px solid #1B2340;
    
  &.animate{
    animation: 0.4s ${ZoomIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`