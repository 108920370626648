import React, {useEffect, useRef, useState} from "react";
import { inject, observer } from "mobx-react";
import { Controller, Scene } from "react-scrollmagic";
import Flickity from "react-flickity-component";
import {
  AbsoluteInfoUser,
  AbsoluteMark,
  Clutch,
  ClutchContainer,
  ClutchMobile,
  ContainerFlickity,
  PositionUser,
  SlideContainer,
  SlideText,
  SlideWrap,
  Stars,
  UserName
} from "./flickity-review-styles";
import { QuotationMark } from "../../clients-happy-styles";
import clutch from "../../../../../../../img/clutch.svg";
import clutchStars from "../../../../../../../img/clutch_stars.svg";

const FlickityReview:React.FC = (props: any) => {
  const {authStore: { setSelectedReview }} = props;
  const [trim, setTrim] = useState(180);
  const [data, setData] = useState(reviews);
  let flick: any;

  let flickityOptions = {
    initialIndex: 0,
    prevNextButtons: true,
    //autoPlay: 3000,
    // pauseAutoPlayOnHover: true,
    groupCells: 3,
    // lazyLoad: true,
    draggable: false,
    // pageDots: true
  }

  const trimmerText = (text: string, size: number) => {
    return text.length > size ? text.slice(0,size) + "..." : text
  }

  const listenResize = () => {
    let width = window.innerWidth;

    if (width > 1440) {
      setTrim(180);
      setData(reviews);
      flick.option({...flickityOptions, groupCells: 3})
    } else if (width > 992) {
      setTrim(180);
      setData(reviews);
      flick.option({...flickityOptions, groupCells: 2})
    }else if (width > 768) {
      setTrim(140);
      setData(reviews);
      flick.option({...flickityOptions, groupCells: 2})
    } else if (width <= 768) {
      setTrim(120);
      setData(reviews2);
      flick.option({...flickityOptions, groupCells: 1})

    }
  }

  useEffect(() => {
    setSelectedReview(reviews[7]);
    window.addEventListener("resize", listenResize);
    listenResize();
    return () => window.removeEventListener("resize", listenResize)
  }, []);
  return (
    <ContainerFlickity id="flickity">
      <Flickity
        className={'carousel'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        // static // default false
        flickityRef={(ref) => flick = ref}
      >
        {data.map((review: any, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[".client-happy-slide", "animate"]} triggerElement="#flickity"triggerHook={0.80} indicators={false} reverse={false}>
              <SlideContainer key={index} isClutch={review.is_clutch} className="client-happy-slide">
                {review.is_clutch ?
                  <SlideWrap onClick={() => window.open("https://clutch.co/profile/nord-soft#reviews", "_blank")}>
                    <ClutchContainer>
                      <div><img src={clutch} alt=""/></div>
                      <Stars><img src={clutchStars} alt=""/></Stars>
                    </ClutchContainer>
                  </SlideWrap>
                  :
                  <SlideWrap onClick={() => setSelectedReview(review)}>
                    <AbsoluteMark>
                      <QuotationMark color="">"</QuotationMark>
                      <SlideText>{trimmerText(review.text, trim)}</SlideText>
                    </AbsoluteMark>
                    <AbsoluteInfoUser>
                      <UserName>{review.username}</UserName>
                      <PositionUser>{review.position}</PositionUser>
                    </AbsoluteInfoUser>
                  </SlideWrap>
                }
              </SlideContainer>
            </Scene>
          </Controller>
        )}
      </Flickity>
      <ClutchMobile onClick={() => window.open("https://clutch.co/profile/nord-soft#reviews", "_blank")}>
        <Clutch><img src={clutch} alt=""/></Clutch>
        <Stars><img src={clutchStars} alt=""/></Stars>
      </ClutchMobile>
    </ContainerFlickity>
  )
}

export default inject((stores: any) => {
  return {
    authStore: stores.authStore,
  };
})(observer(FlickityReview));

const reviews = [
  {
    id: 6,
    text: "",
    username: "",
    position: "",
    is_clutch: true
  },
  {
    id: 1,
    text: "Nord-Soft developers offered alternative solutions for some problems we encountered.",
    username: "Maxence Cornet",
    position: "CTO, Vybe",
    is_clutch: false
  },
  {
    id: 2,
    text: "Nord-Soft is a company you can rely on - fast, professional and great quality work. We definitely will continue to work with them!",
    username: "David Roach",
    position: "Director & Co-founder Catalpa International",
    is_clutch: false
  },
  {
    id: 3,
    text: "Our project was more challenging than initially expected. Hats off to the Nord- Soft team for sticking to their commitment and completing the agreed upon scope. Thank you!",
    username: "Evelyn Horng",
    position: "Co-Founder - ‎Roonga, Inc",
    is_clutch: false
  },
  {
    id: 4,
    text: "We’ve stuck with Nord-Soft because they take all of our concerns out of the picture.",
    username: "Micah Mangione",
    position: "President, Connect-IO",
    is_clutch: false
  },
  {
    id: 5,
    text: "Their efficiency and flexibility have been impressive.",
    username: "Hippolyte Noton",
    position: "Co-founder, The Brand Collector",
    is_clutch: false
  },
  {
    id: 7,
    text: "We have very strict guidelines to follow and tried many freelancers and agencies. They are by far the team that understands the quickest and adapt easily to your work environments. The communication and understanding of projects is excellent. We will be using Nord-Soft for all our future projects without a doubt.",
    username: "Alessandro de Civita",
    position: "CEO beweb.io",
    is_clutch: false
  },
  {
    id: 8,
    text: "Nord-Soft has a deep competency in Django. They have the ability to execute on extremely complex idea with precision that rivals any development team we have worked with. We found their code to be excellent quality and had no problem integrating it with our in-house development team. We cannot recommend this team highly enough.",
    username: "Don Ho",
    position: "Co-founder allbud.com",
    is_clutch: false
  },
];

const reviews2 = reviews.filter((item: any) => !item.is_clutch)