import styled, { keyframes } from 'styled-components';
import theme from "../../../themes/default";
// @ts-ignore
import { fadeInUp } from "react-animations";

type PropsStyle = {
  isWhite?: boolean
}

const FadeInUp = keyframes`${fadeInUp}`;


export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 240px;

  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
    @media (max-width: 768px) {
    height: auto;
    padding: 150px 50px 100px;
  }
`

export const Title = styled.div<PropsStyle>`
  font-size: ${theme.fontSizes.headers[6]}px;
  font-weight: 700;
  padding-bottom: ${theme.spaces[2]}px;
  color: ${theme.palette.white[0]};
  line-height: 84.8px;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-weight: bold;
    font-size: 45px;
    line-height: 59px;
    padding-bottom: 20px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    font-size: 40px;
    padding-bottom: 20px;
    font-weight: 500;
  }
`

export const TextInfo = styled.div<PropsStyle>`
  font-weight: normal;
  font-family: ${theme.fonts.mulish};
  color: ${({isWhite}) => isWhite ? theme.palette.white[0] : theme.palette.black[1]};
  opacity: 0;
  font-size: ${theme.fontSizes.texts[3]};
  line-height: 25.2px;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 992px) {
    br{
      display: none;
    }
  }
`
