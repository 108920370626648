import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import {
  Container,
  ExpertiseCard,
  TextInfo,
  Title,
  Wrapper,
} from "./our-expertise";

const OurExpertise: React.FC = () => {
  return (
    <>
      <Controller>
        <Scene
          classToggle={["#our-expertise", "animate"]}
          triggerElement="#our-expertise"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <Title id="our-expertise">Our expertise</Title>
        </Scene>
      </Controller>
      <Wrapper>
        <Controller>
          <Scene
            classToggle={["#our-text-animate", "animate"]}
            triggerElement="#our-text-animate"
            triggerHook={0.8}
            indicators={false}
            reverse={false}
          >
            <TextInfo id="our-text-animate">
              EyeNord Soft is a full-stack web development company with a vast
              experience acquired in a wide number of performed projects. Our
              team is ready to offer you expert web application development and
              web design services. We have excellent staff able to accomplish
              your requirements.
            </TextInfo>
          </Scene>
        </Controller>
        <Controller>
          <Scene
            classToggle={["#expertise-container", "animate"]}
            triggerElement="#expertise-container"
            triggerHook={0.8}
            indicators={false}
            reverse={false}
          >
        <Container id="expertise-container">
          {dataExpertise.map((expertise: string, index: number) => (
            <ExpertiseCard key={index} id={`card-expertise-${index}`}>
              {expertise}
            </ExpertiseCard>
          ))}
        </Container>
          </Scene>
        </Controller>
      </Wrapper>
    </>
  );
};

export default OurExpertise;

const dataExpertise = [
  "Marketplaces",
  "Entertainment",
  "B2B products",
  "Fintech",
  "SAAS",
  "eCommerce",
  "Startups",
  "Healthcare",
  "Automotive",
  "Logistics",
  "Facebook integrations",
  "Instagram integrations",
  "Tik Tok integrations",
];
