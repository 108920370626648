import React from "react";
import { inject, observer } from "mobx-react";
import { Controller, Scene } from "react-scrollmagic";
import { StartText, TextInfo } from "./start-project-styles";
import {Container, Title} from "../../landing-content.styles";
import { ButtonWrap } from "../OurService/our-service-styles";
import Button from "../../../../../components/button/Button";

type PropsType = {
  authStore?: any
};

const StartProject:React.FC<PropsType> = ({authStore}) => {
  return (
    <Container id="start-project">
      <Controller>
        <Scene classToggle={[".start-project-title", "animate"]} triggerElement="#start-project"triggerHook={0.90} indicators={false} reverse={false}>
          <Title white="true" className="start-project-title" delay={0.8}>Start your project with us</Title>
        </Scene>
      </Controller>
      <TextInfo>
        <Controller>
          <Scene classToggle={[".start-project-text", "animate"]} triggerElement="#start-project"triggerHook={0.90} indicators={false} reverse={false}>
            <StartText className="start-project-text">{text}</StartText>
          </Scene>
        </Controller>
        <Controller>
          <Scene classToggle={[".start-project-button", "animate"]} triggerElement="#start-project"triggerHook={0.90} indicators={false} reverse={false}>
            <ButtonWrap className="start-project-button" delay={1.3}>
              <Button isArrow={true} color="blue" onClick={() => authStore.toggleModal()}>Get in touch</Button>
            </ButtonWrap>
          </Scene>
        </Controller>
      </TextInfo>
    </Container>
  )
}

export default inject((stores: any) => {
  return {
    authStore: stores.authStore,
  };
})(observer(StartProject));

const text = "We specialize in web and mobile app development and deliver high-end solutions to the companies all around the world. Our team's expertise and keen insight into your business needs lay a solid foundation for project success.";