import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import theme from "../../../themes/default";
import WrapColorPicture from "../../../components/wrapColorPicture/WrapColorPicture";
import TitleInfo from "./TitleInfo/TitleInfo";
import SlideCard from "./SlideCard/SlideCard";

type PropsType = {
  data: any
}

const ContentProjectCard:React.FC<PropsType> = (props) => {
  const { data } = props;
  return (
    <>
      <WrapColorPicture padding="TitleInfo" color={data.color}>
        <TitleInfo data={data}/>
      </WrapColorPicture>
      {data.slides.map((slide: any, index: number) =>
        <Controller key={index}>
          <Scene classToggle={[`.slide-wrap-${index}`, "animate"]} triggerElement={`#slide-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
            <WrapColorPicture
              padding="SlideCard"
              color={index % 2 ? data.color : theme.palette.white[0]}
              key={index}
              animateClass={`slide-wrap-${index}`}
            >
              <SlideCard
                slide={slide}
                index={index}
                isLast={data.slides.length === index + 1}
                id={`slide-${index}`}
                {...props}
              />
            </WrapColorPicture>
          </Scene>
        </Controller>
      )}
    </>
  )
}

export default ContentProjectCard;