import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, fadeInDown } from "react-animations";

type StackStyle = {
  count: number
}

const FadeIn = keyframes`${fadeIn}`;
const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;

export const TechnicalWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  color: ${theme.palette.white[0]};
  
  @media (max-width: 768px) {
    grid-template-columns: unset;
  }
`

export const TechnicalCard = styled.div`
  border-radius: 7px;
  background-color: #232D50;
  padding: 30px 35px 15px;
  transition-duration: 0.8s;
  cursor: pointer;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.6s ${FadeIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  :first-child{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  
  :hover{
    background-color: ${theme.palette.grayscale[0]};
    color: ${theme.palette.black[0]};
    
    .stack-wrap{
      div{
        border-color: ${theme.palette.black[0]};
      }
    }
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: 25px 30px 19px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 20px 25px 14px;
  }
  
  @media (max-width: 768px) {
    padding: 20px 15px 14px;
    
    :first-child{
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }
`

export const TechnicalTitle = styled.div`
  font-family: ${theme.fonts.mulish};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 15px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInDown};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 22px;
  }
`

export const StacksWrap = styled.div`
  
`

export const TechnicalStack = styled.div<StackStyle>`
  display: inline-block;
  padding: 3px 10px;
  border: 1px solid ${theme.palette.white[0]};
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: ${theme.fontSizes.texts[1]}px;
  font-weight: 400;
  
  // &.animate{
  //   opacity: 1;
  //   animation: 0.8s ;
  //   animation-delay: 0.5s;
  //   animation-fill-mode: both;
  // }
  opacity: 0; 
  
  &.animate{
    opacity: 1;
    animation: 0.6s ${FadeInUp};
    animation-delay: ${({count}) => count * 0.1 + 0.4}s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 13px;
    line-height: 140%;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 13px;
    line-height: 140%;
    padding: 3px 7px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
  
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 140%;
    padding: 3px 7px;
    margin-right: 6px;
    margin-bottom: 6px;
  }
`