import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { TextInfo, Title } from "../../content-services-styles";
import {
  ImgWrap,
  PartCard,
  PartContainer,
  PartText,
  PartTitle,
} from "./support-styles";
import Testing from "../../../../../img/services/testing.svg";
import Maintenance from "../../../../../img/services/maintenance.svg";
import Audit from "../../../../../img/services/audit.svg";

const Support: React.FC = () => {
  return (
    <>
      <Controller>
        <Scene
          classToggle={["#support-title", "animate"]}
          triggerElement="#support-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <Title isWhite={false} id="support-title">
            QA & Support
          </Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene
          classToggle={[".support-text-info", "animate"]}
          triggerElement="#support-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <TextInfo isWhite={false} className="support-text-info">
            We always control and evaluate our work to make sure that our
            processes run smoothly, our <br /> products are well-developed, and
            the results meet your expectations.
          </TextInfo>
        </Scene>
      </Controller>
      <PartContainer>
        {data.parts.map((part: any, index: number) => (
          <Controller key={index}>
            <Scene
              classToggle={[`#card-part-${index}`, "animate"]}
              triggerElement={`#card-part-${index}`}
              triggerHook={0.8}
              indicators={false}
              reverse={false}
            >
              <PartCard index={index} id={`card-part-${index}`}>
                <Controller>
                  <Scene
                    classToggle={[`.card-part-title-${index}`, "animate"]}
                    triggerElement={`#card-part-${index}`}
                    triggerHook={0.8}
                    indicators={false}
                    reverse={false}
                  >
                    <PartTitle
                      index={index}
                      className={`card-part-title-${index}`}
                    >
                      <ImgWrap src={part.img} />
                      {part.title}
                    </PartTitle>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene
                    classToggle={[`.card-part-text-${index}`, "animate"]}
                    triggerElement={`#card-part-${index}`}
                    triggerHook={0.8}
                    indicators={false}
                    reverse={false}
                  >
                    <PartText className={`card-part-text-${index}`}>
                      {part.text}
                    </PartText>
                  </Scene>
                </Controller>
              </PartCard>
            </Scene>
          </Controller>
        ))}
      </PartContainer>
    </>
  );
};

export default Support;

const data = {
  parts: [
    {
      title: "Testing",
      img: Testing,
      text: "Software testing is an integral part of the software development lifecycle. During the development cycles, we perform various types of testing such as UI testing, functional testing, load and stress testing to detect errors and verify that the functionality satisfies its specified requirements.",
    },
    {
      title: "Maintenance",
      img: Maintenance,
      text: "The delivery of a software product is followed by changes. We understand that. So, we provide a variety of maintenance activities such as bug fixing, capabilities enhancement, optimization, etc.",
    },
    {
      title: "Code audit",
      img: Audit,
      text: "We offer code audit and refactoring services for the projects facing technical issues. We carry out a detailed analysis of the source code, look for bugs and vulnerabilities, and improve the existing codebase by restructuring it. We make your code better and your project healthier.",
    },
  ],
};
