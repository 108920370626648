import styled from 'styled-components';
import theme from "../../themes/default";

type PropsStyle = {
  color: string,
  isArrow?: boolean
}

type ReturnStyleButton = (color: string) => any

let styleButton: ReturnStyleButton = function (color) {
  switch (color) {
    case "red":{
      return {
        color: theme.palette.white[0],
        colorDisabled: theme.palette.white[0],
        backgroundColor: theme.palette.red[1],
        backgroundColorDisabled: theme.palette.grayscale[1],
        backgroundColorHover: theme.palette.red[0]
      }
    }
    case "blue":{
      return {
        color: theme.palette.white[0],
        colorDisabled: theme.palette.white[0],
        backgroundColor: theme.palette.blue[1],
        backgroundColorDisabled: theme.palette.grayscale[1],
        backgroundColorHover: theme.palette.blue[0]
      }
    }
    case "blue2":{
      return {
        color: theme.palette.white[0],
        colorDisabled: theme.palette.white[0],
        backgroundColor: theme.palette.blue[2],
        backgroundColorDisabled: theme.palette.grayscale[1],
        backgroundColorHover: theme.palette.blue[0]
      }
    }
    case "blue3":{
      return {
        color: theme.palette.white[0],
        colorDisabled: theme.palette.white[0],
        backgroundColor: theme.palette.blue[3],
        backgroundColorDisabled: theme.palette.grayscale[1],
        backgroundColorHover: theme.palette.blue[0]
      }
    }
    case "black":{
      return {
        color: theme.palette.black[0],
        colorDisabled: theme.palette.grayscale[1],
        backgroundColor: theme.palette.white[0],
        backgroundColorDisabled: theme.palette.white[0],
        backgroundColorHover: theme.palette.darkBlue[0]
      }
    }
    case "darkBlue":{
      return {
        color: theme.palette.white[0],
        colorDisabled: theme.palette.grayscale[1],
        backgroundColor: theme.palette.darkBlue[1],
        backgroundColorDisabled: theme.palette.grayscale[1],
        backgroundColorHover: theme.palette.darkBlue[0]
      }
    }
    default :{
      return {
        color: color,
        colorDisabled: theme.palette.grayscale[1],
        backgroundColor: theme.palette.white[0],
        backgroundColorDisabled: theme.palette.white[0]
      }
    }

  }
}

export const ButtonStyle = styled.button<PropsStyle>`
  padding: 5px 25px;
  background-color: ${({color}) => styleButton(color).backgroundColor};
  color: ${({color}) => styleButton(color).color};
  border-radius: 47px;
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes.texts[0]}px;
  line-height: 29px;
  border: 2px solid rgb(0,0,0,0);
  transition-duration: 0.5s;
  cursor: pointer;
  
  div{
    display: flex;
    align-items: center;
  }
  
  svg{
    margin-left: 10px;
    
    path{
      stroke: ${({color}) => styleButton(color).color};
    }
  }
  
  :disabled{
    background-color: ${({color}) => styleButton(color).backgroundColorDisabled};
    color: ${({color}) => styleButton(color).colorDisabled};
    cursor: auto;
    
    svg{
      path{
        stroke: ${({color}) => styleButton(color).colorDisabled};
      }
    }
  }
  
  :hover{
      background-color: ${({color}) => styleButton(color).backgroundColorHover};
  }
  
  :focus{
    border: 2px solid ${theme.palette.black[0]};
    outline: unset;
  }
  
  :active{
    color: ${theme.palette.black[0]};
    svg{
      path{
        stroke: ${theme.palette.black[0]};
      }
    }
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 15px;
    line-height: 21px;
    padding: 7px 30px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 13px;
    line-height: 17px;
    padding: 5px 20px;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
    line-height: 18px;
    padding: 12px 35px;
  }
`