import styled, { keyframes } from 'styled-components';
import theme from "../../../../../../../themes/default";
// @ts-ignore
import { fadeInDown, fadeIn, rotateInDownLeft } from "react-animations";

type SelectedReviewStyle = {
  selected: boolean
}

const FadeIn = keyframes`${fadeIn}`;
const FadeInDown = keyframes`${fadeInDown}`;
const RotateInDownLeft = keyframes`${rotateInDownLeft}`;

export const FullReviewContainer = styled.div<SelectedReviewStyle>`
  display: ${({selected}) => selected ? "block" : "none"};
  position: relative;
  min-height: 100px;
`

export const InfoGrid = styled.div`
  grid-column-start: 10;
  grid-column-end: 13;
  
  @media (max-width: 768px) {
    text-align: right;
    margin-top: 20px;
  }
`

export const QuotationMarkContainer = styled.div`
  position: absolute;
  top: -55px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${RotateInDownLeft};
    animation-delay: 0.8s;
    animation-fill-mode: both;
  }
`

export const ReviewGrid = styled.div`
  margin-top: 55px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  color: ${theme.palette.white[0]};
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const TextGrid = styled.div`
  grid-column-start: 1;
  grid-column-end: 9;
  font-family: ${theme.fonts.mulish};
  line-height: 148%;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInDown};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.texts[1]}px;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[1]}px;
  }
`

export const UserNameFull = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${theme.fontSizes.headers[3]}px;
  color: ${theme.palette.blue[1]};
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeIn};
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 28px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 20px;
    line-height: 110%;
  }
  
  @media (max-width: 768px) {
    font-size: 26px;
  }
`

export const UserPosition = styled.div`
  font-family: ${theme.fonts.mulish};
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${RotateInDownLeft};
    animation-delay: 0.8s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 18px;
  }
`