import styled from 'styled-components'
import theme from "../../themes/default";

type PropsStyle = {
  component?: string
  isGlobal?: boolean
}

export const SpinnerContainer = styled.div<PropsStyle>`
  width: 100%;
  height: 100%;
  position: ${({isGlobal}) => isGlobal ? "absolute" : "initial"};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({isGlobal}) => isGlobal ? "white" : "unset"};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  ${({isGlobal}) => !isGlobal ? "padding: 0 41px;" : ""}
  
  @media (min-width: 993px) and (max-width: 1440px) {
      ${({isGlobal}) => !isGlobal ? "padding: 0 38.6px;" : ""}
    }
    
    @media (min-width: 769px) and (max-width: 992px) {
      ${({isGlobal}) => !isGlobal ? "padding: 0 34px;" : ""}
    }
    
    @media (max-width: 768px) {
      ${({isGlobal}) => !isGlobal ? "padding: 0 37px;" : ""}
    }
`

export const SpinnerContent = styled.div<PropsStyle>`
  img {
    height: ${({component}) => component === "button" ? "29px" : "150px"};
    
    @media (min-width: 993px) and (max-width: 1440px) {
      height: ${({component}) => component === "button" ? "21px" : "150px"};
    }
    
    @media (min-width: 769px) and (max-width: 992px) {
      height: ${({component}) => component === "button" ? "17px" : "150px"};
    }
    
    @media (max-width: 768px) {
      height: ${({component}) => component === "button" ? "18px" : "150px"};
    }
  }
`