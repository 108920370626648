import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import WrapColorPicture from "../wrapColorPicture/WrapColorPicture";
import {
  CloseButton,
  ContentWrap,
  ErrorMessage,
  FormLetter,
  FormTitle,
  FormWrap,
  InfoBlock,
  InfoText,
  InfoTitle,
  InputForm,
  MainContainer,
  TextAreaForm,
  WrapBoltInfo,
  WrapButton } from "./get-in-touch-styles";
import Button from "../button/Button";
import Spinner from "../spinner/Spinner";
import Facebook from "../../img/icon-facebook.svg";
import LinkedIn from "../../img/icon-linkedin.svg";

type PropsType = {
  authStore?: any
};

const GetInTouch:React.FC<PropsType> = (props) => {
  const {authStore: {toggleModal, sendMessage, messageErrors, toggleMessageError, isLoading}} = props;
  const [state, setState] = useState({
    description: "",
    email: "",
    name: ""
  })
  return (
    <MainContainer>
      <WrapColorPicture padding="GetInTouch">
        <ContentWrap>
          <FormLetter>
            <FormTitle>Get in touch</FormTitle>
            <FormWrap>
              <InputForm name="name" value={state.name} onChange={(e: any) => setState({...state, name: e.target.value})} type="text" placeholder="Your Name"/>
              <ErrorMessage isShowError={!!messageErrors.name.length}>{messageErrors.name}</ErrorMessage>
              <InputForm name="email" value={state.email} onChange={(e: any) => setState({...state, email: e.target.value})} type="text" placeholder="Email"/>
              <ErrorMessage isShowError={!!messageErrors.email.length}>{messageErrors.email}</ErrorMessage>
              <TextAreaForm name="" value={state.description} onChange={(e: any) => setState({...state, description: e.target.value})} placeholder="Message"/>
              <ErrorMessage isShowError={!!messageErrors.description.length}>{messageErrors.description}</ErrorMessage>
              <WrapButton id="matters-button-learn-animate">
                <Button
                  color="blue2"
                  isArrow={false}
                  onClick={() => {
                    sendMessage(state)
                  }}
                >{isLoading ? <Spinner isGlobal={false} component="button"/> : "Send Message"}</Button>
              </WrapButton>
            </FormWrap>
          </FormLetter>
          <InfoBlock>
            <InfoTitle>
              <span>What happens next</span>
            </InfoTitle>
            {texts.map((text: string, index: number) =>
              <InfoText key={index}>{text}</InfoText>
            )}
            <WrapBoltInfo>+370 (650) 34 448</WrapBoltInfo>
            <WrapBoltInfo>contact@nord-soft.com</WrapBoltInfo>
            <WrapBoltInfo>
              <Link to={{ pathname:"https://facebook.com/NordSoftDev"}} target="_blank"><img src={Facebook} alt=""/></Link>
              <Link to={{ pathname:"https://www.linkedin.com/company/nord-soft"}} target="_blank"><img src={LinkedIn} alt=""/></Link>
            </WrapBoltInfo>
            <CloseButton onClick={() => {
              toggleModal();
              toggleMessageError();
            }}><span></span></CloseButton>
          </InfoBlock>
        </ContentWrap>
      </WrapColorPicture>
    </MainContainer>
  )
}

export default inject((stores: any) => {
  return {
    authStore: stores.authStore,
  };
})(observer(GetInTouch));

const texts = [
  "As soon as we receive your request we'll reach out to you for further discussion. We usually respond within 6 hours.",
  "Next, we'll schedule a meeting to discuss your project in more detail.",
  "We use Skype or Google Hangouts for video calls. If you prefer something else, just let us know  in your message.",
  "Or contact us"
]
