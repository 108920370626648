import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Container } from "./clients-happy-styles";
import { Title } from "../../landing-content.styles";
import FullReview from "./components/FullReview/FullReview";
import FlickityReview from "./components/FlickityReview/FlickityReview";
import './flickity.css';

const ClientsHappy:React.FC = () => {
  return (
    <Container id="clients-happy">
      <Controller>
        <Scene classToggle={[".client-happy-title", "animate"]} triggerElement="#clients-happy"triggerHook={0.80} indicators={false} reverse={false}>
          <Title white="true" className="client-happy-title" delay={0.4}>Clients are happy</Title>
        </Scene>
      </Controller>
      <FullReview/>
      <FlickityReview/>
    </Container>
  )
}

export default ClientsHappy;