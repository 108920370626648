import styled, { keyframes } from 'styled-components';
import theme from "../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeInDown, fadeInLeft, fadeInRight, rotateInUpLeft, rotateInUpRight } from "react-animations";

type PropsStyle = {
  isWhite: boolean
}

type ListStyle = {
  isLeft: boolean
  count: number
  color?: string
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;
const FadeInLeft = keyframes`${fadeInLeft}`;
const FadeInRight = keyframes`${fadeInRight}`;
const RotateInUpLeft = keyframes`${rotateInUpLeft}`;
const RotateInUpRight = keyframes`${rotateInUpRight}`;

export const Wrap = styled.div<PropsStyle>`
  color: ${({isWhite}) => isWhite ? theme.palette.black[0] : theme.palette.white[0]};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  min-height: 350px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    min-height: 225px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    min-height: 175px;
  }
  
  @media (max-width: 768px) {
    display: block;
    min-height: 350px;
  }
`

export const MobileImg = styled.div<PropsStyle>`
  grid-column-start: ${({isWhite}) => isWhite ? 1 : 9};
  grid-column-end: ${({isWhite}) => isWhite ? 5 : 13};
  text-align: ${({isWhite}) => isWhite ? "start" : "end"};
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${({isWhite}) => isWhite ? FadeInLeft : FadeInRight};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  img{
    width: 100%;
  }
  
  @media (max-width: 768px){
    &.animate{
      animation: 0.5s ${FadeInUp};
    }
    
    img{
      width: calc(100% + 30px);
      margin: 0 -15px;
    }
  }
`

export const MobileText = styled.div<PropsStyle>`
  grid-column-start: ${({isWhite}) => isWhite ? 5 : 2};
  grid-column-end: ${({isWhite}) => isWhite ? 13 : 9};
  align-self: center;
`

export const CountSlide = styled.div`
  font-weight: bold;
  font-size: ${theme.fontSizes.headers[3]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`

export const TextSlide = styled.div`
  font-family: ${theme.fonts.mulish};
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.01em;
  margin-top: 16px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInDown};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`

export const ListSlide = styled.ul`
  padding-inline-start: 20px;
`

export const ItemList = styled.li<ListStyle>`
  opacity: 0; 
  
  &.animate{
    opacity: 1;
    animation: 0.6s ${({ isLeft }) => isLeft ? RotateInUpLeft : RotateInUpRight};
    animation-delay: ${({count}) => count * 0.1 + 0.4}s;
    animation-fill-mode: both;
  }
  
  ::marker{
    color: ${({color}) => color};
  }
`

export const DesktopImg = styled.div<PropsStyle>`
  grid-column-start: ${({isWhite}) => isWhite ? 8 : 1};
  grid-column-end: ${({isWhite}) => isWhite ? 13 : 6};
  text-align: ${({isWhite}) => isWhite ? "end" : "start"};
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${({isWhite}) => isWhite ? FadeInRight : FadeInLeft};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    img{
      width: 100%;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    img{
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    img{
      width: 100%;
    }
    
    &.animate{
      animation: 0.5s ${FadeInUp};
    }
  }
`

export const DesktopText = styled.div<PropsStyle>`
  grid-column-start: ${({isWhite}) => isWhite ? 2 : 6};
  grid-column-end: ${({isWhite}) => isWhite ? 8 : 13};
  align-self: center;
`

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 13; 
  margin-top: ${theme.spaces[0]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top: 40px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 40px;
  }
  
  @media (max-width: 768px) {
    margin-top: 40px;
  }
`