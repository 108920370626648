import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import theme from "../../themes/default";
// @ts-ignore
import { zoomIn, fadeInDownBig } from "react-animations";
import React from "react";
import {Simulate} from "react-dom/test-utils";


type PropsStyle = {
  main?: string
  show?: boolean
  floating?: string
  progress?: number
  isMenu?: boolean
  isModal?: boolean
  active?: number
  isBackground?: number
}

type LogoProps = {
  isLogo: boolean
  isMenu?: boolean

}

const ZoomIn = keyframes`${zoomIn}`;
const FadeInDownBig = keyframes`${fadeInDownBig}`;

export const WrapHeader = styled.div<PropsStyle>`
  z-index: ${({isModal}) => isModal ? 0 : 1};
`

export const FixedContainer = styled.div<PropsStyle>`
  position: fixed;

  left: 0;
  right: 0;
  z-index: ${({isModal}) => isModal ? 0 : 10};
  top: 0;
  
  opacity: 1;
  ${({progress, isModal}) => progress && progress > 0 && !isModal ? "transition-duration: 0.4s;" : ""}
  ${({show}) => (show) ? `transform: translateY(0%);` : `transform: translateY(-100%);`}
  
  ${({floating, show, progress, isBackground}) => !show ?
  "background: 0 0;" :
  (floating === "true"  && progress && isBackground && progress > isBackground ) ?
    `background-color: ${theme.palette.grayscale[0]};` :
    ``
}
  
  @media (max-width: 768px) {
    ${({isMenu, isBackground, progress}) => (isMenu  && progress && isBackground && progress > isBackground)? `background-color: ${theme.palette.black[0]};` : ``}
    ${({isMenu, show}) => isMenu ? `transform: translateY(0%);` : show ? `transform: translateY(0%);` : `transform: translateY(-100%);`}
  }
`

export const WrapContainer = styled.div<PropsStyle>`
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  color: ${({main}) => main ? theme.palette.white[0] : theme.palette.black[0]};
  position: relative;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: 20px 60px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 20px 30px;
  }
  
  @media (max-width: 768px) {
    padding: 8px 15px;
  }
`

export const Logo = styled.div<LogoProps>`
      @media (max-width: 768px) {
        height: 60px;
      }
  svg{
    height: 100%;
    path:nth-child(6),
    path:nth-child(7),
    path:nth-child(8){
      transition-duration: 1.2s;
      fill: ${({isLogo}) => (isLogo ? "black" : "white")};
      
      @media (max-width: 768px) {
        fill: ${({isLogo, isMenu}) => isMenu ? "white" : isLogo ? "black" : "white"};
      }
    }
    
`

export const LinkForLogo = styled(Link)<PropsStyle>`
  z-index: 3;
  
  &.animate{
    opacity: 1;
    animation: 0.7s ${FadeInDownBig};
    animation-delay: 0.0s;
    animation-fill-mode: both;
  }


`

export const NavContent = styled.div<PropsStyle>`
  z-index: 2;
  align-self: center;
  
  &.animate{
    opacity: 1;
    animation: 0.7s ${FadeInDownBig};
    animation-delay: 0.0s;
    animation-fill-mode: both;
    @media (max-width: 768px) {
      animation: unset;
    }
  }
  
  @media (max-width: 768px) {
    transition-duration: 0.6s;
    ${({isMenu}) => isMenu ? `transform: translateY(0%);` : `transform: translateY(-200%);`}
    display: inline-block;
    position: fixed;
    width: calc(100% - 30px);
    top: 55px;
    left: 0;
    background-color: ${theme.palette.black[0]};
    padding: 35px 15px 10px;
   
    button{
      margin: 10px 0;
    }
  }
`

export const Burger = styled.div<LogoProps>`
  display: none;
  align-self: center;

  &.animate{
    opacity: 1;
    animation: 0.7s ${FadeInDownBig};
    animation-delay: 0.0s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    position: fixed;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 3;
    
    span, span:before, span:after{
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${({isLogo, isMenu}) => isMenu ? "white" : isLogo ? "black" : "white"};
      transition-duration: .25s;
    }
    
    span:before {
      content: '';
      top: -8px;
    }
    
    span:after {
      content: '';
      top: 8px;
    }
    
    ${({isMenu}) => isMenu && `
      span {
        transform: rotate(45deg);
      }
      
      span::before {
        top: 0;
        transform: rotate(0);
      }
      
      span::after {
        top: 0;
        transform: rotate(90deg);
      }
    `}
  }
`

export const NavText = styled(Link)<PropsStyle>`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  font-size: ${theme.fontSizes.headers[5]}px;
  line-height: 26px;
  margin-right: 25px;
  transition-duration: 0.8s;
  color: ${({active, main}) => active ? '#9ABDDD' : (main === "true" ? theme.palette.black[0] : theme.palette.white[0])};
  text-decoration: none;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    line-height: 23px;
    font-size: 15px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 14px;
    line-height: 18px;
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    padding: 5px 0;
    line-height: 18px;
  }
  
  @media (max-width: 768px) {
    // color: ${({main, isMenu}) => isMenu ? theme.palette.white[0] : main === "true" ? theme.palette.white[0] : theme.palette.black[0]};
    color: ${({main, isMenu}) => isMenu ? theme.palette.white[0] : theme.palette.black[0]};
    display: block;
    padding: 10px 0;
  }
`

export const BackgroundContainer = styled.div<PropsStyle>`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition-duration: 1.6s;
  
  @media (max-width: 768px) {
    ${({isMenu}) => isMenu && `
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #1E253F;
      z-index: 2;
      transition-duration: 0.6s;
    `}
    ${({floating, show, isMenu, progress, isBackground}) => !show ?
  "background: 0 0;" :
  isMenu ? `
          display: block;
          background-color: #1E253F;
        ` :
    (floating === "true" && progress && isBackground && progress > isBackground) ?
      `background-color: ${theme.palette.grayscale[0]};display: block;` :
      ``
}
  }
`

export const ModalGetInTouch = styled.div<PropsStyle>`
  position: fixed;
  transition-duration: 0.6s;
  z-index: 20;
  ${({isModal}) => isModal ? `transform: translateY(0%);` : `transform: translateY(-100%);`}
  left: 0;
  right: 0;
  height: 100vh;
  background-color: white;
  overflow: auto;
`