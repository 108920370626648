import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Title, TextInfo } from "../../content-technologies-styles";
import {
  TechnicalWrap,
  TechnicalCard,
  TechnicalTitle,
  TechnicalStack,
  StacksWrap,
} from "./technical-expertise-styles";

const TechnicalExpertise: React.FC = () => {
  return (
    <>
      <Controller>
        <Scene
          classToggle={["#technical-title", "animate"]}
          triggerElement="#technical-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <Title isWhite={true} id="technical-title">
            Extensive Technical Expertise
          </Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene
          classToggle={[".technical-text", "animate"]}
          triggerElement="#technical-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <TextInfo isWhite={true} className="technical-text">
            We develop solutions of any complexity, starting with Minimum Viable
            Products (MVPs) for start-ups, solutions for small and medium
            businesses, and so on.
          </TextInfo>
        </Scene>
      </Controller>
      <TechnicalWrap>
        {dataTechnical.map((technical: any, index: number) => (
          <Controller key={index}>
            <Scene
              classToggle={[`#card-technical-${index}`, "animate"]}
              triggerElement={`#card-technical-${index}`}
              triggerHook={0.85}
              indicators={false}
              reverse={false}
            >
              <TechnicalCard key={index} id={`card-technical-${index}`}>
                <Controller>
                  <Scene
                    classToggle={[`.card-technical-title-${index}`, "animate"]}
                    triggerElement={`#card-technical-${index}`}
                    triggerHook={0.85}
                    indicators={false}
                    reverse={false}
                  >
                    <TechnicalTitle className={`card-technical-title-${index}`}>
                      {technical.name}
                    </TechnicalTitle>
                  </Scene>
                </Controller>
                <StacksWrap className="stack-wrap">
                  {technical.children.map((stack: string, i: number) => (
                    <Controller key={i}>
                      <Scene
                        classToggle={[
                          `.card-technical-stack-${i}-${index}`,
                          "animate",
                        ]}
                        triggerElement={`#card-technical-${index}`}
                        triggerHook={0.85}
                        indicators={false}
                        reverse={false}
                      >
                        <TechnicalStack
                          key={i}
                          className={`card-technical-stack-${i}-${index}`}
                          count={i + 1}
                        >
                          {stack}
                        </TechnicalStack>
                      </Scene>
                    </Controller>
                  ))}
                </StacksWrap>
              </TechnicalCard>
            </Scene>
          </Controller>
        ))}
      </TechnicalWrap>
    </>
  );
};

export default TechnicalExpertise;

const dataTechnical = [
  {
    name: "Frontend",
    children: [
      "Angular 2+",
      "ReactJS",
      "HTML5",
      "CSS3",
      "jQuery",
      "Express.js",
      "BEM/SASS/LESS",
      "VueJS",
      "JavaScript (ES5+)",
      "Redux",
      "Next.js",
      "Nuxt.js",
      "MobX / MobX State Tree",
    ],
  },
  {
    name: "Backend",
    children: [
      "Django",
      "Python",
      "RabbitMQ",
      "Celery",
      "PyQt5",
      "Kafka",
      "Flask",
      "FastAPI",
      "REST",
      "Asyncio",
      "Redis",
    ],
  },
  {
    name: "DevOps",
    children: [
      "Docker",
      "Docker Swarm",
      "Kubernetes",
      "Jenkins",
      "Heroku",
      "Google Cloud Platform",
    ],
  },
  {
    name: "Testing",
    children: ["Selenium", "Sahi", "Jest", "Enzyme"],
  },
  {
    name: "Database & Storage",
    children: ["MySQL", "PostgreSQL", "MongoDB", "FireBase"],
  },
  {
    name: "Testing methods",
    children: [
      "Unit testing",
      "Functional testing",
      "Integration testing",
      "End-to-end tests",
    ],
  },
  {
    name: "Mobile",
    children: ["React Native", "Ionic"],
  },
  // analytics: {
  //   name: "Analytics",
  //   children: ["Apache HaDoop", "Google"]
  // },
  // apis: {
  //   name: "APIs",
  //   children: ["Django REST framework", "Twilio", "Facebook  API", "LinkedIn API", "Instagram API", "Stripe"]
  // },
];
