import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { TextInfo, Title } from "../../content-services-styles";
import { ImgWrap, ReasonCard, ReasonsContainer, ReasonText, ReasonTitle } from "./choose-us-styles";
import Idea from "../../../../../img/services/idea.svg";
import Workflow from "../../../../../img/services/workflow.svg";
import Updates from "../../../../../img/services/updates.svg";

const ChooseUs:React.FC = () => {
  return (
    <>
      <Controller>
        <Scene classToggle={["#choose-title", "animate"]} triggerElement="#choose-title" triggerHook={0.80} indicators={false} reverse={false}>
          <Title id="choose-title">Why choose us</Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene classToggle={[".choose-text-info", "animate"]} triggerElement="#choose-title" triggerHook={0.80} indicators={false} reverse={false}>
          <TextInfo className="choose-text-info">{data.text}</TextInfo>
        </Scene>
      </Controller>
      <ReasonsContainer>
        {data.reasons.map((reason: any, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[`#card-reason-${index}`, "animate"]} triggerElement={`#card-reason-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
              <ReasonCard index={index} id={`card-reason-${index}`}>
                <Controller>
                  <Scene classToggle={[`.card-reason-title-${index}`, "animate"]} triggerElement={`#card-reason-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <ReasonTitle className={`card-reason-title-${index}`}>
                      <ImgWrap src={reason.img}/>
                      {reason.title}
                    </ReasonTitle>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene classToggle={[`.card-reason-text-${index}`, "animate"]} triggerElement={`#card-reason-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <ReasonText className={`card-reason-text-${index}`}>{reason.text}</ReasonText>
                  </Scene>
                </Controller>
              </ReasonCard>
            </Scene>
          </Controller>
        )}
      </ReasonsContainer>
    </>
  )
}

export default ChooseUs;

const data = {
  text: "We're a dedicated team aiming for top results",
  reasons: [
    {
      title: "From idea to realisation",
      img: Idea,
      text: "We let our attention to detail and keen interest in your business needs guide our development process. We follow your requirements through and deliver high-quality solutions within budget and on time."
    },
    {
      title: "Transparent workflow",
      img: Workflow,
      text: "We keep you as involved as you'd like to be. Frequent checkpoints ensure visibility of the development process and help to align the results with your expectations."
    },
    {
      title: "Regular updates",
      img: Updates,
      text: "We provide you with detailed reports supported by demonstrations for you to review the progress made in a specific iteration and be sure that your project is on track."
    },
  ]
}