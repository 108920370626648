import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import Button from "../button/Button";
import { ButtonWrap, ContainerStack, SkillsWrap, TextContainer, Title, Container, Picture, PictureMobile, ContainerPictureMobile, GridWrap } from "./portfolio-card-styles";
import WrapColorPicture from "../wrapColorPicture/WrapColorPicture";

type PropsStyle = {
  data: any
  history?: any
  index?: number
}

const PortfolioCard:React.FC<PropsStyle> = ({data, history, index}) => {
  return (
    <Controller>
      <Scene classToggle={[`.portfolio-card-${data.id}`, "animate"]} triggerElement={`#portfolio-${data.id}`} triggerHook={0.90} indicators={false} reverse={false}>
        <WrapColorPicture display='flex' color={data.color} animateClass={`portfolio-card-${data.id}`} height={index===0 ? '100vh' : 'auto'}>
          <GridWrap>
            <Container id={`portfolio-${data.id}`} >
              <Controller>
                <Scene classToggle={[`.portfolio-card-${data.id}-title`, "animate"]} triggerElement={`#portfolio-${data.id}`} triggerHook={0.90} indicators={false} reverse={false}>
                  <Title className={`portfolio-card-${data.id}-title`}>{data.title}</Title>
                </Scene>
              </Controller>
              <Controller>
                <Scene classToggle={[`.portfolio-card-${data.id}-text`, "animate"]} triggerElement={`#portfolio-${data.id}`} triggerHook={0.90} indicators={false} reverse={false}>
                  <TextContainer className={`portfolio-card-${data.id}-text`}>{data.text}</TextContainer>
                </Scene>
              </Controller>
              <ContainerPictureMobile>
                <Controller>
                  <Scene classToggle={[`.portfolio-card-${data.id}-img`, "animate"]} triggerElement={`#portfolio-${data.id}`} triggerHook={0.90} indicators={false} reverse={false}>
                    <PictureMobile src={data.mobile_picture_url} className={`portfolio-card-${data.id}-img`} type={data.type}/>
                  </Scene>
                </Controller>
              </ContainerPictureMobile>
              <SkillsWrap>
                {data.stacks.map((stack: string, index: number) =>
                  <Controller key={index}>
                    <Scene classToggle={[`.portfolio-card-${data.id}-stack`, "animate"]} triggerElement={`#portfolio-${data.id}`} triggerHook={0.90} indicators={false} reverse={false}>
                      <ContainerStack key={index} className={`portfolio-card-${data.id}-stack`}>{stack}</ContainerStack>
                    </Scene>
                  </Controller>
                )}
              </SkillsWrap>
              <Controller>
                <Scene classToggle={[`.portfolio-card-${data.id}-button`, "animate"]} triggerElement={`#portfolio-${data.id}`} triggerHook={0.90} indicators={false} reverse={false}>
                  <ButtonWrap className={`portfolio-card-${data.id}-button`}>
                    <Button
                      isArrow={true}
                      color={data.color}
                      onClick={() => {
                        if (data.id) history.push(`project/${data.id}`)
                      }}
                    >
                      See case in details
                    </Button>
                  </ButtonWrap>
                </Scene>
              </Controller>
            </Container>
            <Controller>
              <Scene classToggle={[`.portfolio-card-${data.id}-img`, "animate"]} triggerElement={`#portfolio-${data.id}`} triggerHook={0.90} indicators={false} reverse={false}>
                <Picture src={data.picture_url} className={`portfolio-card-${data.id}-img`}/>
              </Scene>
            </Controller>
          </GridWrap>
        </WrapColorPicture>
      </Scene>
    </Controller>
  )
}

export default PortfolioCard;