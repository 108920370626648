import styled, { keyframes } from "styled-components";
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp } from "react-animations";

type PropsStyle = {
  isWhite?: boolean;
};

const FadeInUp = keyframes`${fadeInUp}`;

export const TextInfo = styled.div<PropsStyle>`
  font-weight: normal;
  font-family: ${theme.fonts.mulish};
  color: ${({ isWhite }) =>
  isWhite ? theme.palette.white[0] : theme.palette.black[1]};
  opacity: 0;
  font-size: ${theme.fontSizes.texts[1]};
  line-height: 22.16px;
  width: 50%;

  &.animate {
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }

  @media (max-width: 992px) {
    br {
      display: none;
    }
  }
    @media (max-width: 768px) {
       width: 100%;
       margin-bottom: 30px;
  }
`;

export const Wrapper = styled.div<PropsStyle>`
  display: flex;
  gap: 30px;
  
    @media (max-width: 768px) {
      display: block;
  }
`;

export const Container = styled.div<PropsStyle>`
  width: 50%;
   opacity: 0;
   
  &.animate {
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
    @media (max-width: 768px) {
       width: 100%;
  }

`;

export const ExpertiseCard = styled.div<PropsStyle>`
  display: inline-block;
  font-weight: 600;
  font-family: ${theme.fonts.mulish};
  color: ${theme.palette.black[1]};
  font-size: ${theme.fontSizes.texts[1]}px;
  line-height: 22.59px;
  padding: 2px;
  border: 1px solid #1e253f;
  border-radius: 3px;
  margin: 0 16px 16px 0;


  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }

  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 15px;
  }

  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 10px;
    margin: 0 8px 8px 0;
  }
`;

export const Title = styled.div<PropsStyle>`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  color: ${({ isWhite }) =>
  isWhite ? theme.palette.white[0] : theme.palette.black[1]};
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;

  &.animate {
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }

  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }

  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 15px;
  }

  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 20px;
  }
`;
