import React from "react";
import LandingContent from "./content/LandingContent";
import LandingHeader from "./header/LandingHeader";
import { LandingContainer } from "./landing-styles";


const MainLandingPage:React.FC = (props: any) => {
  return (
    <LandingContainer >
      <LandingHeader {...props}/>
      <LandingContent {...props}/>
      {/*<Footer />*/}
    </LandingContainer>
  )
}

export default MainLandingPage