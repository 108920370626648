import styled, { keyframes } from 'styled-components';
import theme from "../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeInDownBig, fadeInUpBig } from "react-animations";

type PropsStyle = {
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDownBig = keyframes`${fadeInDownBig}`;
const FadeInUpBig = keyframes`${fadeInUpBig}`;

export const Container = styled.div`
  color: ${theme.palette.white[0]};
`

export const Title = styled.div`
  font-weight: bold;
  font-size: ${theme.fontSizes.headers[2]}px;
  padding-bottom: ${theme.spaces[2]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 45px;
    line-height: 59px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    font-weight: bold;
    font-size: 30px;
    line-height: 115%;
    padding-bottom: 20px;
  }
`

export const InfoWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  font-family: ${theme.fonts.mulish};
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const InfoText = styled.div`
  grid-column-start: 1;
  grid-column-end: 8;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    line-height: 150%;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const StacksThematic = styled.div`
  grid-column-start: 8;
  grid-column-end: 13;
`

export const StackWrap = styled.div`
`

export const ContainerStack = styled.div`
  display: inline-block;
  padding: 3px 10px;
  border: 1px solid ${theme.palette.white[0]};
  border-radius: 3px;
  margin-right: 13px;
  margin-bottom: 13px;
  font-size: ${theme.fontSizes.texts[1]}px;
  opacity: 0;
  font-weight: 300;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInDownBig};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 13px;
    line-height: 140%;
    margin-right: 7px;
    margin-bottom: 7px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 13px;
    line-height: 140%;
    margin-right: 7px;
    margin-bottom: 7px;
  }
  
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 140%;
    margin-right: 7px;
    margin-bottom: 7px;
  }
`

export const ThematicWrap = styled.div`
  font-weight: 500;
`

export const ListThematic = styled.ul`
  padding-inline-start: 20px;
  margin-block-start: 10px;
  margin-block-end: 0;
`

export const ItemList = styled.li`
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 1.3s ${FadeInUpBig};
    animation-delay: 0.0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.headers[5]}px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 18px;
    line-height: 111.5%;
  }
`