import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { CategoriesContainer, CategoryCard, CategoryText, CategoryTitle, IconWrap } from "./software-dev-styles";
import { TextInfo, Title } from "../../content-services-styles";
import Development from "../../../../../img/services/development.svg";
import DevOps from "../../../../../img/services/devops.svg";
import Ios from "../../../../../img/services/apple.svg";
import Android from "../../../../../img/services/android.svg";

const SoftwareDev:React.FC = () => {
  return (
    <>
      <Controller>
        <Scene classToggle={[".software-title-animate", "animate"]} triggerElement="#software-dev" triggerHook={0.80} indicators={false} reverse={false}>
          <Title className="software-title-animate">Full-stack software development</Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene classToggle={[".software-text-animate", "animate"]} triggerElement="#software-dev" triggerHook={0.80} indicators={false} reverse={false}>
          <TextInfo className="software-text-animate">{data.text}<br/>{data.text2}</TextInfo>
        </Scene>
      </Controller>
      <CategoriesContainer>
        {data.categories.map((category: any, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[`#card-category-${index}`, "animate"]} triggerElement={`#card-category-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
              <CategoryCard index={index} id={`card-category-${index}`}>
                <Controller>
                  <Scene classToggle={[`.card-category-title-${index}`, "animate"]} triggerElement={`#card-category-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <CategoryTitle className={`card-category-title-${index}`}>
                      <IconWrap src={category.img}/>
                      {category.title}
                    </CategoryTitle>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene classToggle={[`.card-category-text-${index}`, "animate"]} triggerElement={`#card-category-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <CategoryText className={`card-category-text-${index}`}>{category.text}</CategoryText>
                  </Scene>
                </Controller>
              </CategoryCard>
            </Scene>
          </Controller>
        )}
      </CategoriesContainer>
    </>
  )
}

export default SoftwareDev;

const data = {
  text: "Our primary stack includes React Native, React, Angular, Ionic, HTML5, CSS3, and JavaScript for Frontend",
  text2: " and Python/Django for Backend.",
  categories: [
    {
      title: "Web development",
      text: "We provide development of web applications for a variety of business sectors. Nord-Soft leverages an extended set of programming languages, frameworks, libraries and tools to build projects of any scale and complexity and transform your ideas into innovative solutions.",
      img: Development
    },
    {
      title: "DevOps",
      text: "Our team has extensive experience working with various hosting providers. We offer server setup/configuration and automation to support delivery across all of your applications, and use   the most up-to-date tools to ensure stable and predictable processes of continuous delivery.",
      img: DevOps
    },
    {
      title: "iOS",
      text: "We offer hybrid iOS app development for iPhone and iPad. Our team will help you create a mobile version of your website or develop a totally new app. Be it an idea, a detailed spec or a half-finished app, we'll bring your project to fruition.",
      img: Ios
    },
    {
      title: "Android",
      text: "The state-of-the-art tools and techniques we use allow us building hybrid mobile applications that run smoothly on Android devices. You can rely on us to develop a new Android app, improve your existing one, or port your iOS app to Android platform.",
      img: Android
    },
  ]
}