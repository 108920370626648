import styled, { keyframes } from 'styled-components';
import theme from "../../../themes/default";
// @ts-ignore
import { fadeInUp } from "react-animations";

type PropsStyle = {
  white?: string
  isIn?: boolean
  animate?: boolean
  delay?: number
}

const FadeInUp = keyframes`${fadeInUp}`;

export const Container = styled.div`
  padding: 80px 100px;
    @media (min-width: 769px) and (max-width: 992px) {
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    padding: 20px;
  }
`

export const Title = styled.div<PropsStyle>`
  font-size: ${theme.fontSizes.headers[2]}px;
  line-height: 100%;
  font-weight: bold;
  color: ${({white}) => white === "true" ? theme.palette.white[0] : theme.palette.black[1]};
  padding-bottom: ${theme.spaces[1]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: ${({delay}) => delay ? delay : 0.0}s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    font-size: 28px;
    padding-bottom: ${theme.spaces[2]}px;
  }
`