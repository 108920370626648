import React, { useEffect, useState } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { TextInfo, Title } from "../../content-services-styles";

//icons
import deliver from "../../../../../img/collaboration/deliver.svg";
import analysis from "../../../../../img/collaboration/analysis.svg";
import develop from "../../../../../img/collaboration/develop.svg";
import discuss from "../../../../../img/collaboration/discuss.svg";
import interfaceIcon from "../../../../../img/collaboration/interface.svg";
import nda from "../../../../../img/collaboration/nda.svg";
import maintain from "../../../../../img/collaboration/maintain.svg";
import release from "../../../../../img/collaboration/release.svg";
import sign from "../../../../../img/collaboration/sign.svg";
import {
  CollaborateItemCard,
  CollaborationContainer,
  IconImg,
  ItemText,
  WaitText,
  WaitTitle,
  WrapImg,
  OptionCard,
  WrapperOptions,
  WrapArrow,
} from "./collaboration-styles";
import Arrow from "../../../../../components/arrow/Arrow";

const Collaboration: React.FC = () => {
  const [data, setData] = useState(dataCollaborationsIcon);
  const [widthPoint, setWidthPoint] = useState(0);
  const listenResize = () => {
    let width = window.innerWidth;
    if (width > 768) {
      setData(dataCollaborationsIcon);
    } else {
      setData(dataCollaborationsIconMobile);
    }
    if (width >= 1440 && widthPoint !== 1440) {
      setWidthPoint(1440);
    } else if (width >= 992 && widthPoint !== 992) {
      setWidthPoint(992);
    } else if (width >= 768 && widthPoint !== 768) {
      setWidthPoint(768);
    } else setWidthPoint(320);
  };

  useEffect(() => {
    window.addEventListener("resize", listenResize);
    listenResize();
    return () => window.removeEventListener("resize", listenResize);
  }, []);
  return (
    <>
      <Controller>
        <Scene
          classToggle={["#collaboration-title", "animate"]}
          triggerElement="#collaboration-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <Title id="collaboration-title">Work process</Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene
          classToggle={[".collaboration-text-info", "animate"]}
          triggerElement="#collaboration-title"
          triggerHook={0.8}
          indicators={false}
          reverse={false}
        >
          <TextInfo className="collaboration-text-info">
            {dataCollaboration.text}
            <br />
            {dataCollaboration.text2}
          </TextInfo>
        </Scene>
      </Controller>
      <CollaborationContainer>
        {data.map((item: any, index: number) => (
          <Controller key={index}>
            <Scene
              classToggle={[`#card-collaborate-${index}`, "animate"]}
              triggerElement={`#card-collaborate-${index}`}
              triggerHook={0.8}
              indicators={false}
              reverse={false}
            >
              <CollaborateItemCard id={`card-collaborate-${index}`}>
                <Controller>
                  <Scene
                    classToggle={[`.card-collaborate-img-${index}`, "animate"]}
                    triggerElement={`#card-collaborate-${index}`}
                    triggerHook={0.8}
                    indicators={false}
                    reverse={false}
                  >
                    <WrapImg className={`card-collaborate-img-${index}`}>
                      <IconImg src={item.icon} />
                    </WrapImg>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene
                    classToggle={[`.card-collaborate-text-${index}`, "animate"]}
                    triggerElement={`#card-collaborate-${index}`}
                    triggerHook={0.8}
                    indicators={false}
                    reverse={false}
                  >
                    <ItemText className={`card-collaborate-text-${index}`}>
                      {item.text}
                    </ItemText>
                  </Scene>
                </Controller>
                {item.arrow === "right" ? (
                  <Controller>
                    <Scene
                      classToggle={[
                        `.card-collaborate-arrow-${index}`,
                        "animate-right",
                      ]}
                      triggerElement={`#card-collaborate-${index}`}
                      triggerHook={0.8}
                      indicators={false}
                      reverse={false}
                    >
                      <WrapArrow className={`card-collaborate-arrow-${index}`}>
                        <Arrow
                          arrows={"blue"}
                          top="39px"
                          right={
                            widthPoint < 767
                              ? "-27px"
                              : widthPoint < 991
                              ? "-32px"
                              : "-37px"
                          }
                          color="#2A96B7"
                        />
                      </WrapArrow>
                    </Scene>
                  </Controller>
                ) : (
                  <></>
                )}
              </CollaborateItemCard>
            </Scene>
          </Controller>
        ))}
      </CollaborationContainer>
    </>
  );
};

export default Collaboration;

const dataCollaboration = {
  text: "Here are the basic stages of our client engagement. From the initial meeting to the project",
  text2: " delivery, we offer assistance and resources every step of the way.",
};

const dataCollaborationsIcon = [
  {
    text: "Discovery, analysis, design",
    position: "1.",
    icon: analysis,
    arrow: "right",
  },
  {
    text: "Develop the Software",
    position: "2.",
    icon: develop,
    arrow: "right",
  },
  {
    text: "Deliver Regularly",
    position: "3.",
    icon: deliver,
    arrow: "right",
  },
  {
    text: "Release the Product",
    position: "4.",
    icon: release,
    arrow: "right",
  },
  {
    text: "Maintain the Product",
    position: "5.",
    icon: maintain,
    arrow: "none",
  },
];

const dataCollaborationsIconMobile = [
  {
    text: "Discovery, analysis, design",
    position: "1.",
    icon: nda,
    arrow: "right",
  },
  {
    text: "Discuss the Project",
    position: "2.",
    icon: discuss,
    arrow: "bottom",
  },
  {
    text: "Set up the Interface",
    position: "4.",
    icon: interfaceIcon,
    arrow: "bottom",
  },
  {
    text: "Sign the Contract",
    position: "3.",
    icon: sign,
    arrow: "left",
  },
  {
    text: "Develop the Software",
    position: "5.",
    icon: develop,
    arrow: "right",
  },
  {
    text: "Deliver Regularly",
    position: "6.",
    icon: deliver,
    arrow: "bottom",
  },
  {
    text: "Maintain the Product",
    position: "8.",
    icon: maintain,
    arrow: "none",
  },
  {
    text: "Release the Product",
    position: "7.",
    icon: release,
    arrow: "left",
  },
];
