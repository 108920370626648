import React, {useEffect, useState} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import useRoute from "../routes/useRoute";
import { AppContainer } from './app-styles';
import Spinner from "../components/spinner/Spinner";

function App() {
  const [isLoad, setLoad] = useState(true)
  const route = useRoute();
  useEffect(() => {
    setTimeout(() => setLoad(true), 1000);
  }, []);
  return (
      <AppContainer>
        {isLoad ? route : <Spinner/>}
      </AppContainer>
  );
}

export default App;
