//common
import React from "react";

//components
import { Controller, Scene } from "react-scrollmagic";
import { CategoriesContainer, CategoryCard, CategoryText, CategoryTitle, IconWrap, TextInfo, Title } from "./ui-ux-dev";

//icons
import prototypes from '../../../../../img/services/prototypes.svg'
import identity from '../../../../../img/services/identity.svg'
import designUX from '../../../../../img/services/designUX.svg'
import designUI from '../../../../../img/services/designUI.svg'

const UiUxDev:React.FC = () => {
  return (
    <>
      <Controller>
        <Scene classToggle={[".design-title-animate", "animate"]} triggerElement="#design-dev" triggerHook={0.80} indicators={false} reverse={false}>
          <Title className="design-title-animate">UX/UI Development</Title>
        </Scene>
      </Controller>
      <Controller>
        <Scene classToggle={[".design-text-animate", "animate"]} triggerElement="#design-dev" triggerHook={0.80} indicators={false} reverse={false}>
          <TextInfo className="design-text-animate">{data.text}<br/>{data.text2}</TextInfo>
        </Scene>
      </Controller>
      <CategoriesContainer>
        {data.categories.map((category: any, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[`#card-design-${index}`, "animate"]} triggerElement={`#card-design-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
              <CategoryCard id={`card-design-${index}`}>
                <Controller>
                  <Scene classToggle={[`.card-design-title-${index}`, "animate"]} triggerElement={`#card-design-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <CategoryTitle color={category.color} className={`card-design-title-${index}`}>
                      <IconWrap src={category.img}/>
                      {category.title}
                    </CategoryTitle>
                  </Scene>
                </Controller>
                <Controller>
                  <Scene classToggle={[`.card-design-text-${index}`, "animate"]} triggerElement={`#card-design-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
                    <CategoryText className={`card-design-text-${index}`}>{category.text}</CategoryText>
                  </Scene>
                </Controller>
              </CategoryCard>
            </Scene>
          </Controller>
        )}
      </CategoriesContainer>
    </>
  )
}

export default UiUxDev;

const data = {
  text: "Our primary stack includes React Native, React, Angular, Ionic, HTML5, CSS3, and JavaScript for",
  text2: " Frontend and Python/Django for Backend.",
  categories: [
    {
      title: "Interactive Prototypes",
      text: "This is a visualization tool that is suitable for early feedback, beta testing, and even for selling the ideas of your application.",
      img: prototypes,
      color: 'blue'
    },
    {
      title: "Brand identity",
      text: "We create your brand with a single product style that encompasses your logo, badges, and color scheme.",
      img: identity,
      color: 'red'
    },
    {
      title: "UX Design",
      text: "We use a number of methods for interacting with a client that primarily helps to solve problems and achieve mutual understanding.",
      img: designUX,
      color: 'red'
    },
    {
      title: "UI Design",
      text: "We are developing a user interface for mobile and web applications that is very easy to use and as well as intuitive and visually attractive.",
      img: designUI,
      color: 'darkBlue'
    },
  ]
}