import styled, { keyframes } from 'styled-components';
import theme from "../../themes/default";
// @ts-ignore
import { fadeIn } from "react-animations";

type PropsStyle = {
  color?: string
  image?: string
  padding?:string
  shadow?: string
  height?: string
  display?: string
}
const FadeIn = keyframes`${fadeIn}`;

type ReturnPadding= (index: string | undefined, width?: number | undefined) => any

let getPadding: ReturnPadding = function (index, width) {
  switch (index) {
    case "TitleInfo": {
      switch (width) {
        case 768: {
          return `60px 15px 20px`
        }
        case 992: {
          return "95px 30px 25px"
        }
        case 1440: {
          return `105px 60px ${theme.spaces[1]}px`
        }
        default: {
          return `${theme.spaces[0] + 100}px 100px ${theme.spaces[0]}px`
        }
      }
    }
    case "TitleInfoNew": {
      switch (width) {
        case 768: {
          return `60px 15px 20px`
        }
        case 992: {
          return "95px 30px 25px"
        }
        case 1440: {
          return `105px 60px ${theme.spaces[1]}px`
        }
        default: {
          return `${theme.spaces[0] + 150}px 100px ${theme.spaces[0]}px`
        }
      }
    }
    case "TitleInfoServices": {
      switch (width) {
        case 768: {
          return `60px 15px 30px`
        }
        case 992: {
          return "95px 30px 30px"
        }
        case 1440: {
          return "105px 60px 50px"
        }
        default: {
          return `${theme.spaces[0] + 70}px 100px ${theme.spaces[0]}px`
        }
      }
    }
    case "Projects": {
      switch (width) {
        case 768: {
          return `60px 15px 30px`
        }
        case 992: {
          return "95px 30px 30px"
        }
        case 1440: {
          return "105px 60px 50px"
        }
        default: {
          return `${theme.spaces[3]}px 100px ${theme.spaces[3]}px`
        }
      }
    }
    case "TechnicalExpertise":
    case "ChooseUs": {
      switch (width) {
        case 768: {
          return `40px 15px 40px`
        }
        case 992: {
          return "40px 30px 40px"
        }
        case 1440: {
          return `${theme.spaces[1]}px 60px 40px`
        }
        default: {
          return `${theme.spaces[0]}px 100px 60px`
        }
      }
    }
    case "StartProject": {
      switch (width) {
        case 768: {
          return "75px 15px"
        }
        case 992: {
          return "35px 30px"
        }
        case 1440: {
          return "60px"
        }
        default: {
          return "150px 100px"
        }
      }
    }
    case "SoftwareDev":
    case "WeCare": {
      switch (width) {
        case 768: {
          return `0 14px 20px`
        }
        case 992: {
          return "0 30px 50px"
        }
        case 1440: {
          return `0 60px ${theme.spaces[1]}px`
        }
        default: {
          return `0 100px ${theme.spaces[0]}px`
        }
      }
    }
    case "PortfolioSolutions": {
      switch (width) {
        case 768: {
          return "50px 15px 0"
        }
        case 992: {
          return "40px 30px 0"
        }
        case 1440: {
          return "50px 60px 0"
        }
        default: {
          return "100px 100px 0"
        }
      }
    }
    case "MainHeader":
    case "WrapHeader": {
      switch (width) {
        case 768: {
          return "0 0"
        }
        case 992: {
          return "0 0"
        }
        default: {
          return "0 0"
        }
      }
    }
    case "WhatWeDo":
    case "OurTeam":
    case "Collaboration": {
      switch (width) {
        case 768: {
          return "40px 15px"
        }
        case 992: {
          return "40px 30px"
        }
        case 1440: {
          return theme.spaces[1] + "px 60px"
        }
        default: {
          return theme.spaces[0] + "px 100px"
        }
      }
    }
    case "SlideCard": {
      switch (width) {
        case 768: {
          return "25px 15px"
        }
        case 992: {
          return "25px 30px"
        }
        case 1440: {
          return  "40px 60px"
        }
        default: {
          return theme.spaces[0] + "px 100px"
        }
      }
    }
    case "GetInTouch": {
      switch (width) {
        case 768: {
          return "30px 15px 30px"
        }
        case 992: {
          return "50px 10px 30px"
        }
        case 1440: {
          return theme.spaces[1] + "px 30px"
        }
        default: {
          return theme.spaces[0] + "px 30px"
        }
      }
    }
    default : {
      switch (width) {
        case 768: {
          return theme.spaces[1] + "px 15px"
        }
        case 992: {
          return "40px 30px"
        }
        case 1440: {
          return theme.spaces[1] + "px 60px"
        }
        default: {
          return theme.spaces[0] + "px 100px"
        }
      }
    }
  }
}

export const Container = styled.div<PropsStyle>`
  position: relative;
  background-color: ${({color, image, className}) => color && !image && !className ? color : "unset"};
  ${({image, className}) => image && !className ? `
    background-image: url(${image});
    background-repeat: "unset";
    background-position: "center";
    background-size: cover;
  ` : ""};
  opacity: ${({className}) => !className ? 1 : 0};
  height: ${({height}) => height ? height : 'auto'};
  display:  ${({display}) => display ? display : 'block'};
  &.animate{
    opacity: 1;
    background-color: ${({color, image}) => color && !image ? color : "unset"};
    ${({image}) => image ? `
      background-image: url(${image});
      background-repeat: "unset";
      background-position: "center";
      background-size: cover;
    ` : ""};
    animation: 1.8s ${FadeIn};
    animation-delay: 0.0s;
    animation-fill-mode: both;
  }
`

export const WithShadow = styled.div<PropsStyle>`
  background-color: ${({shadow}) => shadow ? shadow : "unset"};
`

export const WrapContent = styled.div`
  max-width: 1440px;
  margin: auto;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    max-width: 960px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    max-width: 720px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    max-width: 720px;
  }
`

export const PaddingContent = styled.div<PropsStyle>`
  padding: ${({padding}) => getPadding(padding)};
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: ${({padding}) => getPadding(padding, 1440)};
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: ${({padding}) => getPadding(padding, 992)};
  }
  
  @media (max-width: 768px) {
    padding: ${({padding}) => getPadding(padding, 768)};
  }
`