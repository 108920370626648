import styled, { keyframes } from 'styled-components';
import theme from "../../themes/default";
// @ts-ignore
import { fadeInUp, zoomIn, fadeIn } from "react-animations";

type PropsStyle = {
  type?: string
}

const FadeIn = keyframes`${fadeIn}`;
const FadeInUp = keyframes`${fadeInUp}`;
const ZoomIn = keyframes`${zoomIn}`;

export const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const Container = styled.div`
  color: ${theme.palette.white[0]};
  position: relative;
  grid-column-start: 1;
  grid-column-end: 7;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    // grid-column-end: 8;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  padding-bottom: ${theme.spaces[2]}px;
  
  &.animate{
    animation: 0.5s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 33px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    font-size: 26px;
  }
`

export const TextContainer = styled.div`
  font-style: normal;
  font-weight: 300;
  font-family: ${theme.fonts.mulish};
  font-size: ${theme.fontSizes.headers[5]}px;
  
  &.animate{
    animation: 0.5s ${FadeIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.texts[2]}px;
    line-height: 140%;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: ${theme.fontSizes.texts[2]}px;
    line-height: 130%;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
`

export const SkillsWrap = styled.div`
  margin-top: ${theme.spaces[2]}px;
  padding-bottom: ${theme.spaces[2]}px;
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    margin-top: 0;
  }
`

export const ButtonWrap = styled.div`
  &.animate{
    animation: 0.5s ${ZoomIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`

export const ContainerPictureMobile = styled.div`
  // width: calc(100% + 15px);
  position: relative;
  text-align: end;
`

export const Picture = styled.img`
  width: 100%;
  
  grid-column-start: 7;
  grid-column-end: 13;
  margin: -30px 0;
  
  &.animate{
    animation: 0.5s ${FadeIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  @media (min-width: 993px) and (max-width: 1440px) {
    // grid-column-start: 8;
    margin: -10px 0;
  }
  
  @media (max-width: 992px) {
    margin: -10px 0;
  }
  
  
  @media (max-width: 768px) {
    display: none;
  }
`

export const PictureMobile = styled(Picture)<PropsStyle>`
  display: none;
  
  @media (max-width: 768px) {
    display: inline-block;
    position: relative;
    margin: unset;
    width: ${({type}) => type === "mobile" ? `calc(100% + 30px)` : `calc(100% + 15px)`};
    padding: 15px 0;
    margin: ${({type}) => type === "mobile" ? "0 -15px" : " 0 -15px 0 0"};
  }
`

export const ContainerStack = styled.div`
  font-family: ${theme.fonts.mulish};
  display: inline-block;
  padding: 3px 10px;
  border: 1px solid ${theme.palette.white[0]};
  border-radius: 3px;
  margin: 0 8px 8px 0;
  font-size: ${theme.fontSizes.texts[1]}px;
  font-weight: 300;
  
  &.animate{
    animation: 0.5s ${ZoomIn};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 14px;
    line-height: 140%;
  }
  
  @media (max-width: 768px) {
    font-size: 13px;
  }
`