import React from "react";
import theme from "../../../themes/default";
import WrapColorPicture from "../../../components/wrapColorPicture/WrapColorPicture";
import SoftwareDev from "./components/SoftwareDev/SoftwareDev";
import Support from "./components/Support/Support";
import ChooseUs from "./components/ChooseUs/ChooseUs";
import Collaboration from "./components/Collaboration/Collaboration";
import OurExpertise from "./components/OurExpertise/OurExpertise";
import UiUxDev from "./components/UiUxDevelopment/UiUxDev";
import WhyWait from "./components/WhyWait/WhyWait";

const ContentServices:React.FC = () => {
  return (
    <>
      <WrapColorPicture color={theme.palette.grayscale[0]} id='our-expertise'>
        <OurExpertise />
      </WrapColorPicture>
      <WrapColorPicture color={theme.palette.grayscale[0]} id='software-dev'>
        <SoftwareDev/>
      </WrapColorPicture>
      <WrapColorPicture color={theme.palette.black[1]} id='design-dev'>
        <UiUxDev />
      </WrapColorPicture>
      <WrapColorPicture color={theme.palette.grayscale[0]}>
        <Support/>
      </WrapColorPicture>
      <WrapColorPicture padding="ChooseUs" color={theme.palette.grayscale[0]}>
        <ChooseUs/>
      </WrapColorPicture>
      <WrapColorPicture padding="Collaboration" color={theme.palette.grayscale[0]}>
        <Collaboration/>
      </WrapColorPicture>
      <WrapColorPicture padding="Collaboration" color={theme.palette.black[1]}>
        <WhyWait/>
      </WrapColorPicture>
    </>
  )
}

export default ContentServices;