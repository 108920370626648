import styled from 'styled-components';
import theme from "../../../../../themes/default";

type PropsStyle = {
}

export const Container = styled.div`
  
`

export const QuotationMark = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  color: ${({color}) => color === "red" ? theme.palette.red[0] : theme.palette.black[1]};
  transform: matrix(1, 0, 0, -1, 0, 0);
  font-family: ${theme.fonts.mulish};
  display: inline-block;
  position: absolute;
  top: -65px;
  left: 0;
  right: 0;
`