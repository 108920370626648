import React from "react";
import { ArrowStyle } from "./arrow-styles";
import Arrow1 from "../../img/arrows/arrow1440.svg";
import Arrow2 from "../../img/arrows/arrow960.svg";
import Arrow3 from "../../img/arrows/arrow720.svg";
import Arrow4 from "../../img/arrows/arrow_blue.svg";

type PropsType = {
  rotate?: string;
  color?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  arrows?: string;
};

const Arrow: React.FC<PropsType> = ({
  rotate = "0deg",
  color = "#000000",
  top = "auto",
  bottom = "auto",
  left = "auto",
  right = "auto",
  arrows,
}) => {
  return (
    <ArrowStyle
      rotate={rotate}
      color={color}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      arrows={ arrows ? Arrow4 : Arrow1 }
    ></ArrowStyle>
  );
};

export default Arrow;
