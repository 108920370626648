import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, fadeInDown } from "react-animations";

type PropsStyle = {
  color: string | "blue"
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeInDown = keyframes`${fadeInDown}`;
const FadeIn = keyframes`${fadeIn}`;

type ReturnBenefitCard = (color: string) => any

let styleButton: ReturnBenefitCard = function (color) {
  switch (color) {
    case "blue":{
      return {
        color: theme.palette.blue[0],
      }
    }
    case "red":{
      return {
        color: theme.palette.red[0],
      }
    }
    case "blue2":{
      return {
        color: theme.palette.blue[2],
      }
    }
    case "blue3":{
      return {
        color: theme.palette.blue[3],
      }
    }

  }
}

export const ContainerOfBenefits = styled.div`
  margin-bottom: ${theme.spaces[1]}px;
  display: flex;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    margin-bottom: 0px;
  }
  
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 25px;
  }
`

export const CardBenefit = styled.div<PropsStyle>`
  flex: 1;
  padding: 45px 40px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  background-color: ${theme.palette.grayscale[0]};
  margin-right: 20px;
  transition-duration: 0.4s;
  opacity: 0;
  
  :last-child{
    margin-right: 0;
  }
  
  :hover{
    box-shadow: 0px 0px 15px ${({color}) => styleButton(color).color}40;
    background-color: ${theme.palette.white[0]};
  }
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    padding: 35px 30px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 30px 20px;
  }
  
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 20px;
    padding: 30px 30px;
  }
`

export const CardTitle = styled.div<PropsStyle>`
  margin-bottom: 25px;
  color: ${({color}) => styleButton(color).color};
  font-size: 24px;
  position: relative;
  margin-left: 60px;
  font-weight: 500;
  
  &.animate{
    animation: 0.4s ${FadeInDown};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 1440px) {
    font-size: 22px;
    margin-bottom: 15px;
    margin-left: 44px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 18px;
    line-height: 110%;
    margin-bottom: 15px;
    margin-left: 34px;
  }
  
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 10px;
    margin-left: 44px;
  }
`

export const IconWrap = styled.img`
  height: 54px;
  width: 55px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -60px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    height: 49px;
    width: 50px;
    left: -50px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    height: 40px;
    width: 41px;
    left: -39px;
  }
  
  @media (max-width: 768px) {
    height: 40px;
    width: 41px;
    left: -45px;
  }
`

export const CardText = styled.div`
  line-height: 136%;
  
  &.animate{
    animation: 0.4s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[2]}px;
  }
`

export const WrapButton = styled.div`
  display: flex;
  justify-content: center;
  margin: ${theme.spaces[1]}px 0;
  opacity: 0;
  
  :last-child{
    margin: ${theme.spaces[1]}px 0 0;
  }
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-fill-mode: both;
  }
  
  @media (max-width: 1440px) {
    margin: 25px 0;
    
    :last-child{
      margin: 25px 0 0;
    }
  }
  
  @media (max-width: 768px) {
    margin: ${theme.spaces[2]}px 0;
  
    :last-child{
      margin: ${theme.spaces[2]}px 0 0;
    }
  }
  
`

export const ContainerSkills = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const ContainerSkill = styled.div`
  max-width: 160px;
  margin: auto;
  display: flex;
  align-items: center;
  
  @media (max-width: 768px) {
    display: block;
  }
`

export const CardSkill = styled.div`
  height: 110px;
  background: ${theme.palette.grayscale[0]};
  box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.fontSizes.headers[4]}px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.0s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 1440px) {
    font-size: ${theme.fontSizes.headers[5]}px;
    height: 85px;
  }
  
  @media (max-width: 768px) {
    text-align: center;
  }
`

export const ImgSkill = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 25px;
  
  &.animate{
    animation: 0.8s ${FadeIn};
    animation-delay: 0.3s;
    animation-fill-mode: both;
  }
  
  @media (max-width: 1440px) {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-right: 0;
  }
`

export const TextSkill = styled.div`
  &.animate{
    animation: 0.8s ${FadeIn};
    animation-delay: 0.3s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    width: 68px;
    font-size: 15px;
    line-height: 19px;
  }
  
  @media (max-width: 768px) {
    font-size: ${theme.fontSizes.texts[1]}px;
  }
`