import React from "react";
import Header from "../../../components/header/Header";
import { HeaderStyle } from "./header-project-card-styles";

type PropsType = {
  data: any
}

const HeaderProjectCard:React.FC<PropsType> = (props) => {
  const {data} = props;
  return (
    <HeaderStyle color={data.color}>
      <Header color={data.color} {...props}/>
    </HeaderStyle>
  )
}

export default HeaderProjectCard;