import styled, { keyframes } from 'styled-components';
import theme from "../../../../../themes/default";
// @ts-ignore
import { fadeInUp, fadeIn, fadeInDown } from "react-animations";

type PropsStyle = {
}

const FadeInUp = keyframes`${fadeInUp}`;
const FadeIn = keyframes`${fadeIn}`;
const FadeInDown = keyframes`${fadeInDown}`;

export const Title = styled.div`
  font-size: ${theme.fontSizes.headers[2]}px;
  font-weight: bold;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.5s ${FadeInUp};
    animation-delay: 0s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 52px;
    padding-bottom: 25px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 39px;
    padding-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 28px;
    padding-bottom: 20px;
  }
`

export const CareText = styled.div`
  font-family: ${theme.fonts.mulish};
  font-weight: 600;
  margin-top: 30px;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.8s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top: 0;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-weight: 400;
    margin-top: 0;
  }
  
  @media (max-width: 768px) {
    margin-top: 0;
  }
`

export const CareWrap = styled.div`
  margin-top: 55px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  
  @media (min-width: 993px) and (max-width: 1440px) {
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
`

export const CareCard = styled.div`
  text-align: center;
  opacity: 0;
  
  &.animate{
    opacity: 1;
    animation: 0.4s ${FadeIn};
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
  
  img{
    &.animate{
      animation: 0.4s ${FadeInDown};
      animation-delay: 0.5s;
      animation-fill-mode: both;
    }
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    img{
      width: 56px;
      height: 57px;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    img{
      width: 68px;
      height: 69px;
    }
  }
  
  @media (max-width: 768px) {
    img{
      width: 68px;
      height: 69px;
    }
  }
`

export const CareCardText = styled.div`
  font-size: ${theme.fontSizes.headers[4]}px;
  font-weight: 500;
  width: 200px;
  margin: auto;
  margin-top: 12px;
  
  &.animate{
    animation: 0.4s ${FadeInUp};
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
  
  @media (min-width: 993px) and (max-width: 1440px) {
    width: 128px;
    font-size: ${theme.fontSizes.texts[1]}px;
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    font-weight: bold;
    font-size: ${theme.fontSizes.texts[1]}px;
    line-height: 114.5%;
    width: 106px;
  }
  
  @media (max-width: 768px) {
    width: 106px;
    font-weight: bold;
    font-size: ${theme.fontSizes.texts[1]}px;
    line-height: 114.5%;
  }
`