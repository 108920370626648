import React, {useEffect, useState} from "react";
import theme from "../../../themes/default";
import PortfolioInfo from "./components/PortfolioInfo/PortfolioInfo";
import WrapColorPicture from "../../../components/wrapColorPicture/WrapColorPicture";
import Question from "./components/Questions/Questions";
import PortfolioCard from "../../../components/potfolioCard/PortfolioCard";
import { dataProjects } from "../../../services/api";

const Content:React.FC = (props) => {
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    setData(dataProjects())
  }, [])
  return (
    <>
      {/*<WrapColorPicture padding="TitleInfo" color={theme.palette.black[0]}>*/}
      {/*  <PortfolioInfo/>*/}
      {/*</WrapColorPicture>*/}
      {data?.map((project: any, index: number) =>
        <PortfolioCard data={project} key={index} index={index} {...props}  />
      )}
      <WrapColorPicture color={theme.palette.white[0]}>
        <Question/>
      </WrapColorPicture>
    </>
  )
}

export default Content;