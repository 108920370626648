import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { OptionCard, Title, WrapperOptions } from "./what-we-do-styles";

const WhatWeDo:React.FC = () => {
  return (
    <>
      <Controller>
        <Scene classToggle={["#what-title", "animate"]} triggerElement="#what-title" triggerHook={0.80} indicators={false} reverse={false}>
          <Title id="what-title">What we do</Title>
        </Scene>
      </Controller>
      <WrapperOptions>
        <Controller>
          <Scene classToggle={[`#card-option-1`, "animate"]} triggerElement={`#card-option-1`} triggerHook={0.80} indicators={false} reverse={false}>
            <OptionCard id={`card-option-1`}>
              <Controller>
                <Scene classToggle={[`.card-option-title-1`, "animate"]} triggerElement={`#card-option-1`} triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="card-option-title-1">Start a new project</div>
                </Scene>
              </Controller>
              <Controller>
                <Scene classToggle={[`.card-option-text-1`, "animate"]} triggerElement={`#card-option-1`} triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="card-option-text-1">
                    Describe your vision, and we'll be in touch soon.
                  </div>
                </Scene>
              </Controller>
            </OptionCard>
          </Scene>
        </Controller>
        <Controller>
          <Scene classToggle={[`#card-option-2`, "animate"]} triggerElement={`#card-option-2`} triggerHook={0.80} indicators={false} reverse={false}>
            <OptionCard id={`card-option-2`}>
              <Controller>
                <Scene classToggle={[`.card-option-title-2`, "animate"]} triggerElement={`#card-option-2`} triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="card-option-title-2">Recover an existing project</div>
                </Scene>
              </Controller>
              <Controller>
                <Scene classToggle={[`.card-option-text-2`, "animate"]} triggerElement={`#card-option-2`} triggerHook={0.80} indicators={false} reverse={false}>
                  <div className="card-option-text-2">
                    Your app or web site leaves something to be desired? Let us know, we'll bring it to a new technical level.
                  </div>
                </Scene>
              </Controller>
            </OptionCard>
          </Scene>
        </Controller>
      </WrapperOptions>
    </>
  )
}

export default WhatWeDo