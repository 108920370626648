import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { SphereCard, SphereImg, SpheresContainer, SphereText, Title, WrapImg } from "./title-info-styles";
import { TextInfo } from"../../content-technologies-styles";
import billing from "../../../../../img/spheres/billing.svg";
import customer from "../../../../../img/spheres/customer.svg";
import document from "../../../../../img/spheres/document.svg";
import ecommerce from "../../../../../img/spheres/ecommerce.svg";
import gaming from "../../../../../img/spheres/gaming.svg";
import geolocation from "../../../../../img/spheres/geolocation.svg";
import intelligence from "../../../../../img/spheres/intelligence.svg";
import learning from "../../../../../img/spheres/learning.svg";
import media from "../../../../../img/spheres/media.svg";
import mobility from "../../../../../img/spheres/mobility.svg";
import process from "../../../../../img/spheres/process.svg";
import social from "../../../../../img/spheres/social.svg";

const TitleInfo:React.FC = () => {
  return (
    <>
      {/*<Controller>*/}
      {/*  <Scene classToggle={["#technologies-title", "animate"]} triggerElement="#technologies-title" triggerHook={0.80} indicators={false} reverse={false}>*/}
      {/*    <Title id="technologies-title">Technologies</Title>*/}
      {/*  </Scene>*/}
      {/*</Controller>*/}
      {/*<Controller>*/}
      {/*  <Scene classToggle={[".technologies-text", "animate"]} triggerElement="#technologies-title" triggerHook={0.80} indicators={false} reverse={false}>*/}
      {/*    <TextInfo className="technologies-text">We develop solutions across a wide range of business domains. Most frequent spheres are:</TextInfo>*/}
      {/*  </Scene>*/}
      {/*</Controller>*/}
      <SpheresContainer>
        {spheres.map((sphere: any, index: number) =>
          <Controller key={index}>
            <Scene classToggle={[`#card-sphere-${index}`, "animate"]} triggerElement={`#card-sphere-${index}`} triggerHook={0.80} indicators={false} reverse={false}>
              <SphereCard key={index} id={`card-sphere-${index}`}>
                <WrapImg>
                  <SphereImg src={sphere.icon}/>
                </WrapImg>
                <SphereText>{sphere.text}</SphereText>
              </SphereCard>
            </Scene>
          </Controller>
        )}
      </SpheresContainer>
    </>
  )
}

export default TitleInfo;

const spheres = [
  {
    text: "Mobility",
    icon: mobility
  },
  {
    text: "Business process & automation",
    icon: process
  },
  {
    text: "Social Networking",
    icon: social
  },
  {
    text: "Customer Relationship Management",
    icon: customer
  },
  {
    text: "Geolocation",
    icon: geolocation
  },
  {
    text: "Document & Content Management",
    icon: document
  },
  {
    text: "Billing & Payments",
    icon: billing
  },
  {
    text: "eCommerce",
    icon: ecommerce
  },
  {
    text: "Business Intelligence",
    icon: intelligence
  },
  {
    text: "Media Content Distribution",
    icon: media
  },
  {
    text: "Learning and Online Training",
    icon: learning
  },
  {
    text: "Gaming",
    icon: gaming
  },
]